import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { serviceCardTypes } from '@/components/shared/servicesSection/constants';
// components
const ServiceSectionV1 = dynamic(() =>
	import('@/components/shared/servicesSection/partials/serviceSectionV1')
);
const ServiceSectionV2 = dynamic(() =>
	import('@/components/shared/servicesSection/partials/serviceSectionV2')
);
const ServiceSectionV3 = dynamic(() =>
	import('@/components/shared/servicesSection/partials/serviceSectionV3')
);
const ServiceSectionV4 = dynamic(() =>
	import('@/components/shared/servicesSection/partials/serviceSectionV4')
);

export default function ServiceGroup({ cardListTheme, cardList }) {
	const isDesktop = useSelector((state) => state.common.isDesktop);
	const [activeCardIndex, setActiveCardIndex] = React.useState(0);

	let SectionComponent,
		sectionProps = { cardList, activeCardIndex, setActiveCardIndex };

	switch (cardListTheme) {
		case serviceCardTypes.v1: {
			SectionComponent = ServiceSectionV1;
			sectionProps.centerAlignContent = cardList.length < 3 && isDesktop;
			break;
		}
		case serviceCardTypes.v2: {
			SectionComponent = ServiceSectionV2;
			break;
		}
		case serviceCardTypes.v3: {
			SectionComponent = ServiceSectionV3;
			break;
		}
		case serviceCardTypes.v4: {
			SectionComponent = ServiceSectionV4;
			sectionProps.centerAlignContent = cardList.length < 3 && isDesktop;
			break;
		}
	}

	return <SectionComponent {...sectionProps} />;
}

ServiceGroup.propTypes = {
	cardListTheme: PropTypes.string,
	cardList: PropTypes.array,
};
ServiceGroup.defaultProps = {
	cardListTheme: serviceCardTypes.v1,
	cardList: [],
};
