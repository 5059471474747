import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/youtube';

// utils
import * as eventActions from '@/utils/analytics';

export default function VideoPlayer({ autoPlay, autoPlayOnView, ...props }) {
	const [isPlaying, setIsPlaying] = React.useState(!autoPlayOnView && autoPlay);
	const observerRef = React.useRef(null);
	const playerRef = React.useRef(null);

	const trackEvent = (eventName, eventLabel, eventAction) => {
		eventActions.trackEvent(eventName, {
			...props.analyticsProps,
			eventCategory: 'USER_EVENT',
			subSection: 'MEDIA_LIST_OPEN',
			eventAction: eventAction,
			eventLabel: eventLabel,
			mediaSrc: props.url,
			thumbnailURL: props.thumbnailURL,
		});
	};

	const handleOnReady = () => {
		!autoPlayOnView && !isPlaying && setIsPlaying(true);
		playerRef.current.seekTo(0, 'seconds');
	};

	const handleOnPlay = () => {
		trackEvent('VIDEO_PLAYED', 'VIDEO_PLAY_BUTTON', 'CLICKED');
	};

	const handleOnPause = () => {
		trackEvent('VIDEO_PAUSED', 'VIDEO_PAUSE_BUTTON', 'CLICKED');
	};

	const handleOnEnded = () => {
		trackEvent('VIDEO_ENDED', 'VIDEO_AUTO_ENDED', 'AUTO');
	};
	const handleOnBuffer = () => {
		trackEvent('VIDEO_BUFFERED', 'VIDEO_AUTO_BUFFER', 'AUTO');
	};

	React.useEffect(() => {
		if (autoPlayOnView && playerRef.current) {
			let observer = observerRef.current;

			observer && observer.disconnect();
			observer = new IntersectionObserver(
				([target]) => setIsPlaying(target.isIntersecting),
				{ root: null, threshold: 0.5 }
			);
			observer.observe(playerRef.current.wrapper);

			return () => observer && observer.disconnect();
		}
	}, [autoPlayOnView]);

	return (
		<ReactPlayer
			ref={playerRef}
			width="100%"
			volume={0.8}
			height={props.height}
			url={props.url}
			playsinline={true}
			controls={true}
			playing={isPlaying}
			loop={props.loop}
			muted={autoPlayOnView || autoPlay}
			light={autoPlayOnView ? false : props.thumbnailURL || props.light}
			onReady={handleOnReady}
			onPlay={handleOnPlay}
			onPause={handleOnPause}
			onEnded={handleOnEnded}
			onBuffer={handleOnBuffer}
		/>
	);
}

VideoPlayer.propTypes = {
	url: PropTypes.string.isRequired,
	thumbnailURL: PropTypes.string,
	loop: PropTypes.bool,
	light: PropTypes.bool,
	autoPlay: PropTypes.bool,
	autoPlayOnView: PropTypes.bool,
	analyticsProps: PropTypes.object,
};
VideoPlayer.defaultProps = {
	loop: false,
	light: true,
	autoPlay: false,
	autoPlayOnView: false,
	analyticsProps: {},
};
