import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import GenericButton from '@/components/widgets/genericButton';
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function ServiceCard({
	iconImage,
	coverImage,
	button,
	...props
}) {
	const { classes } = useStyles({ ...props });
	const isMobile = useSelector((state) => state.common.isMobile);
	return (
		<Box className={classes.container}>
			{coverImage && coverImage.url && (
				<Box className={classes.imageSection}>
					<Box className={classes.coverImageWrap}>
						<ImageComponent
							src={coverImage.url}
							alt={coverImage.alternativeText || props.title}
							layout="fill"
							objectFit="cover"
						/>
					</Box>
				</Box>
			)}
			<Box className={classes.body}>
				<Typography
					color={props.titleHighlighted ? 'primary' : 'black'}
					className={classes.title}
					variant={isMobile ? 'h6' : 'h5'}
					component="h6"
				>
					{iconImage && iconImage.url && (
						<Box className={classes.iconImageWrap}>
							<ImageComponent
								src={iconImage.url}
								alt={iconImage.alternativeText || props.title}
								layout="fill"
								objectFit="cover"
							/>
						</Box>
					)}
					{props.title}
				</Typography>

				<Markdown className={classes.content}>{props.description}</Markdown>

				{button && (
					<Box className={classes.buttonWrap}>
						<GenericButton
							label={button.label}
							link={button.link}
							fullWidth={true}
							variant={button.variant}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
}

ServiceCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	titleHighlighted: PropTypes.bool,
	button: PropTypes.object,
	coverImage: PropTypes.object,
	iconImage: PropTypes.object,
};

ServiceCard.defaultProps = {
	titleHighlighted: true,
};
