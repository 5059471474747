// comps
import EastIcon from '@mui/icons-material/East';
import { Box, Typography } from '@mui/material';
import { get } from 'lodash';
import PropTypes from 'prop-types';
// styles
import { useStyles } from './style';

export default function LocationCard(props) {
	const { classes } = useStyles();
	const title = get(props, 'title');
	const addressLine1 = get(props, 'addressLine1');
	const addressLine2 = get(props, 'addressLine2');
	const pincode = get(props, 'pincode');
	const receptionNumber = get(props, 'receptionNumber');
	const googlePlacesId = get(props, 'googlePlacesId');

	return (
		<Box className={classes.cardContainer}>
			{title && (
				<Typography component="h2" className={classes.title} color="primary">
					{title}
				</Typography>
			)}
			{addressLine1 && (
				<Typography className={classes.details}>{addressLine1}</Typography>
			)}
			{addressLine2 && (
				<Typography className={classes.details}>{addressLine2}</Typography>
			)}
			{pincode && (
				<Typography className={classes.details}>Pincode: {pincode}</Typography>
			)}
			{receptionNumber && (
				<Typography className={classes.details}>
					Reception:
					<a href={`tel:${receptionNumber.replace(/ /g, '')}`}>
						{` ${receptionNumber}`}
					</a>
				</Typography>
			)}
			<Box
				className={classes.ctaSection}
				onClick={() => props.onClickViewMap(googlePlacesId)}
			>
				<Typography className={classes.ctaLabel}>{props.ctaLabel}</Typography>
				<EastIcon color="primary" fontSize="small" />
			</Box>
		</Box>
	);
}

LocationCard.propTypes = {
	title: PropTypes.string,
	addressLine1: PropTypes.string,
	addressLine2: PropTypes.string,
	pincode: PropTypes.string,
	receptionNumber: PropTypes.string,
	ctaLabel: PropTypes.string,
	googlePlacesId: PropTypes.string,
	onClickViewMap: PropTypes.func,
};

LocationCard.defaultProps = {
	title: 'Azent Registered Address',
	ctaLabel: 'View map',
};
