// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	commentWrap: {
		marginBottom: theme.spacing(4),
	},
}));

export default useStyles;
