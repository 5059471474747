import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	counterV1Section: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(4),
		justifyContent: 'space-between',
		maxWidth: '1200px',
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	cardV1Wrap: {
		flex: '0 0 calc(33% - 32px)',
		[theme.breakpoints.down('sm')]: {
			flex: 'none',
		},
	},
}));

export default useStyles;
