import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box } from '@mui/material';

// components
import ComponentSlider from '@/components/shared/componentSlider';
import ServiceCard from '@/components/shared/serviceCard';
import TitleSection from '@/components/shared/titleSection';
import LinkCard from './linkCard';

// styles
import useStyles from './style';

export default function PlanListSection({
	title,
	highlightTitle,
	subTitle,
	titleFontSize,
	cardList,
	button,
	analyticsProps,
}) {
	const { classes } = useStyles();
	const isDesktop = useSelector((state) => state.common.isDesktop);

	const Slides = React.useMemo(() => {
		const slides = cardList.map((item, index) => {
			return (
				<Box key={index} className={classes.sliderCardWrap}>
					<ServiceCard
						{...item}
						coverImage={{
							url: get(item, 'coverImage.data.attributes.url', ''),
							alternativeText: get(
								item,
								'coverImage.data.attributes.alternativeText',
								''
							),
						}}
						iconImage={{
							url: get(item, 'iconImage.data.attributes.url', ''),
							alternativeText: get(
								item,
								'iconImage.data.attributes.alternativeText',
								''
							),
						}}
					/>
				</Box>
			);
		});

		if (button) {
			slides.push(
				<Box key={cardList.length} className={classes.sliderCardWrap}>
					<LinkCard button={button} />
				</Box>
			);
		}

		return slides;
	}, [cardList, button]);

	return (
		<Box>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={highlightTitle}
					title={title}
					highlightTitleAfter={true}
					fontSize={titleFontSize || titleSizes.medium}
					subTitle={subTitle}
				/>
			</Box>

			<ComponentSlider
				focusOnSelect={false}
				slidesToShow={isDesktop ? 3 : 1}
				useTransform={false}
				analyticsProps={analyticsProps}
				analyticsPropsSubSections={cardList.map((item) => item.title)}
				className={classes.slider}
			>
				{Slides}
			</ComponentSlider>
		</Box>
	);
}

PlanListSection.propTypes = {
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	analyticsProps: PropTypes.object,
};
PlanListSection.defaultProps = {
	analyticsProps: {},
};
