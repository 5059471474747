import PropTypes from 'prop-types';

// constants
import {
	couponAppliedImage,
	textValues,
} from '@/components/widgets/couponCodeBox/constants';

// component
import CTAButton from '@/components/widgets/ctaButton';
import ImageComponent from '@/components/widgets/image';

// material
import {
	Box,
	Button,
	CircularProgress,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';

// styles
import useStyles from './style';

export default function CouponCodeMobileForm({
	paymentDetail,
	errorText,
	couponCode,
	canProceedToPayment,
	...props
}) {
	const { classes } = useStyles();

	return (
		<Box className={classes.couponSectionWrap}>
			<Box className={classes.inputTextLabelWrap}>
				<Typography component="span" fontWeight="fontWeightMedium">
					{textValues.couponCodeFieldText}
				</Typography>
				{canProceedToPayment && (
					<Typography
						component="span"
						variant="subtitle2"
						fontWeight="fontWeightSemiBold"
						className={classes.couponFormTriggerText}
						onClick={props.handleRenterCouponCode}
					>
						{textValues.renterCouponCodeText}
					</Typography>
				)}
			</Box>
			<TextField
				type="text"
				value={couponCode}
				onChange={props.handleInputChange}
				onKeyDown={props.handleKeyDown}
				disabled={canProceedToPayment}
				helperText={
					<>
						{errorText && <span>{errorText}&nbsp;&nbsp;</span>}
						<span
							className={classes.couponFormTriggerText}
							onClick={props.handleClose}
						>
							{textValues.closeFormText}
						</span>
					</>
				}
				FormHelperTextProps={{
					error: Boolean(errorText),
					disabled: !errorText,
					sx: { marginLeft: 0 },
				}}
				InputProps={{
					sx: { backgroundColor: '#f1f5fd' },
					endAdornment: canProceedToPayment && (
						<InputAdornment position="end">
							<ImageComponent
								src={couponAppliedImage.url}
								alt={couponAppliedImage.alternativeText}
								width="55px"
								height="55px"
								placeholder="empty"
								unoptimized={true}
							/>
						</InputAdornment>
					),
				}}
				sx={{
					width: '100%',
					marginBottom: '12px',

					fieldSet: {
						borderStyle: 'dashed',
						borderRadius: '7px',
					},
				}}
			/>
			{canProceedToPayment ? (
				<CTAButton
					fullWidth={true}
					openFormModal={true}
					styledButton={false}
					color="success"
					label={textValues.proceedButtonText}
					pageSlug={props.pageSlug}
					form={props.form}
					formPageConstants={props.formPageConstants}
					formPageConfig={props.formPageConfig}
					paymentDetail={{
						...paymentDetail,
						couponCode,
						withCouponCode: false,
					}}
					analyticsProps={props.analyticsProps}
				/>
			) : (
				<Button
					variant="contained"
					fullWidth
					onClick={props.handleApplyCouponCode}
					disabled={props.loading || (couponCode || '').trim().length === 0}
				>
					{props.loading ? (
						<CircularProgress size="35px" />
					) : (
						textValues.applyButtonText
					)}
				</Button>
			)}
		</Box>
	);
}

CouponCodeMobileForm.propTypes = {
	// data
	couponCode: PropTypes.string,
	analyticsProps: PropTypes.object,
	pageSlug: PropTypes.string.isRequired,
	paymentDetail: PropTypes.object.isRequired,
	// function
	loading: PropTypes.bool,
	errorText: PropTypes.string,
	canProceedToPayment: PropTypes.bool,
	// form
	form: PropTypes.object.isRequired,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
	// handlers
	handleClose: PropTypes.func.isRequired,
	handleKeyDown: PropTypes.func.isRequired,
	handleInputChange: PropTypes.func.isRequired,
	handleApplyCouponCode: PropTypes.func.isRequired,
	handleRenterCouponCode: PropTypes.func.isRequired,
};

CouponCodeMobileForm.defaultProps = {
	loading: false,
	canProceedToPayment: false,
	analyticsProps: {},
};
