import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import HightlightCardV2 from '@/components/shared/highlightSection/partials/highlightCardV2';
import TitleSection from '@/components/shared/titleSection';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function HightlightSectionV2({ cardList, ...props }) {
	const { classes, cx } = useStyles();

	const cardWrapClasses = cx(
		classes.cardV2Wrap,
		props.columnCount === 1
			? classes.cardWidth100
			: props.columnCount === 3
			? classes.cardWidth33
			: classes.cardWidth50
	);

	return (
		<Box className={classes.sectionWrap}>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
					title={props.title}
					subTitle={props.subTitle}
				/>
			</Box>
			<Box className={classes.highlightV2Section}>
				{cardList.map((item, index) => (
					<Box key={index} className={cardWrapClasses}>
						<HightlightCardV2 {...item} />
					</Box>
				))}
			</Box>
		</Box>
	);
}

HightlightSectionV2.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	columnCount: PropTypes.number,
};
HightlightSectionV2.defaultProps = {
	cardList: [],
	columnCount: 2,
};
