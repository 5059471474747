import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import Markdown from '@/components/widgets/markdown';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function ContentSection({ title, body, ...props }) {
	const { classes } = useStyles(props);
	return (
		<Box className={classes.contentWrap}>
			{title && (
				<Box className={classes.titleWrap}>
					<TitleSection
						title={title}
						fontSize={props.titleFontSize || titleSizes.medium}
						textAlign={props.alignment || 'left'}
					/>
				</Box>
			)}
			<Markdown className="content-body">{body}</Markdown>
		</Box>
	);
}

ContentSection.propTypes = {
	title: PropTypes.string,
	body: PropTypes.string.isRequired,
	alignment: PropTypes.string,
	styled: PropTypes.bool,
	titleFontSize: PropTypes.string,
};
ContentSection.defaultProps = {
	alignment: 'left',
	styled: false,
};
