import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function CounterCardV3({ title, subTitle, ...props }) {
	const { classes } = useStyles(props);
	const { isMobile, isDesktop } = useSelector((state) => state.common);

	return (
		<Box className={classes.counterCardV3Wrap}>
			<Typography
				variant={isMobile ? 'h6' : isDesktop ? 'h4' : 'h5'}
				className={`${classes.title} ${!isMobile && classes.fontWeightMedium}`}
			>
				{title}
			</Typography>
			<Typography variant={'body1'} className={classes.subTitle}>
				{subTitle}
			</Typography>
		</Box>
	);
}

CounterCardV3.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired,
};
CounterCardV3.defaultProps = {};
