import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	planFeature: {
		display: 'flex',
		gap: '17.54px',
	},
	feature: {
		fontWeight: theme.typography.fontWeightSemiBold,
	},
	icon: {
		height: '20.24px',
		width: '20.24px',
		marginTop: '3px',
	},
	offeredIconColor: {
		color: theme.palette.success.main,
	},
	iconColor: {
		color: theme.palette.primary.main,
	},
	highlighIconColor: {
		color: theme.palette.common.white,
	},
	noIconMargin: {
		marginLeft: '37.78px',
	},
	textDisabled: {
		color: '#ABABAB',
	},
}));

export default useStyles;
