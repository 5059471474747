import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		mapContainer: {
			margin: theme.spacing(0, 3),
			borderRadius: theme.spacing(3),
			overflow: 'hidden',
            [theme.breakpoints.down('md')]: {
				margin: theme.spacing(0, 2),
			},
			[theme.breakpoints.down('sm')]: {
				margin: theme.spacing(0, 1),
			},
		},
	};
});
