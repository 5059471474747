// constants
import { counterThemeTypes } from '@/components/shared/counterSection/constants';
// components
import CounterSectionV1 from '@/components/shared/counterSection/partials/counterSectionV1';
import CounterSectionV2 from '@/components/shared/counterSection/partials/counterSectionV2';
import CounterSectionV3 from '@/components/shared/counterSection/partials/counterSectionV3';
// Default
import PropTypes from 'prop-types';

export default function CounterSection({ cardListTheme, ...props }) {
	const [activeCardIndex, setActiveCardIndex] = React.useState(0);

	let SectionComponent,
		sectionProps = {
			title: props.title,
			description: props.description,
			image: props.titleImage,
			cardList: props.cardList,
			titleFontSize: props.titleFontSize,
			activeCardIndex,
			setActiveCardIndex,
		};

	switch (cardListTheme) {
		case counterThemeTypes.v1: {
			SectionComponent = CounterSectionV1;
			break;
		}

		case counterThemeTypes.v2: {
			SectionComponent = CounterSectionV2;
			break;
		}

		case counterThemeTypes.v3: {
			SectionComponent = CounterSectionV3;
			break;
		}

		default:
			SectionComponent = CounterSectionV1;
	}

	return <SectionComponent {...sectionProps} />;
}

CounterSection.propTypes = {
	title: PropTypes.string,
	titleFontSize: PropTypes.string,
	description: PropTypes.string,
	titleImage: PropTypes.object,
	cardListTheme: PropTypes.string,
	cardList: PropTypes.array,
};
CounterSection.defaultProps = {
	cardListTheme: counterThemeTypes.v1,
	cardList: [],
};
