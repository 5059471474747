// constants
import { titleSizes } from '@/utils/constants/titleSizes';
// Comps
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';
// mui
import { Box, Grid, Typography } from '@mui/material';
// Defaults
import get from 'lodash/get';
import Image from 'next/image';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { CustomButton } from './partials/custom-button';
// styles
import useStyles from './styles';

export default function CareerPageBannerSection(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const imageUrl = get(props, 'bannerBackground.url', null);
	const logoUrl = get(props, 'titleImage.url', null);

	const onClickCtaButton = () => {
		const form = document.getElementById('curr_form');
		if (form) form.scrollIntoView();
	};

	const renderCTAButton = (fullWidth = false) => (
		<Box>
			<CustomButton
				variant="contained"
				color="success"
				onClick={onClickCtaButton}
				className={classes.button}
				fullWidth={fullWidth}
			>
				{props.buttonText || 'Get Started'}
			</CustomButton>
		</Box>
	);

	return (
		<Box className={classes.root}>
			<Box width="100%">
				{imageUrl && (
					<Image
						src={imageUrl}
						className={classes.bannerBackground}
						loading="eager"
						layout="fill"
						priority={true}
						alt={
							props.bannerBackground.alternativeText ||
							props.bannerBackground.name
						}
					/>
				)}
				<Grid container className={classes.mainTextContainer}>
					<Grid container item sm={6}>
						<Box
							className={`${classes.textContainer} ${classes.mainTextSection}`}
						>
							<Box>
								<Box mb={2}>
									<TitleSection
										highlightTitle={props.highlightTitle}
										title={props.title}
										highlightTitleAfter={true}
										heading={'h1'}
										fontSize={props.titleFontSize || titleSizes.large}
										textAlign={'left'}
									/>
								</Box>
								<Box mb={2}>
									<Typography
										variant={'h6'}
										className={classes.headingDescription}
									>
										{props.description}
									</Typography>
								</Box>
								{!isMobile && renderCTAButton()}
							</Box>
						</Box>
					</Grid>
					<Grid container item sm={6} className={classes.logoContainer}>
						<Box className={classes.logoWrapper} position="relative">
							{logoUrl && (
								<ImageComponent
									priority={true}
									src={logoUrl}
									layout="fill"
									objectFit="contain"
									alt={
										props.titleImage.alternativeText || props.titleImage.name
									}
								/>
							)}
						</Box>
					</Grid>

					{isMobile && (
						<Grid item xs={12}>
							<Box className={classes.buttonContainer}>
								{renderCTAButton(true)}
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
		</Box>
	);
}

CareerPageBannerSection.propTypes = {
	bannerBackground: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	highlightTitle: PropTypes.string,
	description: PropTypes.string.isRequired,
	titleImage: PropTypes.object.isRequired,
	analyticsProps: PropTypes.object,
	buttonText: PropTypes.string,
};
CareerPageBannerSection.defaultProps = {
	analyticsProps: {},
};
