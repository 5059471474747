import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';
import { s3PostfixKeys } from '@/utils/constants/s3PostfixKey';
import { titleSizes } from '@/utils/constants/titleSizes';

// mui
import { Box, Card, Grid, Typography } from '@mui/material';

// components
import FormBoxWrap from '@/components/shared/formBoxWrap';
import TitleSection from '@/components/shared/titleSection';
import Image from 'next/image';

// utils
import useFormBoxProps from '@/utils/hooks/use-form-box-props';
import { get, lowerCase } from 'lodash';

// styles
import useStyles from './style';

// Apis
import { signRequest } from '@/api';

export default function CareerPageFormSection(props) {
	const { classes } = useStyles();

	const isMobile = useSelector((state) => state.common.isMobile);
	const formBoxProps = useFormBoxProps(props);

	const logoUrl = get(props, 'formIcon.url', '');
	const logoAltText = get(props, 'formIcon.alternativeText', '');

	const formulateDataToBeSubmitted = (data) => {
		let reqBody = { ...data };
		if (Array.isArray(reqBody.documents) && reqBody.documents.length > 0) {
			reqBody.document_url = reqBody.documents[0].url;
		}
		delete reqBody.documents;
		return reqBody;
	};

	const analyticsProps = {
		...props.analyticsProps,
		formTitle: props.title,
		isFormModal: props.isModal,
	};

	return (
		<Box id="curr_form" className={classes.root}>
			<Grid container>
				<Grid item xs={12} md={5}>
					<Box className={classes.textSection}>
						<Box className={classes.iconContainer}>
							<Box className={classes.iconWrapper}>
								<Image
									src={logoUrl}
									title={logoAltText}
									layout="fill"
									objectFit="cover"
									objectPosition="50% 50%"
									alt={logoAltText}
								/>
							</Box>
						</Box>
						<Box className={classes.contentContainer} pr={!isMobile && 3}>
							<TitleSection
								title={props.formTitle}
								textAlign={isMobile ? 'center' : 'left'}
								fontSize={props.titleFontSize || titleSizes.medium}
							/>
							{!isMobile && (
								<Typography className={classes.description}>
									{props.formDescription}
								</Typography>
							)}
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={7}>
					<Card className={classes.formCard}>
						<FormBoxWrap
							{...formBoxProps}
							title=""
							description=""
							disableWhatsappCheck={true}
							analyticsProps={analyticsProps}
							formulateDataToBeSubmitted={formulateDataToBeSubmitted}
							uploadConfig={{
								awsConfig: constants.awsConfig,
								s3Postfix: s3PostfixKeys.career
									? lowerCase(s3PostfixKeys.career)
									: lowerCase(constants.envName),
								selfAuthApiCall: signRequest,
							}}
						/>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
}

CareerPageFormSection.propTypes = {
	pageSlug: PropTypes.string,
	formTitle: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	formDescription: PropTypes.string,
	formIcon: PropTypes.object,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object.isRequired,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};
CareerPageFormSection.defaultProps = {
	analyticsProps: {},
	useOtpFlowForForm: false,
};
