import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const commonSectionStyles = {
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	};
	return {
		container: {
			display: 'flex',
			margin: '0 auto',
			gap: theme.spacing(4),
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		sectionOne: {
			...commonSectionStyles,
			width: '60%',
			gap: theme.spacing(2),
		},
		sectionTwo: {
			...commonSectionStyles,
			width: '40%',
			alignItems: 'center',
		},
		imageHeadingWrap: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
			gap: theme.spacing(2),
			marginBottom: theme.spacing(3),
			[theme.breakpoints.down('md')]: {
				textAlign: 'center',
			},
		},
		titleImageWrap: {
			position: 'relative',
			width: '100%',
			height: '446px',
			borderRadius: '15px',
			overflow: 'hidden',
			[theme.breakpoints.down('xl')]: {
				height: '415px',
			},
			[theme.breakpoints.down('md')]: {
				height: '345.35px',
			},
			[theme.breakpoints.down('sm')]: {
				height: '187.4px',
			},
		},
		dateTime: {
			...theme.typography.h5,
			fontWeight: theme.typography.fontWeightMedium,
			fontStyle: 'italic',
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.typography.h6.fontSize,
			},
		},
		content: {
			fontWeight: theme.typography.fontWeightMedium,
			overflowWrap: 'break-word',
			'& :first-child': {
				marginTop: 0,
			},
			'& a': {
				color: theme.palette.common.linkBlue,
				'&:hover': {
					textDecoration: 'underline',
				},
			},
		}
	};
});

export default useStyles;
