import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	mainSection: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(5),
	},
	locationSection: {
		flexBasis: 'calc(60% - 24px)',
		[theme.breakpoints.down('md')]: {
			flexBasis: '100%',
		},
	},
	iFrame: {
		border: 'none',
		height: '450px',
		[theme.breakpoints.down('md')]: {
			height: '400px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '350px',
		},
	},
	contactInfoWrap: {
		flexBasis: 'calc(40% - 24px)',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(5),
		[theme.breakpoints.down('md')]: {
			flexBasis: '100%',
			alignItems: 'center',
			textAlign: 'center',
		},
	},
	socialIconWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(2),
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	label: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	text: {
		fontSize: '18px',
	},
}));

export default useStyles;
