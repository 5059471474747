// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import FormBoxWrap from '@/components/shared/formBoxWrap';
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';

// utils
import useFormBoxProps from '@/utils/hooks/use-form-box-props';

// material
import { Alert, Box, Snackbar, Typography } from '@mui/material';

// Defaults
import moment from 'moment-mini';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// styles
import useStyles from './style';

const otherInfo = 'Other Info';
export default function EventDetailPageBannerSection(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const formBoxProps = useFormBoxProps(props);

	const hasEventEnded = () => {
		return moment().isSameOrAfter(moment(props.endTime));
	};
	const [eventEnded, setEventEnded] = React.useState(hasEventEnded);

	const analyticsProps = {
		section: 'HERO_SECTION',
		...props.analyticsProps,
	};

	React.useEffect(() => {
		if (eventEnded) {
			return;
		}

		const intervalId = setInterval(() => {
			if (hasEventEnded()) {
				setEventEnded(true);
				clearInterval(intervalId);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [props.endTime]);

	return (
		<div>
			{isMobile && eventEnded && (
				<Box mb={2} position={'sticky'} top={'76px'} zIndex={10}>
					<Alert
						severity="warning"
						variant="filled"
						sx={{ fontSize: '18px', fontWeight: 500 }}
					>
						This event has ended
					</Alert>
				</Box>
			)}
			<Box className={classes.container}>
				<Box className={classes.sectionOne}>
					<Box className={classes.imageHeadingWrap}>
						<Box className={classes.titleImageWrap}>
							<ImageComponent
								src={props.titleImage.url}
								alt={props.titleImage.alternativeText}
								priority={true}
								layout="fill"
								objectFit={'fill'}
							/>
						</Box>
						<Box className={classes.titleWrap}>
							<TitleSection
								highlightTitle={props.highlightTitle}
								title={props.title}
								highlightTitleAfter={false}
								textAlign={'left'}
								fontSize={props.titleFontSize || titleSizes.large}
							/>
						</Box>
						<Box>
							<Typography component="p" className={classes.dateTime}>
								{moment(props.dateTime).format('Do MMM, hh:mm A')}
							</Typography>
						</Box>
					</Box>
					<Box>
						<Typography variant="subtitle2" color="text.disabled">
							{otherInfo}
						</Typography>
						<Markdown className={classes.content}>
							{isMobile ? props.mobileDescription : props.description}
						</Markdown>
					</Box>
				</Box>
				{formBoxProps && !eventEnded && (
					<Box className={classes.sectionTwo} id={props.formId}>
						<FormBoxWrap
							{...formBoxProps}
							fullWidthSubmitButton={true}
							timer={props.timer}
							analyticsProps={analyticsProps}
						/>
					</Box>
				)}
				<Snackbar open={!isMobile && eventEnded}>
					<Alert severity="warning" variant="filled">
						This event has ended
					</Alert>
				</Snackbar>
			</Box>
		</div>
	);
}

EventDetailPageBannerSection.propTypes = {
	titleImage: PropTypes.object,
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	description: PropTypes.string.isRequired,
	mobileDescription: PropTypes.string.isRequired,
	timer: PropTypes.object,
	endTime: PropTypes.string,
	dateTime: PropTypes.string,
	formId: PropTypes.string.isRequired,
	useOtpFlowForForm: PropTypes.bool,
	form: PropTypes.object,
	authUserForm: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
	globalFormConfig: PropTypes.object,
	paymentDetail: PropTypes.object,
	analyticsProps: PropTypes.object,
	searchQueryValues: PropTypes.object,
};
EventDetailPageBannerSection.defaultProps = {
	titleImage: null,
	analyticsProps: {},
	formPageConstants: {},
	formPageConfig: {},
	globalFormConfig: {},
	searchQueryValues: {},
	paymentDetail: {},
};
