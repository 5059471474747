import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	cardWrap: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(8),
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			gap: theme.spacing(4),
			flexDirection: 'column',
		},
		[theme.breakpoints.down('sm')]: {
			gap: theme.spacing(2),
		},
	},
	imageSection: {
		padding: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			width: '100%',
			padding: 0,
		},
	},
	imageWrap: {
		width: '500px',
		height: '300px',
		position: 'relative',
		borderRadius: '15px',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '380px',
		},
		[theme.breakpoints.down('sm')]: {
			height: '225px',
		},
	},
	descriptionSection: {
		width: '100%',
	},
	buttonWrap: {
		marginTop: '30px',
		display: 'flex',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	ctaButton: {
		paddingLeft: '30px',
		paddingRight: '30px',
	},
	rowReverse: {
		flexDirection: 'row-reverse',
	},
	textCenter: {
		textAlign: 'center',
	},
}));

export default useStyles;
