import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	contentWrap: {
		backgroundColor: 'transparent',
		textAlign: 'left',
	},
	readMoreTextWrap: {
		color: theme.palette.common.linkBlue,
		cursor: 'pointer',
	},
}));

export default useStyles;
