import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	couponSectionWrap: {
		marginTop: '26px',
	},
	inputTextLabelWrap: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	couponFormTriggerText: {
		cursor: 'pointer',
		textDecoration: 'underline',
	},
}));

export default useStyles;
