import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const commonSectionStyles = {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	};
	return {
		container: {
			display: 'flex',
			margin: theme.spacing(0, 'auto'),
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				margin: theme.spacing(0, 'auto'),
			},
		},
		descriptionSection: {
			...commonSectionStyles,
			width: '55%',
			padding: theme.spacing(2),
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(2, 0),
			},
		},
		otherSection: {
			...commonSectionStyles,
			width: '45%',
		},
		title: {
			...theme.typography.h2,
			fontWeight: theme.typography.fontWeightBold,
			[theme.breakpoints.down('lg')]: {
				fontSize: theme.typography.h3.fontSize,
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.typography.h4.fontSize,
			},
		},
		titleImageWrap: {
			position: 'relative',
			width: '100%',
			height: '500px',
			overflow: 'hidden',
			[theme.breakpoints.down('sm')]: {
				height: '280px',
			},
		},
		image: {
			'-webkit-transition': 'opacity 1s ease-in-out',
			'-moz-transition': 'opacity 1s ease-in-out',
			'-o-transition': 'opacity 1s ease-in-out',
			transition: 'opacity 1s ease-in-out',
		},
		appLinkContainer: {
			display: 'flex',
			justifyContent: 'center',
		},
		appLinkWrap: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
			marginBottom: theme.spacing(5),
		},
		contentLimitedWidth: {
			maxWidth: '550px',
			margin: '0 auto',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '100%',
			},
		},
		loginFormWrap: {
			width: '100%',
			maxWidth: '500px',
		},
		content: {
			overflowWrap: 'break-word',
			'& a': {
				color: theme.palette.common.linkBlue,
				'&:hover': {
					textDecoration: 'underline',
				},
			},
		},
		addSpaceAfter: {
			'&::after': {
				content: '" "',
				whiteSpace: 'pre',
			},
		},
		zeroOpacity: {
			opacity: 0,
		},
	};
});

export default useStyles;
