import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// mui
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

// components
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';

export default function RightAlignedSection(props) {
	const { isDesktop, isMobile } = useSelector((state) => state.common);
	const { classes } = useStyles();

	const renderImageSection = () => {
		if (!props.imageUrl) return null;

		if (isMobile) {
			return (
				<Box className={classes.imageSection}>
					<ImageComponent
						src={props.imageUrl}
						alt={props.imageAltText}
						width="340px"
						height="300px"
					/>
				</Box>
			);
		}
		return (
			<Box className={classes.imageSection}>
				<Box className={classes.imageWrap}>
					<ImageComponent
						src={props.imageUrl}
						alt={props.imageAltText}
						layout="fill"
						objectFit="contain"
					/>
				</Box>
			</Box>
		);
	};

	return (
		<Box className={classes.root}>
			{!isDesktop && renderImageSection()}
			<Box className={classes.contentSection}>
				<Typography
					component="h2"
					className={isDesktop ? '' : classes.textCenter}
				>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						highlightTitleAfter={true}
						fontSize={props.titleFontSize}
						textAlign={isDesktop ? 'left' : 'center'}
					/>
				</Typography>
				<Typography className={classes.description}>
					{props.description}
				</Typography>
			</Box>
			{isDesktop && renderImageSection()}
		</Box>
	);
}

RightAlignedSection.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	imageAltText: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	highlightTitle: PropTypes.string,
	description: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};
RightAlignedSection.defaultProps = {
	analyticsProps: {},
};
