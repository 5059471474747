import constants from '@/constants';
import PropTypes from 'prop-types';
// comps
import { Box } from '@mui/material';
// styles
import { useStyles } from './style';

export default function Map({ currentGooglePlacesId }) {
	const {classes} = useStyles();
	const placesAPI =
		constants.googleMapsApiUrl + 'q=place_id:' + currentGooglePlacesId;
	return (
		<Box className={classes.mapContainer}>
			<div
				dangerouslySetInnerHTML={{
					__html: `<iframe
                    width="100%"
                    height="500"
                    frameborder="0"
                    style="border:0"
                    src="${placesAPI}"
                    allowFullScreen
                ></iframe>`,
				}}
			/>
		</Box>
	);
}

Map.propTypes = {
	currentGooglePlacesId: PropTypes.string.isRequired,
};
