import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	scholarshipBannerSection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'unset',
			flexDirection: 'column',
			gap: 0,
		},
	},
	descriptionWrap: {
		width: '63%',
		display: 'flex',
		alignItems: 'center',
		'& > * + *': {
			marginLeft: '14px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	submitButton: {
		margin: theme.spacing(1, 0),
		borderRadius: '37px',
		textTransform: 'capitalize',
		color: theme.palette.common.white,
		background:
			'linear-gradient(263.25deg, rgba(245, 131, 32, 0.87) 0%, rgba(241, 105, 33, 0.987054) 81.82%, #D75312 109%)',
		boxShadow: '6.84606px 11.4101px 17.1151px rgba(208, 102, 8, 0.3)',
		padding: '12px 43px',
		fontSize: '18px',
		textWrap: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2, 0),
			padding: '8px 35px',
		},
	},
	logoWrap: {
		width: '80px',
		height: '80px',
		position: 'relative',
		borderRadius: '50%',
		border: `1px solid ${theme.palette.text.disabled}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
		flexShrink: 0,
		backgroundColor: theme.palette.common.white,
		[theme.breakpoints.down('md')]: {
			width: '60px',
			height: '60px',
			flexShrink: 0,
		},
	},
	logo: {
		padding: '12px !important',
	},
}));

export default useStyles;
