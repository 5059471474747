// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	nextArrow: {
		position: 'absolute',
		top: '50%',
		right: 0,
		transform: 'translateY(-50%)',
		cursor: 'pointer',
		zIndex: 100,
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		background: theme.palette.primary.main,
	},
}));

export default useStyles;
