import { makeStyles } from 'tss-react/mui';

const textWrapStyles = {
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
};

const useStyles = makeStyles()((theme, { highlight }) => ({
	planCard: {
		background: theme.palette.common.white,
		border: `1px solid ${theme.palette.common.grey}`,
		borderRadius: '22px',
		padding: '29px 27.53px',

		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		overflow: 'hidden',
	},

	titleWrap: {
		borderBottom: `0.84319px solid ${
			highlight ? theme.palette.common.white : '#A9A9A9'
		}`,
		minHeight: '155.84px',
		maxWidth: '268.95px',
	},
	titleAndIcon: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	arrowIcon: {
		color: highlight ? theme.palette.common.white : theme.palette.primary.main,
	},
	title: {
		fontSize: '26px',
		fontWeight: theme.typography.fontWeightSemiBold,

		...textWrapStyles,
	},
	subTitle: {
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '18px',
		color: highlight ? theme.palette.common.white : '#6D6D6D',

		...textWrapStyles,
	},
	featureWrap: {
		display: 'flex',
		flexDirection: 'column',
		gap: '21px',
		marginTop: '33.4px',
	},
	planCardBottomWrap: {
		marginTop: '23.43px',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: '100%',
	},
	tagWrap: {
		height: '89.34px',
		position: 'relative',
	},
	tag: {
		fontWeight: theme.typography.fontWeightSemiBold,
		backgroundColor: theme.palette.common.white,
		color: theme.palette.primary.main,
		borderRadius: '21.4175px',
		display: 'inline-block',
		padding: '3.5px 14.13px',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
		width: 'max-content',
		border: `1px solid ${theme.palette.primary.main}`,
		maxWidth: '100%',
		...textWrapStyles,
	},
	highlightPlanCard: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
		boxShadow: '5.22949px 8.71582px 13.0737px rgba(208, 102, 8, 0.3)',
	},
}));

export default useStyles;
