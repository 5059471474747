import getPlatform from '@/utils/getPlatform';

export const websiteTextValues = {
	loginBoxTitle: 'Talk to our counsellors',
	loginBoxButtonText: 'Register now',
};

export const partnerTextValues = {
	loginBoxTitle: 'Become a partner today',
	loginBoxButtonText: 'Register now',
};

export const textValues =
	getPlatform() === 'partner' ? partnerTextValues : websiteTextValues;
