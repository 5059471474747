import qs from 'qs';

export const getQueryString = (searchQueryValues, queryKeyConfig) => {
	if (!searchQueryValues) return '';

	const _searchQueryValues = { ...searchQueryValues };
	if (queryKeyConfig) {
		for (const key in _searchQueryValues) {
			if (key in queryKeyConfig) {
				_searchQueryValues[queryKeyConfig[key]] = _searchQueryValues[key];
				delete _searchQueryValues[key];
			}
		}
	}
	return qs.stringify(_searchQueryValues);
};
