import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function HightlightCardV2(props) {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);
	return (
		<Box className={classes.container}>
			{props.extraDetailsText && (
				<Box>
					<Typography className={classes.extraDetailsText}>
						{props.extraDetailsText}
					</Typography>
				</Box>
			)}
			<Box className={classes.titleWrap}>
				<Typography
					component="h6"
					variant={isMobile ? 'body1' : 'h6'}
					className={classes.title}
				>
					{props.title}
				</Typography>
			</Box>
			{props.description && (
				<Box>
					<Typography>{props.description}</Typography>
				</Box>
			)}
		</Box>
	);
}

HightlightCardV2.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	extraDetailsText: PropTypes.string,
};
