// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	sectionWrapper: {
		display: 'flex',
		justifyContent: 'center',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 2),
		},
	},
	sectionList: {
		display: 'flex',
		overflowX: 'scroll',
		gap: theme.spacing(4),
		scrollBehavior: 'smooth',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	sectionWrap: {
		minWidth: 'fit-content',
		cursor: 'pointer',
	},
	section: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	activeSection: {
		color: theme.palette.primary.main,
	},
	arrowLeft: {
		position: 'absolute',
		left: '-30px',
		[theme.breakpoints.down('sm')]: {
			left: '-10px',
		},
	},
	arrowRight: {
		position: 'absolute',
		right: '-30px',
		[theme.breakpoints.down('sm')]: {
			right: '-10px',
		},
	},
	hidden: {
		display: 'none !important',
	},
}));

export default useStyles;
