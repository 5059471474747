import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { listTypes } from './constants';

// material
import { Box } from '@mui/material';

// components
import ListGroup from '@/components/shared/recentlyAddedSection/partials/listGroup';
import TitleSection from '@/components/shared/titleSection';

// actions
import { blogPageListAction } from '@/store/actions/pageActions/blogPage';
import { eventPageListAction } from '@/store/actions/pageActions/eventPage';

// utils
import { shapeBlogItem, shapeEventItem, shapeKioskBlogItem } from './util';

// styles

import useStyles from './style';

export default function RecentlyAddedSection({ exclude, itemCount, ...props }) {
	const { classes } = useStyles();

	const [loading, setLoading] = React.useState(false);
	const [cardList, setCardList] = React.useState(props.cardList || []);

	const analyticsProps = {
		...props.analyticsProps,
		section: 'RECENTLY_ADDED_SECTION',
	};

	let listAction, shapeDataFunction;
	switch (props.listType) {
		case listTypes.v1: {
			listAction = eventPageListAction;
			shapeDataFunction = shapeEventItem;
			break;
		}
		case listTypes.v2: {
			listAction = blogPageListAction;
			shapeDataFunction = shapeBlogItem;
			break;
		}
		case listTypes.v3: {
			listAction = blogPageListAction;
			shapeDataFunction = shapeKioskBlogItem;
			break;
		}
	}

	React.useEffect(() => {
		if (!listAction) return;

		setLoading(true);

		listAction({
			page: 1,
			pageSize: itemCount,
			exclude: exclude || '',
		})
			.then((result) => {
				const dataList = result.data.map(({ attributes }) =>
					shapeDataFunction(attributes)
				);
				setCardList(dataList);
			})
			.finally(() => setLoading(false));
	}, [exclude, itemCount]);

	return (
		<Box>
			{(props.title || props.highlightTitle) && (
				<Box className={classes.titleSection}>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						subTitle={props.subTitle}
						highlightTitleAfter={false}
						fontSize={props.titleFontSize || titleSizes.medium}
					/>
				</Box>
			)}

			<Box>
				<ListGroup
					cardList={cardList}
					listType={props.listType}
					itemCount={itemCount}
					alignment={props.alignment}
					slider={props.slider}
					link={props.link}
					loading={loading}
					analyticsProps={analyticsProps}
				/>
			</Box>
		</Box>
	);
}

RecentlyAddedSection.propTypes = {
	highlightTitle: PropTypes.string,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	slider: PropTypes.bool,
	itemCount: PropTypes.number,
	listType: PropTypes.string.isRequired,
	alignment: PropTypes.string,
	link: PropTypes.string,
	exclude: PropTypes.string,
	cardList: PropTypes.array,
	analyticsProps: PropTypes.object,
};

RecentlyAddedSection.defaultProps = {
	analyticsProps: {},
	itemCount: 6,
};
