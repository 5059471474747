import PropTypes from 'prop-types';

// components
import GenericButton from '@/components/widgets/genericButton';

// material
import { AddCircle, Assignment } from '@mui/icons-material';
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function LinkCard({ button }) {
	const { classes } = useStyles();

	return (
		<Box className={classes.linkCard}>
			<Box className={classes.iconWrap}>
				<Assignment classes={{ root: classes.icon }} />
				<AddCircle classes={{ root: classes.subIcon }} />
			</Box>
			{button && (
				<GenericButton
					label={button.label}
					link={button.link}
					fullWidth={false}
					variant={button.variant}
					className={classes.button}
				/>
			)}
		</Box>
	);
}

LinkCard.propTypes = {
	button: PropTypes.object,
};
