import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	counterSectionV2Wrap: {
		display: 'flex',
		gap: theme.spacing(4),
		justifyContent: 'center',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	counterSectionV2SectionOne: {
		flex: '1 1 50%',
	},
	counterSectionV2SectionTwo: {
		flex: '1 1 50%',
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'flex-end',
		gap: theme.spacing(3),
		[theme.breakpoints.down('md')]: {
			flex: '1 0 100%',
			justifyContent: 'center',
		},
	},
	cardV2Wrap: {
		flex: '1 1 calc(50% - 48px)',
	},
	imageWrap: {
		position: 'relative',
		width: '75px',
		height: '75px',
		borderRadius: '50%',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.common.lightOrange,
		marginBottom: theme.spacing(2),
	},
	logo: {
		transform: 'rotate(-90deg)',
		width: '50px',
		height: '50px',
		color: theme.palette.common.white,
	},
	fontWeightLight: {
		fontWeight: theme.typography.fontWeightLight,
	},
}));

export default useStyles;
