// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box } from '@mui/material';

// Default
import PropTypes from 'prop-types';

// components
import TitleSection from '@/components/shared/titleSection';
import ServiceGroup from './partials/serviceGroup';

// styles
import useStyles from './style';

export default function ServiceSection(props) {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={false}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>

			<Box>
				<ServiceGroup
					cardListTheme={props.cardListTheme}
					cardList={props.cardList}
				/>
			</Box>
		</Box>
	);
}

ServiceSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	cardListTheme: PropTypes.string.isRequired,
	cardList: PropTypes.array,
};
ServiceSection.defaultProps = {
	subTitle: '',
};
