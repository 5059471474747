import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

//components
import FormSection from '@/components/shared/commentSection/partials/formSection';
import ListSection from '@/components/shared/commentSection/partials/listSection';
import TitleSection from '@/components/shared/titleSection';

// material
import { Box } from '@mui/material';

// actions
import { commentListAction } from '@/store/actions/comment';
import { useSelector } from 'react-redux';

//style
import useStyles from './style';

export default function CommentSection({
	source,
	pageId,
	itemCount,
	...props
}) {
	const { classes } = useStyles();

	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const isMobile = useSelector((state) => state.common.isMobile);
	const analyticsProps = {
		...props.analyticsProps,
		source,
		sourceId: pageId,
	};

	const constants = {
		source,
		sourceId: pageId,
	};

	const handleSuccessCallback = (comment) => {
		comment.attributes.id = comment.id;
		setData((existingData) => [comment.attributes, ...existingData]);
	};

	React.useEffect(() => {
		setLoading(true);
		commentListAction({
			source,
			sourceId: pageId,
			pageSize: itemCount,
		})
			.then((result) => {
				const commentList = result.data.map((item) => {
					item.attributes.id = item.id;
					return item.attributes;
				});
				setData(commentList);
			})
			.finally(() => setLoading(false));
	}, [source, pageId, itemCount]);

	return (
		<Box>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={true}
					fontSize={props.titleFontSize || titleSizes.medium}
					textAlign={isMobile ? 'center' : 'left'}
				/>
			</Box>
			<Box className={classes.formSectionWrap}>
				<FormSection
					constants={constants}
					handleSuccessCallback={handleSuccessCallback}
					analyticsProps={analyticsProps}
				/>
			</Box>
			<Box>
				<ListSection data={data} loading={loading} />
			</Box>
		</Box>
	);
}

CommentSection.propTypes = {
	source: PropTypes.string.isRequired,
	pageId: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	itemCount: PropTypes.number,
	highlightTitle: PropTypes.string,
	analyticsProps: PropTypes.object,
};

CommentSection.defaultProps = {
	analyticsProps: {},
	itemCount: 6,
};
