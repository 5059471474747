import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			padding: theme.spacing(0, 3),
			height: '100%',
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(0, 1),
				marginBottom: theme.spacing(3),
			},
		},
		titleWrap: {
			marginBottom: theme.spacing(1),
			textAlign: 'center',
		},
		mapSection: {
			marginBottom: theme.spacing(6),
		},
		locationListSection: {
			display: 'flex',
			gap: theme.spacing(4),
			flexWrap: 'wrap',
		},
		locationItemWrap: {
			flexGrow: 0,
			flexShrink: 0,
			flexBasis: 'calc(33% - 24px)',
			[theme.breakpoints.down('md')]: {
				flexBasis: 'calc(50% - 16px)',
			},
			[theme.breakpoints.down('sm')]: {
				flexBasis: '100%',
			},
		},
	};
});
