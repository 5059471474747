// components
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';
import VideoPlayer from '@/components/widgets/videoPlayer';
// material
import { Box, Rating, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// styles
import useStyles from './style';

export default function TestimonialCardV1({ image, ...props }) {
	const { classes } = useStyles(props);

	return (
		<Box className={classes.testimonialCardContainer}>
			{(props.videoLink || (image && image.url)) && (
				<Box className={classes.testimonialVideoWrap}>
					{props.videoLink ? (
						<VideoPlayer url={props.videoLink} height="215px" />
					) : (
						<Box className={classes.imageWrap}>
							<ImageComponent
								src={image.url}
								alt={image.alternativeText}
								layout="fill"
								objectFit="cover"
							/>
						</Box>
					)}
				</Box>
			)}

			<Box className={classes.testimonialUserInfoWrap}>
				<Typography color="primary" variant="h6" className={classes.textCenter}>
					{props.name}
				</Typography>
				<Typography className={classes.textCenter}>
					{props.universityName}
				</Typography>
				<Rating value={props.ratting} readOnly />
			</Box>
			<Box className={classes.testimonialDescriptionWrap}>
				<Markdown className={classes.textCenter}>
					{props.testimonialText}
				</Markdown>
			</Box>
		</Box>
	);
}

TestimonialCardV1.propTypes = {
	videoLink: PropTypes.string,
	image: PropTypes.object,
	name: PropTypes.string.isRequired,
	universityName: PropTypes.string.isRequired,
	ratting: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	testimonialText: PropTypes.string.isRequired,
};
TestimonialCardV1.defaultProps = {
	minWidth: '100%',
	maxWidth: '100%',
	videoLink: '',
	image: null,
};
