import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width }) => ({
	wrap: {
		width: width || '100%',
		margin: '0 auto',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
	},
	sectionOne: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	sectionTwo: {
		borderRadius: '30%',
		boxShadow:
			'0px 0px 40px rgb(245 106 32 / 10%), 0px 0px 40px rgb(245 131 32 / 10%), 0px 0px 40px rgb(245 211 32 / 10%)',
	},
}));

export default useStyles;
