import { store } from '@/store';
import { actionTypes, defaultConfig } from './constants';

// actions
import { goToExternalRoute } from '@/store/actions';
import { goToRoute } from '@/store/actions/navigation';
import { getCenterLink } from '@/store/actions/pageActions/redirectionPage';

// utils
import constants from '@/constants';
import redirectToPlatform from '@/utils/redirect-to-platform';
import get from 'lodash/get';

/*
Config From Strapi Example: 
{
	"redirectToLink": {
		"message": "Redirection to Link",
		"actionType": "redirectToLink"
	},
	"redirectToCompass": {
		"message": "Redirection to Compass",
		"actionType": "redirectToCompass"
	}
}
*/

const actions = {
	[actionTypes.redirectToCompass]: (data) => {
		const user = get(data, 'storeData.user.data');
		if (user && user.id) {
			return async () => redirectToPlatform(user, 2000);
		}
	},
	[actionTypes.redirectToCompassEvent]: (data) => {
		const params = {
			onboarding_type: 'fairs',
			id: data.searchQueryValues.cms_event_id,
		};
		const url = constants.studentPlatformUrl + `events`;
		return async () => goToExternalRoute(url, null, {}, params, false);
	},
	[actionTypes.redirectToLink]: (data) => {
		if (get(data, 'searchQueryValues.redirectLink')) {
			const url = data.searchQueryValues.redirectLink;
			return async () => goToExternalRoute(url, null, {}, null, false);
		}
	},
	[actionTypes.redirectToCenter]: (data) => {
		const reqBody = { ...data.searchQueryValues };
		delete reqBody.action;
		return async () => {
			const response = await getCenterLink(reqBody);
			if (response && response.redirect_url) {
				goToExternalRoute(response.redirect_url, null, {}, null, false);
				return;
			}
			store && store.dispatch(goToRoute('/'));
		};
	},
};
export const getConfig = ({ config, searchQueryValues }) => {
	let _config;
	if (config && searchQueryValues)
		_config = config[searchQueryValues.action] && {
			...config[searchQueryValues.action],
		};
	if (!_config) return { ...defaultConfig };

	const storeData = store && store.getState();
	const action =
		actions[_config.actionType] &&
		actions[_config.actionType]({ storeData, searchQueryValues });
	if (!action) return { ...defaultConfig };

	_config.action = action;
	return _config;
};
