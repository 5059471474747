import PropTypes from 'prop-types';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function DiscountAmount({ originalAmount, discountAmount }) {
	const { classes } = useStyles();

	if (discountAmount) {
		return (
			<Box className={classes.amountWrap}>
				<Typography component="span" className={classes.originalAmount}>
					{`₹${originalAmount}/-`}
				</Typography>

				<Typography
					component="span"
					variant="h3"
					fontWeight="fontWeightBold"
					color="primary"
				>
					{`₹${discountAmount}/-`}
				</Typography>
			</Box>
		);
	}
	return (
		<Box className={classes.amountWrap}>
			<Typography
				component="span"
				variant="h3"
				fontWeight="fontWeightBold"
				textAlign="center"
			>{`₹${originalAmount}/-`}</Typography>
		</Box>
	);
}

DiscountAmount.propTypes = {
	originalAmount: PropTypes.string.isRequired,
	discountAmount: PropTypes.string,
};
