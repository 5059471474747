// constants
import { testimonialCardTypes } from '@/components/shared/testimonialSection/constants';
// components
import TestimonialSectionV1 from '@/components/shared/testimonialSection/partials/testimonialSectionV1';
// Default
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// styles
import useStyles from './style';

export default function TestimonialSlider({
	cardListTheme,
	cardList,
	...props
}) {
	const { classes } = useStyles();
	const isDesktop = useSelector((state) => state.common.isDesktop);

	let SectionComponent,
		sectionProps = {
			inActiveClassName: classes.disableClick,
			cardList,
			analyticsProps: props.analyticsProps,
			analyticsPropsSubSections: props.analyticsPropsSubSections,
		};

	switch (cardListTheme) {
		case testimonialCardTypes.v1: {
			SectionComponent = TestimonialSectionV1;
			sectionProps.width = '315px';
			sectionProps.centerFocusedSlider = cardList.length > 3;
			sectionProps.slidesToShow = isDesktop ? 5 : 1;
			if (isDesktop && cardList.length < 6) sectionProps.slidesToShow = 3;
			break;
		}

		default: {
			SectionComponent = TestimonialSectionV1;
			sectionProps.width = '315px';
			sectionProps.centerFocusedSlider = false;
			sectionProps.slidesToShow = isDesktop ? 3 : 1;
		}
	}

	return <SectionComponent {...sectionProps} />;
}

TestimonialSlider.propTypes = {
	cardListTheme: PropTypes.string,
	cardList: PropTypes.array,
};
TestimonialSlider.defaultProps = {
	cardListTheme: testimonialCardTypes.v1,
};
