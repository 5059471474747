import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { imageAlignmentTypes, variantTypes } from './constants';

// components
import TitleSection from '@/components/shared/titleSection';
import CTAButton from '@/components/widgets/ctaButton';
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function AboutUsPageBannerSection({
	variant,
	imageAlignment,
	ctaButton,
	titleImages,
	...props
}) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const [activeImageIndex, setActiveImageIndex] = React.useState(
		titleImages.length > 0 ? 0 : null
	);

	const descriptionText = isMobile
		? props.mobileDescription
		: props.description;

	const CTAButtonElement = ctaButton && (
		<Box className={classes.ctaButtonWrap}>
			<CTAButton
				pageSlug={props.pageSlug}
				fullWidth={true}
				label={ctaButton.label}
				link={ctaButton.link}
				form={props.form}
				formId={props.formId}
				openLoginModal={ctaButton.openLoginModal}
				openFormModal={ctaButton.openFormModal}
				scrollToForm={ctaButton.scrollToForm}
				formPageConstants={props.formPageConstants}
				formPageConfig={props.formPageConfig}
				useOtpFlowForForm={props.useOtpFlowForForm}
				paymentDetail={props.paymentDetail}
				analyticsProps={props.analyticsProps}
				shadow={ctaButton.shadow}
			/>
		</Box>
	);

	React.useEffect(() => {
		let intervalID;
		if (titleImages.length > 0) {
			intervalID = setInterval(() => {
				setActiveImageIndex((currentActiveIndex) => {
					return currentActiveIndex + 1 >= titleImages.length
						? 0
						: currentActiveIndex + 1;
				});
			}, 5000);
		}

		return () => intervalID && clearInterval(intervalID);
	}, []);

	return (
		<Box
			className={cx(
				imageAlignment === imageAlignmentTypes.v1
					? classes.row
					: classes.rowReverse,
				classes.container
			)}
		>
			<Box className={classes.descriptionSection}>
				<Box
					className={
						variant === variantTypes.v1
							? classes.contentLimitedWidth
							: classes.contentLimitedWidthSmall
					}
				>
					<Box
						className={
							variant === variantTypes.v1 || isMobile ? classes.textCenter : ''
						}
					>
						<TitleSection
							highlightTitle={props.highlightTitle}
							title={props.title}
							highlightTitleAfter={true}
							heading={'h1'}
							fontSize={props.titleFontSize || titleSizes.large}
						/>
					</Box>
					{descriptionText && (
						<Markdown className={classes.content} useCustomList={true}>
							{descriptionText}
						</Markdown>
					)}
				</Box>
				{!isMobile && CTAButtonElement}
			</Box>
			<Box className={classes.otherSection}>
				<Box
					className={
						variant === variantTypes.v1
							? classes.titleImageWrap
							: classes.titleImageSmallWrap
					}
				>
					{titleImages.map((image, index) => (
						<ImageComponent
							key={index}
							src={image.url}
							alt={image.alternativeText}
							className={`${classes.image} ${
								index !== activeImageIndex && classes.zeroOpacity
							}`}
							priority={true}
							layout="fill"
							objectFit="contain"
							objectPosition="top"
						/>
					))}
				</Box>
				{isMobile && CTAButtonElement}
			</Box>
		</Box>
	);
}

AboutUsPageBannerSection.propTypes = {
	pageSlug: PropTypes.string,
	titleImages: PropTypes.array,
	highlightTitle: PropTypes.string,
	title: PropTypes.string,
	titleFontSize: PropTypes.string,
	description: PropTypes.string,
	mobileDescription: PropTypes.string,
	ctaButton: PropTypes.object,
	variant: PropTypes.string,
	imageAlignment: PropTypes.string,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};
AboutUsPageBannerSection.defaultProps = {
	titleImages: [],
	variant: variantTypes.v1,
	imageAlignment: imageAlignmentTypes.v1,
	analyticsProps: {},
};
