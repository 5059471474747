import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function HightlightCardV1(props) {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);
	return (
		<Box className={classes.container}>
			<Box>
				<Typography
					component="h6"
					variant={isMobile ? 'body1' : 'h6'}
					className={classes.title}
				>
					{props.title}
				</Typography>
			</Box>
			{props.extraDetailsText && (
				<Box>
					<Typography>{props.extraDetailsText}</Typography>
				</Box>
			)}
		</Box>
	);
}

HightlightCardV1.propTypes = {
	title: PropTypes.string.isRequired,
	extraDetailsText: PropTypes.string,
};
HightlightCardV1.defaultProps = {
	extraDetailsText: '',
};
