import PropTypes from 'prop-types';

// constant
import { textValues } from './constants';

// components
import ComponentSlider from '@/components/shared/componentSlider';
import ImageComponent from '@/components/widgets/image';
import NextArrow from '../nextArrow';
import PrevArrow from '../previousArrow';

// material
import { Close } from '@mui/icons-material';
import { Box, Dialog, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function ImageModalSection(props) {
	const { cx, classes } = useStyles();

	return (
		<Dialog
			open={props.open}
			classes={{ paper: classes.dialogPaper }}
			className={classes.dialog}
			maxWidth="lg"
		>
			<Box>
				<Typography variant="h4" className={classes.title}>
					{textValues.title}
				</Typography>
				<Box className={classes.closeIconWrap}>
					<Close fontSize="small" onClick={props.onClose} />
				</Box>
			</Box>
			<ComponentSlider
				focusOnSelect={true}
				slidesToShow={1}
				dots={true}
				useTransform={false}
				appendDots={(dots) => (
					<ul>
						{dots.map((item, index) => (
							<li
								key={index}
								className={cx(
									classes.dot,
									item.props.className ? classes.activeDot : ''
								)}
							>
								{item.props.children}
							</li>
						))}
					</ul>
				)}
				centerFocusedSlider={true}
				prevArrow={PrevArrow}
				nextArrow={NextArrow}
				arrows
				analyticsProps={props.analyticsProps}
				analyticsPropsSubSections={props.images.map(
					(item) => item.alternativeText
				)}
			>
				{props.images.map((image, index) => (
					<Box className={classes.imageWrap} key={index}>
						<ImageComponent
							src={image.url}
							alt={image.alternativeText}
							priority={true}
							layout="fill"
							objectFit="cover"
						/>
					</Box>
				))}
			</ComponentSlider>
		</Dialog>
	);
}

ImageModalSection.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	images: PropTypes.array,
	analyticsProps: PropTypes.object,
};

ImageModalSection.defaultProps = {
	open: false,
	images: [],
	analyticsProps: {},
};
