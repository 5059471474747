import PropTypes from 'prop-types';
// mui
import { Box, Typography } from '@mui/material';
// comps
import Image from 'next/image';
// styles
import useStyles from './style';

export default function ImageCard({
	img,
	altText,
	title,
	description,
	lastCard,
}) {
	const { classes } = useStyles({ lastCard });
	return (
		<Box className={classes.imageCardRoot}>
			<Box className={classes.imageContainer}>
				<Box className={classes.imageWrap}>
					<Image src={img} layout="fill" objectFit="contain" alt={altText} />
				</Box>
			</Box>
			<Box px={1}>
				<Typography className={classes.perkTitle} variant={'h6'}>
					{title}
				</Typography>
				<Typography className={classes.perkDescription}>
					{description}
				</Typography>
			</Box>
		</Box>
	);
}

ImageCard.propTypes = {
	img: PropTypes.string.isRequired,
	altText: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	lastCard: PropTypes.any,
};
