import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box } from '@mui/material';

// components
import ImageCard from '@/components/shared/imageCard';
import TitleSection from '@/components/shared/titleSection';

// utils
import { getConfig } from './util';

// style
import useStyles from './style';

export default function ImageGallerySection({
	cardList,
	imageShape,
	...props
}) {
	const { isDesktop, isMobile } = useSelector((state) => state.common);
	const deviceSize = isMobile ? 'mobile' : isDesktop ? 'desktop' : 'tablet';

	const config = getConfig(props.imageSize, imageShape, deviceSize);
	const { classes } = useStyles(config);

	return (
		<Box>
			<Box className={classes.sectionOne}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={false}
					fontSize={props.titleFontSize || titleSizes.medium}
					subTitle={props.subTitle}
				/>
			</Box>
			{cardList.length > 0 && (
				<Box className={classes.sectionTwo}>
					{cardList.map((item, index) => (
						<Box key={index} className={classes.itemWrap}>
							<ImageCard
								image={item.image}
								link={item.link}
								label={item.label}
								imageWidth={config.width}
								imageHeight={config.height}
								imageShape={imageShape}
							/>
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
}

ImageGallerySection.propTypes = {
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	imageSize: PropTypes.string.isRequired,
	imageShape: PropTypes.string.isRequired,
};
ImageGallerySection.defaultProps = {
	cardList: [],
};
