import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, Typography } from '@mui/material';

// components
import CTAButton from '@/components/widgets/ctaButton';
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';

// styles
import useStyles from './style';

export default function HighlightServiceCard({ image, ctaButton, ...props }) {
	const { classes, cx } = useStyles();
	const { isDesktop, isMobile } = useSelector((state) => state.common);

	return (
		<Box
			className={cx(
				classes.cardWrap,
				isDesktop && props.imageAlignment === 'right' ? classes.rowReverse : ''
			)}
		>
			<Box className={classes.imageSection}>
				<Box className={classes.imageWrap}>
					<ImageComponent
						src={image.url}
						alt={image.alternativeText}
						layout="fill"
						objectFit="contain"
					/>
				</Box>
			</Box>
			<Box className={classes.descriptionSection}>
				<Typography
					component="h6"
					variant={isMobile ? 'h6' : 'h4'}
					className={isDesktop ? '' : classes.textCenter}
				>
					{props.title}
				</Typography>
				<Markdown useCustomList={true}>{props.description}</Markdown>
				{ctaButton && (
					<Box className={classes.buttonWrap}>
						<CTAButton
							pageSlug={props.pageSlug}
							className={classes.ctaButton}
							fullWidth={isMobile}
							label={ctaButton.label}
							link={ctaButton.link}
							form={props.form}
							formId={props.formId}
							openLoginModal={ctaButton.openLoginModal}
							openFormModal={ctaButton.openFormModal}
							scrollToForm={ctaButton.scrollToForm}
							formPageConstants={props.formPageConstants}
							formPageConfig={props.formPageConfig}
							useOtpFlowForForm={props.useOtpFlowForForm}
							paymentDetail={props.paymentDetail}
							analyticsProps={props.analyticsProps}
							shadow={ctaButton.shadow}
						/>
					</Box>
				)}
			</Box>
		</Box>
	);
}

HighlightServiceCard.propTypes = {
	pageSlug: PropTypes.string,
	title: PropTypes.string.isRequired,
	image: PropTypes.object.isRequired,
	description: PropTypes.string.isRequired,
	ctaButton: PropTypes.object,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};

HighlightServiceCard.defaultProps = {
	analyticsProps: {},
};
