import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	contentWrap: {
		backgroundColor: 'transparent',
		textAlign: 'left',
	},
	linkWrap: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	link: {
		color: theme.palette.primary.main,
	},
}));

export default useStyles;
