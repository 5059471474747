import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const commonSectionStyles = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	return {
		container: {
			display: 'flex',
			gap: theme.spacing(2),
			margin: theme.spacing(0, 'auto'),
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column-reverse',
				margin: theme.spacing(0, 'auto'),
			},
		},
		descriptionSection: {
			...commonSectionStyles,
			width: '55%',
		},
		contentLimitedWidth: {
			maxWidth: '550px',
			margin: '0 auto',
			width: '100%',
			[theme.breakpoints.down('sm')]: {
				maxWidth: '100%',
			},
		},
		couponCodeBoxWrap: {
			marginTop: '24px',
		},
		otherSection: {
			...commonSectionStyles,
			width: '45%',
		},
		titleImageWrap: {
			position: 'relative',
			width: '100%',
			overflow: 'hidden',
			height: '500px',
			[theme.breakpoints.down('md')]: {
				height: '280px',
			},
		},
		image: {
			transition: 'opacity 1s ease-in-out',
		},
		zeroOpacity: {
			opacity: 0,
		},
	};
});

export default useStyles;
