// actions
import { getCMSData, postCMSData } from '@/api';

// utils
import get from 'lodash/get';

export const commentListAction = async ({
	source,
	sourceId,
	page = 1,
	pageSize = 20,
}) => {
	const andFilterArray = [];

	if (source) {
		andFilterArray.push({ source: { $eq: source } });

		if (sourceId) {
			andFilterArray.push({ sourceId: { $eq: sourceId } });
		}
	}

	let result = await getCMSData('comments', {
		filters: { $and: andFilterArray },
		sort: ['createdAt:desc'],
		pagination: { page, pageSize },
	});
	return result.data;
};

export const addCommentAction = async (data) => {
	let result = await postCMSData('comments', { data });

	return get(result, 'data.data', null);
};
