import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, maxWidth }) => {
	return {
		testimonialCardContainer: {
			padding: theme.spacing(2),
			maxWidth: maxWidth || '315px',
			width: width || '100%',
			display: 'inline-block',
		},
		imageWrap: {
			position: 'relative',
			width: '100%',
			height: '215px',
			backgroundColor: theme.palette.common.grey,
		},
		testimonialVideoWrap: {
			borderRadius: '19px',
			overflow: 'hidden',
			marginBottom: theme.spacing(2.5),
		},
		testimonialUserInfoWrap: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			gap: theme.spacing(1),
		},
		textCenter: {
			textAlign: 'center',
		},
	};
});

export default useStyles;
