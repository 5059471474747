import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { alignment, styled }) => ({
	contentWrap: {
		borderRadius: '20px',
		backgroundColor: 'transparent',
		textAlign: alignment || 'left',

		border: styled ? `1px solid ${theme.palette.primary.main}` : '',
		padding: styled ? theme.spacing(2, 8) : 0,

		[theme.breakpoints.down('md')]: {
			padding: styled ? theme.spacing(1, 3) : 0,
		},
		[theme.breakpoints.down('sm')]: {
			padding: styled ? theme.spacing(1, 2) : 0,
		},
	},
	titleWrap: {
		margin: theme.spacing(2, 0),
	},
}));

export default useStyles;
