import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { textValues } from './constants';

// components
import TitleSection from '@/components/shared/titleSection';
import GenericButton from '@/components/widgets/genericButton';
import ImageComponent from '@/components/widgets/image';
import TooltipTypography from '@/components/widgets/tooltipTypography';
import ImageModalSection from './partials/imageModal';

// material
import { ViewCarousel } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function UniversityDetailBannerSection({
	title,
	highlightTitle,
	subTitle,
	titleFontSize,
	titleIcon,
	titleImages,
	stats,
	button,
	analyticsProps,
}) {
	const { cx, classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const _analyticsProps = {
		...analyticsProps,
		section: 'UNIVERSITY_DETAIL_BANNER_SECTION',
	};

	const [openImageModal, setOpenImageModal] = React.useState(false);
	const toggleImageModal = () => setOpenImageModal(!openImageModal);

	const renderImageSection = () => {
		if (isMobile) return null;

		return (
			<Box className={classes.titleImageWrap}>
				<ImageComponent
					src={titleImages[0].url}
					alt={titleImages[0].alternativeText}
					priority={true}
					layout="fill"
					objectFit="fill"
				/>
				{titleImages.length < 2 ? null : (
					<Box className={classes.imageCaption} onClick={toggleImageModal}>
						<ViewCarousel />
						<Typography component="span" className={classes.subTitle}>
							{textValues.imageCaption}
						</Typography>
					</Box>
				)}
			</Box>
		);
	};

	const renderTitleAndLogo = () => (
		<Box className={classes.titleLogoWrap}>
			{titleIcon && titleIcon.url && (
				<Box className={classes.logoWrap}>
					<ImageComponent
						src={titleIcon.url}
						alt={titleIcon.alternativeText}
						className={classes.logo}
						priority={true}
						layout="fill"
						objectFit="contain"
					/>
				</Box>
			)}
			<Box>
				<Typography component="h1" className={classes.textAlignCenter}>
					<TitleSection
						highlightTitle={highlightTitle}
						title={title}
						highlightTitleAfter={false}
						fontSize={titleFontSize || titleSizes.large}
					/>
				</Typography>
			</Box>
		</Box>
	);

	const renderStats = () => {
		if (stats.length === 0) return null;

		return (
			<Box className={classes.statsWrap}>
				{stats.map((stat, index) => (
					<Box key={index} className={classes.stat}>
						<TooltipTypography
							text={stat.title.toString()}
							extraDetails={stat.extraDetails}
							className={classes.statTitle}
							maxTextLength={stat.maxTextLength || 7}
							variant="h6"
						/>

						<Typography variant="subtitle2" className={classes.subTitle}>
							{stat.subTitle}
						</Typography>
						{stat.source && (
							<Typography component="span" className={classes.source}>
								{stat.source}
							</Typography>
						)}
					</Box>
				))}
			</Box>
		);
	};

	return (
		<Box className={classes.wrapper}>
			{renderImageSection()}
			<Box className={classes.infoWrap}>
				{renderTitleAndLogo()}
				{subTitle && (
					<Box className={classes.subTitleWrap}>
						<Typography className={classes.subTitle}>{subTitle}</Typography>
					</Box>
				)}
				{renderStats()}
				{button && (
					<GenericButton
						label={button.label}
						link={button.link}
						variant={button.variant}
						fullWidth={isMobile}
						className={classes.button}
					/>
				)}
			</Box>
			{titleImages.length < 2 ? null : (
				<ImageModalSection
					images={titleImages}
					open={openImageModal}
					onClose={toggleImageModal}
					analyticsProps={_analyticsProps}
				/>
			)}
		</Box>
	);
}

UniversityDetailBannerSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	titleImages: PropTypes.array.isRequired,
	titleIcon: PropTypes.object,
	stats: PropTypes.array,
	button: PropTypes.object,
	analyticsProps: PropTypes.object,
};
UniversityDetailBannerSection.defaultProps = {
	stats: [],
	analyticsProps: {},
};
