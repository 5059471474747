import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { lastCard }) => {
	return {
		imageCardRoot: {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			[theme.breakpoints.down('md')]: {
				marginBottom: theme.spacing(8),
			},
			[theme.breakpoints.down('sm')]: {
				marginBottom: lastCard ? 0 : theme.spacing(8),
			},
		},
		imageContainer: {
			display: 'inline-flex',
			height: theme.spacing(20),
			width: theme.spacing(20),
			marginBottom: theme.spacing(3),
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: '50%',
			boxShadow:
				'10px 0px 50px rgba(245, 106, 32, 0.15), 0px 0px 50px rgba(245, 131, 32, 0.03), -10px 0px 50px rgba(245, 211, 32, 0.5)',
		},
		imageWrap: {
			position: 'relative',
			overflow: 'hidden',
			height: theme.spacing(8),
			width: theme.spacing(8),
		},
		imageCard: { position: 'relative' },
		perkTitle: {
			textAlign: 'center',
			fontWeight: 500,
			fontSize: theme.spacing(3),
		},
		perkDescription: {
			textAlign: 'center',
		},
	};
});

export default useStyles;
