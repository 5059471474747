import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(1),
		textAlign: 'center',
	},
	title: {
		...theme.typography.h4,
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.h5.fontSize,
		},
	},
	highlightV1Section: {
		display: 'flex',
		flexWrap: 'wrap',
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	cardV1Wrap: {
		margin: theme.spacing(2),
		flex: '0 0 calc(33.33% - 32px);',
		display: 'inline-block',
		[theme.breakpoints.down('lg')]: {
			flex: '0 0 calc(50% - 32px);',
		},
		[theme.breakpoints.down('md')]: {
			flex: '0 0 calc(100% - 32px);',
		},
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(2, 0),
			flex: '0 0 100%;',
		},
	},
	justifyContentCenter: {
		justifyContent: 'center',
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
}));

export default useStyles;
