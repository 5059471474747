import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import TitleSection from '@/components/shared/titleSection';
import Image from 'next/image';

// material
import { Box, Button } from '@mui/material';

//utils
import * as eventActions from '@/utils/analytics';
import useUserModal from '@/utils/hooks/use-login-modal';
import { getRedirectionLink } from './util';

// style
import useStyles from './style';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
const buttonLabel = 'Apply Now';

export default function ScholarshipDetailBannerSection({ image, ...props }) {
	const { classes } = useStyles();
	const openLoginModal = useUserModal();

	const isMobile = useSelector((state) => state.common.isMobile);
	const profileData = useSelector((state) => state.profile.data);

	const onClickLogin = () => {
		const redirectionLink = getRedirectionLink(profileData, props.universityId);
		eventActions.trackEvent('BUTTON_CLICKED', {
			...props.analyticsProps,
			eventLabel: buttonLabel,
			eventAction: 'CLICKED',
			eventCategory: 'NAVIGATION',
			section: 'DETAIL_BANNER_SECTION',
			landingPageUrl: redirectionLink,
		});
		openLoginModal('login', '', { redirectionLink });
	};

	return (
		<Box className={classes.scholarshipBannerSection}>
			<Box className={classes.descriptionWrap}>
				{get(image, 'url') ? (
					<Box className={classes.logoWrap}>
						<Image
							src={image.url}
							alt={image.alternativeText}
							className={classes.logo}
							priority={true}
							layout="fill"
							objectFit="contain"
						/>
					</Box>
				) : null}
				<TitleSection
					heading="h1"
					textAlign="left"
					title={props.title}
					fontSize={props.titleFontSize || titleSizes.small}
				/>
			</Box>
			<Button
				className={classes.submitButton}
				onClick={onClickLogin}
				fullWidth={isMobile}
			>
				{buttonLabel}
			</Button>
		</Box>
	);
}

ScholarshipDetailBannerSection.propTypes = {
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	image: PropTypes.object,
	universityId: PropTypes.string,
	analyticsProps: PropTypes.object,
};

ScholarshipDetailBannerSection.defaultProps = {
	analyticsProps: {},
};
