import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import CounterCardV1 from '@/components/shared/counterSection/partials/counterCardV1';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function CounterSectionV1({ cardList }) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<Box className={classes.counterV1Section}>
			{cardList.map((item, index) => (
				<Box key={index} className={classes.cardV1Wrap}>
					<CounterCardV1 {...item} width={isMobile ? '250px' : 'auto'} />
				</Box>
			))}
		</Box>
	);
}

CounterSectionV1.propTypes = {
	cardList: PropTypes.array,
};
CounterSectionV1.defaultProps = {
	cardList: [],
};
