import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	dialog: {
		background: 'rgba(0,0,0,0.76)',
	},
	dialogPaper: {
		background: 'none',
		boxShadow: 'none',
		overflowY: 'unset',
		[theme.breakpoints.down('md')]: {
			maxWidth: '768px',
		},
	},
	title: {
		textAlign: 'center',
		color: theme.palette.common.white,
		marginBottom: theme.spacing(3),
	},
	closeIconWrap: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		position: 'absolute',
		cursor: 'pointer',
		right: 0,
		top: 0,
		color: theme.palette.common.white,
		margin: theme.spacing(1),
	},
	dot: {
		'& button::before': {
			fontSize: '15px!important',
			opacity: '1!important',
			color: `${theme.palette.text.lightGrey}!important`,
		},
	},
	activeDot: {
		'& button::before': {
			color: `${theme.palette.primary.main}!important`,
		},
	},
	imageWrap: {
		position: 'relative',
		width: '816px',
		height: '550px',
		borderRadius: '15px',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			width: '632px',
			height: '426px',
		},
	},
}));

export default useStyles;
