import PropTypes from 'prop-types';

// material
import { Box, Typography } from '@mui/material';

//style
import useStyles from './style';

export default function Comment(props) {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.metaWrap}>
				<Box className={classes.avatarWrap}>{props.userName[0]}</Box>
				<Typography>
					<span className={classes.userName}>{props.userName}</span>
					<span className={classes.createdDate}>{props.createdDate}</span>
				</Typography>
			</Box>
			<Box className={classes.messageWrap}>
				<Typography>{props.message}</Typography>
			</Box>
		</Box>
	);
}

Comment.propTypes = {
	userName: PropTypes.string.isRequired,
	email: PropTypes.string.isRequired,
	mobile: PropTypes.string,
	message: PropTypes.string.isRequired,
	createdDate: PropTypes.string.isRequired,
};
