import { Box } from '@mui/material';
import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// Components
import TitleSection from '@/components/shared/titleSection';
import Markdown from '@/components/widgets/markdown';

// material
import { ExpandMore } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
} from '@mui/material';

// styles
import useStyles from './style';

export default function FAQSection(props) {
	const { classes } = useStyles();

	const [expandedIndex, setExpandedIndex] = React.useState(false);

	const handleChange = (panelIndex) => (_, isExpanded) => {
		setExpandedIndex(isExpanded ? panelIndex : false);
	};

	const renderAccordianTitle = (number, title) => {
		const text = props.numbered ? `${number}. ${title}` : title;
		return <Typography className={classes.accordianTitle}>{text}</Typography>;
	};

	return (
		<Box>
			<Box className={classes.titleSectionWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={true}
					fontSize={props.titleFontSize || titleSizes.medium}
					subTitle={props.subTitle}
				/>
			</Box>

			<Box className={classes.accordianWrap}>
				{props.cardList.map((item, index) => (
					<Accordion
						key={index}
						expanded={expandedIndex === index}
						onChange={handleChange(index)}
						disableGutters
						elevation={0}
						classes={{ root: classes.accordian }}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMore className={classes.icon} fontSize="large" />
							}
						>
							<Typography className={classes.accordianTitle}>
								{renderAccordianTitle(index + 1, item.title)}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<Markdown className={classes.content}>{item.body}</Markdown>
						</AccordionDetails>
					</Accordion>
				))}
			</Box>
		</Box>
	);
}

FAQSection.propTypes = {
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	numbered: PropTypes.bool,
};

FAQSection.defaultProps = {
	numbered: false,
};
