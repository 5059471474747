import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// component
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';

//material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

let intervalId;
export default function PlanDetailSection({
	highlightTitle,
	title,
	subTitle,
	titleFontSize,
	cardList,
	interval,
}) {
	const { classes, cx } = useStyles();

	const isMobile = useSelector((state) => state.common.isMobile);
	const [activeItemIndex, setActiveItemIndex] = React.useState(() => {
		const selectedItemIndex = cardList.findIndex((item) => item.highlightTitle);
		return selectedItemIndex === -1 ? 0 : selectedItemIndex;
	});
	const [infoCardLoaded, setInfoCardLoaded] = React.useState(false);

	const toggleActiveItem = () => {
		if (!infoCardLoaded || !interval) return;

		intervalId && clearInterval(intervalId);
		intervalId = setInterval(() => {
			setActiveItemIndex((currentActiveIndex) => {
				return currentActiveIndex + 1 >= cardList.length
					? 0
					: currentActiveIndex + 1;
			});
		}, 5000);
	};

	const handleItemClick = (index) => {
		setActiveItemIndex(index);
		toggleActiveItem();
	};

	React.useEffect(() => {
		toggleActiveItem();
		return () => intervalId && clearInterval(intervalId);
	}, [infoCardLoaded]);

	return (
		<Box>
			<Box className={classes.titleSectionWrap}>
				<TitleSection
					title={highlightTitle ? null : title}
					highlightTitle={highlightTitle ? title : null}
					fontSize={titleFontSize || titleSizes.medium}
					subTitle={subTitle}
				/>
			</Box>
			<Box className={classes.mainSectionWrap}>
				{!isMobile && (
					<Box className={classes.infoCardList}>
						{cardList.map((item, index) => (
							<Box
								key={index}
								className={cx(
									classes.infoCard,
									activeItemIndex === index ? '' : classes.zeroOpacity
								)}
							>
								<Box className={classes.infoCardImageWrap}>
									<ImageComponent
										placeholder="blur"
										src={get(item, 'coverImage.data.attributes.url', '')}
										alt={
											get(item, 'coverImage.data.attributes.alternativeText') ||
											item.cardTitle
										}
										objectFit="cover"
										layout="fill"
									/>
								</Box>
								<Box className={classes.infoCardBody}>
									<Typography component="h6" variant="h4">
										{item.cardTitle}
									</Typography>
									<Typography className={classes.cardDescription}>
										{item.cardDescription}
									</Typography>
								</Box>
							</Box>
						))}
					</Box>
				)}

				<Box className={classes.listWrap}>
					{cardList.map((item, index) => (
						<Box key={index} className={classes.itemWrap}>
							<ImageComponent
								src={get(item, 'iconImage.data.attributes.url', '')}
								alt={
									get(item, 'iconImage.data.attributes.alternativeText') ||
									item.title
								}
								objectFit="contain"
								height="37px"
								width="42px"
								priority={true}
								onLoadingComplete={() => setInfoCardLoaded(true)}
							/>
							<Typography
								className={classes.itemTitle}
								color={activeItemIndex === index ? 'primary' : 'default'}
								onClick={() => handleItemClick(index)}
							>
								{item.title}
							</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}

PlanDetailSection.propTypes = {
	highlightTitle: PropTypes.bool,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	interval: PropTypes.number,
};
PlanDetailSection.defaultProps = {
	highlightTitle: false,
	cardList: [],
};
