import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	formWrap: {
		border: '1px solid #A8A8A8',
		borderRadius: '15px',
		padding: theme.spacing(3),
		backgroundColor: theme.palette.common.white,
	},
	form: {
		width: '100%',
		margin: '0 auto',
	},
	errorMessageWrap: {
		textAlign: 'center',
		margin: theme.spacing(1, 'auto'),
	},
	submitButtonWrap: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: theme.spacing(6),
		margin: theme.spacing(1.5, 0),
	},
	progress: {
		width: '100%',
	},
	submitButton: {
		borderRadius: '24px',
		padding: theme.spacing(1.5, 3),
	},
}));

export default useStyles;
