import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import IconAction from '@/components/shared/iconAction';
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';
import LoginBox from '@/components/widgets/loginBox';
import Markdown from '@/components/widgets/markdown';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function HomePageBannerSection({ titleImages, ...props }) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const [activeImageIndex, setActiveImageIndex] = React.useState(
		titleImages.length > 0 ? 0 : null
	);

	React.useEffect(() => {
		let intervalID;
		if (titleImages.length > 0) {
			intervalID = setInterval(() => {
				setActiveImageIndex((currentActiveIndex) => {
					return currentActiveIndex + 1 >= titleImages.length
						? 0
						: currentActiveIndex + 1;
				});
			}, 5000);
		}

		return () => intervalID && clearInterval(intervalID);
	}, []);

	const LoginFormElement = (
		<Box className={classes.loginFormWrap}>
			<LoginBox />
		</Box>
	);

	return (
		<Box className={classes.container}>
			<Box className={classes.descriptionSection}>
				<Box>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						highlightTitleAfter={true}
						heading={'h1'}
						fontSize={props.titleFontSize || titleSizes.large}
					/>
				</Box>
				<Box className={classes.contentLimitedWidth}>
					<Markdown className={classes.content} useCustomList={true}>
						{isMobile ? props.mobileDescription : props.description}
					</Markdown>
				</Box>
				<Box className={classes.appLinkContainer}>
					<Box className={classes.appLinkWrap}>
						{props.androidAppIcon && (
							<Box>
								<IconAction
									iconWidth={isMobile ? '115px' : '150px'}
									iconHeight={isMobile ? '48px' : '60px'}
									{...props.androidAppIcon}
								/>
							</Box>
						)}
						{props.iosAppIcon && (
							<Box>
								<IconAction
									iconWidth={isMobile ? '115px' : '150px'}
									iconHeight={isMobile ? '48px' : '60px'}
									{...props.iosAppIcon}
								/>
							</Box>
						)}
					</Box>
				</Box>
				{!isMobile && LoginFormElement}
			</Box>
			<Box className={classes.otherSection}>
				<Box className={classes.titleImageWrap}>
					{titleImages.map((image, index) => (
						<ImageComponent
							key={index}
							src={image.url}
							alt={image.alternativeText}
							className={`${classes.image} ${
								index !== activeImageIndex && classes.zeroOpacity
							}`}
							priority={true}
							layout="fill"
							objectFit="contain"
							objectPosition="top"
						/>
					))}
				</Box>
				{isMobile && LoginFormElement}
			</Box>
		</Box>
	);
}

HomePageBannerSection.propTypes = {
	titleImages: PropTypes.array,
	titleFontSize: PropTypes.string,
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	mobileDescription: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};
HomePageBannerSection.defaultProps = {
	titleImages: [],
	analyticsProps: {},
};
