import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box, Typography } from '@mui/material';

// components
import TitleSection from '@/components/shared/titleSection';
import HighlightServiceCard from './partials/highlightServiceCard';

// style
import useStyles from './style';

export default function HighlightServiceSection(props) {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={true}
					fontSize={props.titleFontSize || titleSizes.medium}
					subTitle={
						props.subTitle && (
							<Typography component="p" className={classes.subTitle}>
								{props.subTitle}
							</Typography>
						)
					}
				/>
			</Box>
			<Box className={classes.cardListWrap}>
				{props.cardList.map((item, index) => (
					<HighlightServiceCard
						key={index}
						imageAlignment={index % 2 !== 0 ? 'right' : 'left'}
						title={item.title}
						description={item.description}
						image={item.image}
						ctaButton={item.ctaButton}
						form={props.form}
						formId={props.formId}
						formPageConstants={props.formPageConstants}
						formPageConfig={props.formPageConfig}
						useOtpFlowForForm={props.useOtpFlowForForm}
						paymentDetail={props.paymentDetail}
						globalFormConfig={props.globalFormConfig}
						searchQueryValues={props.searchQueryValues}
						analyticsProps={props.analyticsProps}
					/>
				))}
			</Box>
		</Box>
	);
}

HighlightServiceSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	formId: PropTypes.string,
	form: PropTypes.object,
	analyticsProps: PropTypes.object,
};

HighlightServiceSection.defaultProps = {
	cardList: [],
	analyticsProps: {},
};
