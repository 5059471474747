// utils
import * as eventActions from '@/utils/analytics';
import useUserModal from '@/utils/hooks/use-login-modal';
// material
import {
	Box,
	Button,
	InputAdornment,
	TextField,
	Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// constants
import { textValues } from './constants';
// style
import useStyles, { textFieldStyleWrapper } from './style';

const StyledTextField = textFieldStyleWrapper(TextField);

export default function LoginBox(props) {
	const verticalLayout = props.layout === 'vertical';
	const { classes } = useStyles({ ...props, verticalLayout });
	const isMobile = useSelector((state) => state.common.isMobile);
	const userData = useSelector((state) => state.user.data);
	const location = useSelector((state) => state.router.location);

	const openLoginModal = useUserModal();

	const textFieldRef = React.useRef(null);
	const [errorText, setErrorText] = React.useState('');
	const [value, setValue] = React.useState('');

	const handleChange = (e) => {
		const { value } = e.target;
		if (value.length < 11) setValue(value);
	};

	const handleSubmit = (e) => {
		let errorMsg = '';
		if (value.length === 0) {
			errorMsg = textValues.requiredText;
		} else if (value.length > 10 || value.length < 10 || !/^\d+$/.test(value)) {
			errorMsg = textValues.invalidText;
		}

		setErrorText(errorMsg);
		if (errorMsg) {
			e.preventDefault();
			return;
		}

		eventActions.trackEvent('BUTTON_CLICKED', {
			...props.analyticsProps,
			eventCategory: 'USER_EVENT',
			userAction: 'CLICKED',
			eventLabel: 'LOGIN',
		});
		openLoginModal('login', value, { sendOTPToNumber: true });
		setValue('');
		e.preventDefault();
		e.stopPropagation();
	};

	const handleWheel = (e) => e.preventDefault();

	React.useEffect(() => {
		const element = textFieldRef.current;
		if (!element) return;

		element.addEventListener('wheel', handleWheel, {
			passive: false,
		});

		return () => {
			element.removeEventListener('wheel', handleWheel, {
				passive: false,
			});
		};
	}, [textFieldRef.current]);

	React.useEffect(() => {
		setValue((value || userData.mobile || '').slice(-10));
	}, [userData]);

	return (
		<Box className={classes.loginBoxWrap}>
			{props.title && (
				<Box className={classes.sectionOne}>
					<Typography variant="h5">{props.title}</Typography>
					{props.subTitle && (
						<Typography variant="body1">{props.subTitle}</Typography>
					)}
				</Box>
			)}
			<form
				className={classes.sectionTwo}
				onSubmit={handleSubmit}
				autoComplete={location.pathname.startsWith('/kiosk/') ? 'off' : 'on'}
			>
				<Box className={classes.inputFieldContainer}>
					<StyledTextField
						type="number"
						variant="outlined"
						margin="dense"
						fullWidth
						value={value}
						error={!!errorText}
						onChange={handleChange}
						helperText={errorText || null}
						placeholder={textValues.placeholder}
						ref={textFieldRef}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Box className={classes.countryCodeAdornment}>
										{textValues.countryCodeText}
									</Box>
								</InputAdornment>
							),
							inputProps: { min: 0 },
						}}
						disabled={userData.registered}
					/>
				</Box>
				<Box className={classes.buttonWrap}>
					<Button
						variant="outlined"
						fullWidth={verticalLayout || isMobile}
						className={classes.submitButton}
						onClick={handleSubmit}
					>
						{props.buttonText || textValues.buttonText}
					</Button>
				</Box>
			</form>
		</Box>
	);
}

LoginBox.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	width: PropTypes.string,
	titlePosition: PropTypes.string,
	buttonText: PropTypes.string,
	analyticsProps: PropTypes.object,
};
