import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	container: {
		width: width || '100%',
		height: height || '100%',
		display: 'inline-block',
		overflow: 'hidden',
		borderRadius: '10px',
		padding: theme.spacing(2),
		backgroundColor: theme.palette.common.lightBlue,
	},
	titleWrap: {
		marginBottom: theme.spacing(1),
	},
	title: {
		fontWeight: theme.typography.fontWeightSemiBold,
	},
	extraDetailsText: {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightSemiBold,
		fontStyle: 'italic',
	},
}));

export default useStyles;
