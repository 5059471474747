import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'stretch',
			padding: '0 100px',
			[theme.breakpoints.down('sm')]: {
				padding: '0 30px',
				flexDirection: 'column',
				justifyContent: 'start',
				alignItems: 'center',
			},
		},
		listSection: {
			display: 'flex',
			flexDirection: 'column',
			width: '70%',
			minHeight: '100%',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
			},
		},
		titleWrapper: {
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
			},
		},
		listWrapper: {
			height: '100%',
			width: '60%',
		},
		listItem: {
			minHeight: '100px',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			borderBottom: '1px solid #C4C4C4',
		},
		selectedItem: {
			borderBottom: '1px solid #F16621',
			color: '#F16621',
			fontWeight: theme.typography.fontWeightBold,
		},
		listItemTitle: {
			color: '#F16621',
		},
		listItemSubtitle: {
			fontWeight: theme.typography.fontWeightMedium,
		},
		imageSection: {
			width: '30%',
			marginTop: theme.spacing(2),
			position: 'relative',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				minHeight: '450px',
			},
		},
		mobileStepsWrapper: {},
		stepperWrapper: {
			marginTop: '14px',
			display: 'flex',
			justifyContent: 'center',
		},
		stepper: {
			width: '15px',
			height: '15px',
			background: '#D9D9D9',
			borderRadius: '50%',
			marginRight: '8px',
			transition: 'background 0.5s ease-in-out',
		},
		selectedStepper: {
			background: '#F16621',
		},
		stepWrapper: {
			position: 'relative',
			width: '300px',
			minHeight: '100px',
		},
		step: {
			position: 'absolute',
			left: '0',
			right: '0',
			padding: '20px 0',
			textAlign: 'center',
			'-webkit-transition': 'opacity 1s ease-in-out',
			'-moz-transition': 'opacity 1s ease-in-out',
			'-o-transition': 'opacity 1s ease-in-out',
			transition: 'opacity 0.5s ease-in-out',
		},
		stepTitle: {
			color: '#F16621',
			fontWeight: theme.typography.fontWeightMedium,
		},
		stepSubtitle: {
			fontSize: '18px',
			fontWeight: theme.typography.fontWeightMedium,
		},
		zeroOpacity: {
			opacity: 0,
		},
	};
});

export default useStyles;
