import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	accordian: {
		borderTop: `1px solid ${theme.palette.divider}`,
		padding: theme.spacing(1),
		background: 'transparent',
		'&:first-child': {
			borderTop: 0,
		},
	},
	accordianTitle: {
		fontWeight: theme.typography.fontWeightMedium,
	},
	content: {
		color: theme.palette.common.textLightGrey,
		fontWeight: theme.typography.fontWeightMedium,
	},
	icon: {
		color: `${theme.palette.primary.main}`,
	},
}));

export default useStyles;
