import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	text_center: {
		textAlign: 'center',
	},
	text_left: {
		textAlign: 'left',
	},
	text_right: {
		textAlign: 'right',
	},
}));

export default useStyles;
