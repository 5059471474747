import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';
import controlSchema from './schema';
import { desktopUiSchema, mobileUiSchema, tabletUiSchema } from './uiSchema';

// components
import NoSSRJsonForm from '@/components/widgets/form/NoSSRJsonForm';

// material
import { Box, Button, LinearProgress, Typography } from '@mui/material';

//actions
import { addCommentAction } from '@/store/actions/comment';

// utils
import * as eventActions from '@/utils/analytics';

// styles
import useStyles from './style';

let formErrors = null;
export default function FormSection(props) {
	const { classes } = useStyles();
	const { isMobile, isTablet } = useSelector((state) => state.common);

	const userData = useSelector((state) => state.user.data);
	const location = useSelector((state) => state.router.location);

	const [loading, setLoading] = React.useState(false);
	const [formData, setFormData] = React.useState({});
	const [showFormErrors, setShowFormErrors] = React.useState(false);
	const [errorMessage, setErrorMessage] = React.useState(null);

	const trackEvent = (eventName, eventProperties) => {
		eventActions.trackEvent(eventName, {
			...props.analyticsProps,
			...eventProperties,
			section: 'COMMENTS_SECTION',
			eventCategory: 'USER_EVENT',
		});
	};

	const handleChange = ({ data, errors }) => {
		setFormData(data);
		formErrors = errors;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setErrorMessage(null);
		setShowFormErrors(false);

		if (formErrors && formErrors.length > 0) {
			return setShowFormErrors(true);
		}

		trackEvent('COMMENT_SUBMIT_ATTEMPTED', {
			eventAction: 'CLICKED',
			eventLabel: 'Add comment',
		});
		setLoading(true);
		addCommentAction({ ...formData, ...props.constants })
			.then((comment) => {
				trackEvent('COMMENT_SUBMITION_SUCCESS', {
					eventAction: 'SHOWN',
					eventLabel: 'Comment added',
				});
				props.handleSuccessCallback(comment);
				setFormData({});
			})
			.catch(() => {
				trackEvent('COMMENT_SUBMITION_FAILED', {
					eventAction: 'SHOWN',
					eventLabel: 'Failed to add comment',
				});
				setErrorMessage(textValues.errorMessage);
				setShowFormErrors(true);
			})
			.finally(() => setLoading(false));
	};

	const FormElement = React.useMemo(() => {
		const uiSchema = isMobile
			? mobileUiSchema
			: isTablet
			? tabletUiSchema
			: desktopUiSchema;

		return (
			<>
				{errorMessage && (
					<Box className={classes.errorMessageWrap}>
						<Typography color="error">{errorMessage}</Typography>
					</Box>
				)}
				<form
					onSubmit={handleSubmit}
					noValidate
					autoComplete={location.pathname.startsWith('/kiosk/') ? 'off' : 'on'}
				>
					<Box className={classes.form}>
						<NoSSRJsonForm
							uiSchema={uiSchema}
							schema={controlSchema}
							data={formData}
							onChange={handleChange}
							showFormErrors={showFormErrors}
						/>
					</Box>
					<Box className={classes.submitButtonWrap}>
						{loading ? (
							<LinearProgress className={classes.progress} />
						) : (
							<Button
								color="success"
								variant="contained"
								type="submit"
								className={classes.submitButton}
								fullWidth={isMobile}
							>
								<Typography fontWeight="fontWeightSemiBold">
									{textValues.submitButtonText}
								</Typography>
							</Button>
						)}
					</Box>
				</form>
			</>
		);
	}, [formData, showFormErrors, loading, errorMessage]);

	React.useEffect(() => {
		if (!userData.registered) return;

		setFormData({
			...formData,
			userName: userData.first_name
				? userData.last_name
					? `${userData.first_name} ${userData.last_name}`
					: userData.first_name
				: '',
			email: userData.email || '',
			mobile: (userData.mobile || '').slice(-10),
		});
	}, [userData]);

	return <Box className={classes.formWrap}>{FormElement}</Box>;
}

FormSection.propTypes = {
	constants: PropTypes.object,
	handleSuccessCallback: PropTypes.func.isRequired,
	analyticsProps: PropTypes.object,
};

FormSection.defaultProps = {
	constants: {},
	analyticsProps: {},
};
