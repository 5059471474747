import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { perItemWidth, itemsGap }) => ({
	sectionOne: {
		marginBottom: theme.spacing(6),
	},
	sectionTwo: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: itemsGap,
		[theme.breakpoints.down('md')]: {
			justifyContent: 'center',
		},
	},
	itemWrap: {
		flexGrow: 0,
		flexShrink: 1,
		flexBasis: `calc(${perItemWidth} - 24px)`,
	},
	labelWrap: {
		marginTop: '10px',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
}));

export default useStyles;
