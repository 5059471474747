import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box } from '@mui/material';

// components
import ImageCard from '@/components/shared/imageCard';
import TitleSection from '@/components/shared/titleSection';

// styles
import useStyles from './style';

export default function PlanOfferingSection(props) {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.titleSectionWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					subTitle={props.subTitle}
					highlightTitleAfter={false}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>
			<Box className={classes.cardListWrap}>
				{props.cardList.map((item, index) => (
					<Box key={index} className={classes.cardWrap}>
						<ImageCard
							image={item.image}
							label={item.label}
							link={item.link}
							imageWidth="97px"
							imageShape="logo"
							labelStyled
						/>
					</Box>
				))}
			</Box>
		</Box>
	);
}

PlanOfferingSection.propTypes = {
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	titleFontSize: PropTypes.string,
};
PlanOfferingSection.defaultProps = {
	cardList: [],
};
