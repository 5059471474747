import PropTypes from 'prop-types';

// material
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function NavigationSection({ cardList }) {
	const { classes, cx } = useStyles();

	const [activeSectionId, setActiveSectionId] = React.useState(null);
	const [showArrows, setShowArrows] = React.useState(false);
	const navigationListRef = React.useRef(null);

	const handleScroll = (sectionId) => {
		setActiveSectionId(sectionId);
	};

	const handleScrollButtonClick = (scroll) => {
		if (scroll == 'left') navigationListRef.current.scrollLeft += 150;
		else navigationListRef.current.scrollLeft -= 150;
	};

	React.useEffect(() => {
		const hash = location.hash && location.hash.slice(1);
		const sectionFromUrl = cardList.find(({ id }) => id === hash);

		setTimeout(() => {
			sectionFromUrl && handleScroll(sectionFromUrl.id);
		}, 500);
	}, []);

	React.useEffect(() => {
		if (!navigationListRef.current) return;

		if (
			navigationListRef.current.scrollWidth >
			navigationListRef.current.parentElement.scrollWidth
		) {
			setShowArrows(true);
		}
	}, [navigationListRef.current]);

	return (
		<Box className={classes.sectionWrapper}>
			<ChevronLeft
				className={cx(classes.arrowLeft, showArrows ? '' : classes.hidden)}
				onClick={() => handleScrollButtonClick('right')}
			/>
			<Box className={classes.sectionList} ref={navigationListRef}>
				{cardList.map((section, index) => (
					<Box key={index} className={classes.sectionWrap}>
						<a
							href={`#${section.id}`}
							onClick={() => {
								handleScroll(section.id);
							}}
						>
							<Typography
								className={cx(
									classes.section,
									activeSectionId === section.id ? classes.activeSection : ''
								)}
							>
								{section.label}
							</Typography>
						</a>
					</Box>
				))}
			</Box>
			<ChevronRight
				className={cx(classes.arrowRight, showArrows ? '' : classes.hidden)}
				onClick={() => handleScrollButtonClick('left')}
			/>
		</Box>
	);
}

NavigationSection.propTypes = {
	cardList: PropTypes.array,
};

NavigationSection.defaultProps = {
	cardList: [],
};
