import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { highlight }) => ({
	planCard: {
		background: theme.palette.common.white,
		border: `1px solid ${theme.palette.common.grey}`,
		borderRadius: '8.68098px',
		padding: '26px 24px 49px',

		display: 'flex',
		flexDirection: 'column',
		minHeight: '207.43px',
		height: '100%',
		overflow: 'hidden',
	},
	highlightPlanCard: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.common.white,
	},
	titleSection: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	title: {
		fontSize: '24px',
		fontWeight: theme.typography.fontWeightSemiBold,
	},
	subTitle: {
		fontWeight: theme.typography.fontWeightMedium,
		color: highlight ? theme.palette.common.white : '#6D6D6D',
	},
	arrowIcon: {
		color: highlight ? theme.palette.common.white : theme.palette.primary.main,
		cursor: 'pointer',
	},
	planCardBottomWrap: {
		maxWidth: '250px',
		margin: '23.43px auto 0',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		height: '100%',
	},
}));

export default useStyles;
