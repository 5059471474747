import get from 'lodash/get';
import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// comps
import LeftAlignedSection from './partials/LeftAlignedSection';
import RightAlignedSection from './partials/RightAlignedSection';

export default function WalkthroughSection(props) {
	const imageUrl = get(props, 'titleImage.url', null);
	const imageAltText = get(props, 'titleImage.alternativeText', null);
	if (props.alignImage === 'leftAlign')
		return (
			<LeftAlignedSection
				imageUrl={imageUrl}
				imageAltText={imageAltText}
				title={props.title}
				highlightTitle={props.highlightTitle}
				description={props.description}
				titleFontSize={props.titleFontSize || titleSizes.medium}
			/>
		);
	return (
		<RightAlignedSection
			imageUrl={imageUrl}
			imageAltText={imageAltText}
			title={props.title}
			highlightTitle={props.highlightTitle}
			description={props.description}
			titleFontSize={props.titleFontSize || titleSizes.medium}
		/>
	);
}

WalkthroughSection.propTypes = {
	titleImage: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	highlightTitle: PropTypes.string,
	description: PropTypes.string,
	alignImage: PropTypes.oneOf(['rightAlign', 'leftAlign']).isRequired,
	analyticsProps: PropTypes.object,
};
WalkthroughSection.defaultProps = {
	analyticsProps: {},
};
