export const getSectionId = (componentType, componentId, labelOnNavigation) => {
	if (labelOnNavigation) {
		return labelOnNavigation.toLowerCase().replace(/ +/g, '-');
	}

	let _componentType = componentType.split('.');
	_componentType = _componentType[1] || _componentType[0];
	return `${_componentType}-${componentId}`;
};

export const getNavigationSectionCardList = (components) => {
	return components.reduce((data, component) => {
		if (component.labelOnNavigation) {
			data.push({
				label: component.labelOnNavigation,
				id: getSectionId(
					component.__component,
					component.id,
					component.labelOnNavigation
				),
			});
		}
		return data;
	}, []);
};
