import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import CategoryRow from './partials/CategoryRow';
import PlanGroup from './partials/planGroup';

// material
import { Box } from '@mui/material';

// utils
import { shapeCategories } from './util';

// Styles
import useStyles from './style';

export default function PlanComparisonSection(props) {
	const { classes } = useStyles();
	const location = useSelector((state) => state.router.location);

	const categories = React.useMemo(
		() => shapeCategories(props.cardList),
		[props.cardList]
	);

	const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState(
		() => {
			const _selectedCategoryIndex = categories.findIndex(
				(module) => module.highlight
			);
			return _selectedCategoryIndex === -1 ? 0 : _selectedCategoryIndex;
		}
	);

	const handleSectionClick = (index) => {
		setSelectedCategoryIndex(index);
	};

	React.useEffect(() => {
		const hash = location.hash && location.hash.slice(1);
		const moduleIndex = categories.findIndex((section) => section.id === hash);
		setSelectedCategoryIndex(
			moduleIndex === -1 ? selectedCategoryIndex : moduleIndex
		);
	}, [location.hash]);

	return (
		<Box>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>

			<Box className={classes.categoryRowWrap}>
				<CategoryRow
					handleCategoryClick={handleSectionClick}
					selectedCategoryIndex={selectedCategoryIndex}
					categories={categories}
				/>
			</Box>

			<PlanGroup
				plans={categories[selectedCategoryIndex].offeredPlans}
				analyticsProps={props.analyticsProps}
			/>
		</Box>
	);
}

PlanComparisonSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
};
