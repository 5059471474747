import PropTypes from 'prop-types';

// material
import { Box, Typography } from '@mui/material';

// components
import VideoPlayer from '@/components/widgets/videoPlayer';

// Styles
import useStyles from './style';

export default function VideoCard(props) {
	const { classes } = useStyles(props);

	return (
		<Box className={classes.videoCardWrap}>
			<Box className={classes.videoWrap}>
				<VideoPlayer
					url={props.url}
					height={props.playerHeight}
					thumbnailURL={props.thumbnailURL}
					analyticsProps={props.analyticsProps}
				/>
			</Box>
			{props.label && (
				<Box className={classes.labelWrap}>
					<Typography component="h6" className={classes.label}>
						{props.label}
					</Typography>
				</Box>
			)}
		</Box>
	);
}

VideoCard.propTypes = {
	label: PropTypes.string,
	url: PropTypes.string.isRequired,
	thumbnailURL: PropTypes.string,
	playerHeight: PropTypes.string,
	analyticsProps: PropTypes.object,
};

VideoCard.defaultProps = {
	playerHeight: '250px',
	analyticsProps: {},
};
