// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';
// material
import { Box, Typography } from '@mui/material';
// default
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// styles
import useStyles from './style';

let intervalID = null;
export default function HighlightStepsSection({
	title,
	highlightTitle,
	subTitle,
	cardList,
	titleImage,
	titleFontSize,
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const [currentStepIndex, setCurrentStepIndex] = React.useState(0);

	const highlightStepsInLoop = () => {
		if (intervalID) {
			clearTimeout(intervalID);
		}
		intervalID = setTimeout(() => {
			setCurrentStepIndex((currentStepIndex) => {
				return currentStepIndex + 1 >= cardList.length
					? 0
					: currentStepIndex + 1;
			});
		}, cardList[currentStepIndex].interval * 1000);
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.listSection}>
				<Box className={classes.titleWrapper}>
					<TitleSection
						highlightTitle={highlightTitle}
						title={title}
						highlightTitleAfter={true}
						fontSize={titleFontSize || titleSizes.medium}
						subTitle={subTitle}
						textAlign={'left'}
					/>
				</Box>

				{!isMobile && (
					<Box className={classes.listWrapper}>
						{cardList.map((item, index) => (
							<Box
								key={index}
								className={`${classes.listItem} ${
									index === currentStepIndex && classes.selectedItem
								}`}
							>
								<Box className={classes.listItemTitle}>{item.title}</Box>
								<Box className={classes.listItemSubtitle}>{item.subtitle}</Box>
							</Box>
						))}
					</Box>
				)}
			</Box>
			<Box className={classes.imageSection}>
				<ImageComponent
					placeholder="blur"
					blurDataURL={
						'https://staging-azent-website-cms-uploads.s3.amazonaws.com/placeholder_Image_8f878f09b1.svg'
					}
					src={titleImage.url}
					alt={titleImage.alternativeText}
					layout={'fill'}
					objectFit={'contain'}
					onLoadingComplete={highlightStepsInLoop}
				/>
			</Box>
			{isMobile && (
				<Box className={classes.mobileStepsWrapper}>
					<Box className={classes.stepperWrapper}>
						{cardList.map((item, index) => (
							<Box
								key={index}
								className={`${classes.stepper} ${
									index === currentStepIndex && classes.selectedStepper
								}`}
							></Box>
						))}
					</Box>
					<Box className={classes.stepWrapper}>
						{cardList.map((item, index) => (
							<Box
								className={`${classes.step} ${
									index !== currentStepIndex && classes.zeroOpacity
								}`}
								key={index}
							>
								<Typography className={classes.stepTitle} variant="subtitle2">
									{item.title}
								</Typography>
								<Typography className={classes.stepSubtitle} variant="h6">
									{item.subtitle}
								</Typography>
							</Box>
						))}
					</Box>
				</Box>
			)}
		</Box>
	);
}

HighlightStepsSection.propTypes = {
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	titleImage: PropTypes.object.isRequired,
	analyticsProps: PropTypes.object,
};
HighlightStepsSection.defaultProps = {
	cardList: [],
	analyticsProps: {},
};
