import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import CounterCardV2 from '@/components/shared/counterSection/partials/counterCardV2';
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';

// material
import { PlayArrow } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function CounterSectionV2({
	title,
	titleFontSize,
	description,
	image,
	activeCardIndex,
	setActiveCardIndex,
	cardList,
}) {
	const { classes } = useStyles();
	const { isDesktop } = useSelector((state) => state.common);

	const TitleAndDescriptionSection = (
		<Box className={classes.counterSectionV2SectionOne}>
			<Box className={classes.imageWrap}>
				{image && image.url ? (
					<ImageComponent
						src={image.url}
						alt={image.alternativeText}
						layout="fill"
						objectFit="cover"
					/>
				) : (
					<PlayArrow className={classes.logo} />
				)}
			</Box>
			<TitleSection
				title={title}
				fontSize={titleFontSize || titleSizes.medium}
				textAlign={'left'}
			/>
			<Typography
				variant={isDesktop ? 'h6' : 'body1'}
				component="p"
				className={classes.fontWeightLight}
			>
				{description}
			</Typography>
		</Box>
	);

	return (
		<Box className={classes.counterSectionV2Wrap}>
			{isDesktop && TitleAndDescriptionSection}
			<Box className={classes.counterSectionV2SectionTwo}>
				{cardList.map((item, index) => (
					<Box
						key={index}
						onClick={() => setActiveCardIndex(index)}
						className={classes.cardV2Wrap}
					>
						<CounterCardV2 {...item} isActive={activeCardIndex === index} />
					</Box>
				))}
			</Box>
			{!isDesktop && TitleAndDescriptionSection}
		</Box>
	);
}

CounterSectionV2.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	image: PropTypes.object,
	titleFontSize: PropTypes.string,
	activeCardIndex: PropTypes.number,
	setActiveCardIndex: PropTypes.func.isRequired,
	cardList: PropTypes.array,
};
CounterSectionV2.defaultProps = {
	activeCardIndex: null,
	cardList: [],
};
