// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	formSectionWrap: {
		marginBottom: theme.spacing(3),
	},
}));

export default useStyles;
