import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import constants from '@/constants';
import { textValuesPartner } from './constants';

// material
import { Box, Typography } from '@mui/material';

// components
import CTAButton from '@/components/widgets/ctaButton';

// style
import useStyles from './style';

export default function LoginBox(props) {
	const verticalLayout = props.layout === 'vertical';
	const isMobile = useSelector((state) => state.common.isMobile);
	const { classes } = useStyles({ ...props, verticalLayout });

	return (
		<Box className={classes.loginBoxWrap}>
			{props.title && (
				<Box className={classes.sectionOne}>
					<Typography variant="h5">{props.title}</Typography>
					{props.subTitle && (
						<Typography variant="body1">{props.subTitle}</Typography>
					)}
				</Box>
			)}
			<Box className={classes.sectionTwo}>
				<CTAButton
					pageSlug={props.pageSlug}
					className={classes.submitButton}
					fullWidth={verticalLayout || isMobile}
					label={props.buttonText || textValuesPartner.buttonText}
					link={`${constants.businessPlatformUrl}/signup?partnerNew=true`}
					analyticsProps={props.analyticsProps}
				/>
			</Box>
		</Box>
	);
}

LoginBox.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	width: PropTypes.string,
	titlePosition: PropTypes.string,
	buttonText: PropTypes.string,
	analyticsProps: PropTypes.object,
};
