import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const commonSectionStyles = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2, 0),
		},
	};
	return {
		container: {
			display: 'flex',
			margin: theme.spacing(3, 'auto'),
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
				margin: theme.spacing(0, 'auto'),
			},
		},
		descriptionSection: {
			...commonSectionStyles,
			width: '60%',
		},
		otherSection: {
			...commonSectionStyles,
			width: '40%',
		},
		titleWrap: {
			textAlign: 'center',
		},
		titleImageWrap: {
			position: 'relative',
			width: '414px',
			height: '207px',
			[theme.breakpoints.down('sm')]: {
				width: '221px',
				height: '110px',
			},
		},
		descriptionWrapper: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			maxWidth: '740px',
			[theme.breakpoints.down('lg')]: {
				maxWidth: '600px',
			},
		},
		content: {
			overflowWrap: 'break-word',
			'& a': {
				color: theme.palette.common.linkBlue,
				'&:hover': {
					textDecoration: 'underline',
				},
			},
		},
	};
});

export default useStyles;
