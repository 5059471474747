import PropTypes from 'prop-types';

// components
import StyledTableTag from '@/components/widgets/styledTableTag';

// style
import useStyles from './style';

export default function GenericTable(props) {
	const { classes } = useStyles();

	const renderRowBody = (row) => {
		return row.map((data, index) => (
			<td
				key={index}
				className={classes[`text_${props.columnsAlignment[index]}`]}
			>
				{data.value}
			</td>
		));
	};

	return (
		<StyledTableTag
			tableStyled={props.styled}
			rowBorder={props.rowBorder}
			columnBorder={props.columnBorder}
			tableBorder={props.tableBorder}
		>
			<thead>
				<tr>
					{props.headings.map((heading, index) => (
						<th
							key={index}
							className={classes[`text_${props.columnsAlignment[index]}`]}
						>
							{heading}
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{props.rows.map((row, index) => (
					<tr key={index}>{renderRowBody(row)}</tr>
				))}
			</tbody>
		</StyledTableTag>
	);
}

GenericTable.propTypes = {
	styled: PropTypes.bool,
	tableBorder: PropTypes.bool,
	rowBorder: PropTypes.bool,
	columnBorder: PropTypes.bool,
	columnsAlignment: PropTypes.array,
	headings: PropTypes.array,
	rows: PropTypes.array,
};

GenericTable.defaultProps = {
	columnsAlignment: [],
	headings: [],
	rows: [],
};
