import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
			gap: 0,
		},
	},
	imageSection: {
		position: 'relative',
		overflow: 'hidden',
		width: '45%',
		height: '338px',
		borderRadius: '15px',
		[theme.breakpoints.down('lg')]: {
			height: '315px',
		},
		[theme.breakpoints.down('md')]: {
			width: '40%',
			height: '134px',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			height: '187px',
			marginTop: theme.spacing(1.5),
		},
	},
	descriptionSection: {
		width: '55%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			width: '60%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	mobileDescription: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	titleWrapper: {
		textAlign: 'center',
		width: '100%',
		margin: theme.spacing(1.5, 0),
	},
	contentWrapper: {
		width: '70%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	content: {
		...theme.typography.h6,
		fontWeight: theme.typography.fontWeightMedium,
		overflowWrap: 'break-word',
		color: theme.palette.common.black,
		opacity: '0.38',
		'& a': {
			color: theme.palette.common.linkBlue,
			'&:hover': {
				textDecoration: 'underline',
			},
		},
		[theme.breakpoints.down('md')]: {
			...theme.typography.subtitle1,
		},
	},
	timerWrapper: {
		marginBottom: theme.spacing(2.5),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(1.5),
		},
	},
	ctaButtonWrap: {
		marginTop: theme.spacing(1.5),
		width: '320px',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
}));

export default useStyles;
