import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	counterSectionV3Wrap: {
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
		boxShadow: '-3px 4px 15px #FBC4AC',
		borderRadius: '20px',
		padding: theme.spacing(3, 0),

		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	cardV3Wrap: {
		padding: theme.spacing(2),
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: 'calc(33% - 32px)',
		display: 'inline-flex',
		borderRight: `1px solid ${theme.palette.common.textLightGrey}`,

		'&:nth-child(3n)': {
			border: 'none',
		},

		[theme.breakpoints.down('sm')]: {
			flexBasis: 'calc(100% - 32px)',
			flexDirection: 'column',
			margin: theme.spacing(0, 3),
			borderRight: 'none',
			borderBottom: `1px solid ${theme.palette.common.textLightGrey}`,
		},
	},
}));

export default useStyles;
