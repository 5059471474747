// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import FormBoxWrap from '@/components/shared/formBoxWrap';
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';
// utils
import useFormBoxProps from '@/utils/hooks/use-form-box-props';
// material
import { Box } from '@mui/material';
// Default
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// styles
import useStyles from './style';

export default function LandingPageBannerSection(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const formBoxProps = useFormBoxProps(props);

	const analyticsProps = {
		section: 'HERO_SECTION',
		...props.analyticsProps,
	};

	const TitleContent = React.useMemo(
		function TitleContent() {
			if (props.titleImage && props.titleImage.url) {
				return (
					<Box className={classes.titleImageWrap}>
						<ImageComponent
							src={props.titleImage.url}
							alt={props.titleImage.alternativeText}
							priority={true}
							layout="fill"
							objectFit="cover"
						/>
					</Box>
				);
			}

			return (
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={false}
					heading={'h1'}
					isVertical={true}
					fontSize={props.titleFontSize || titleSizes.large}
				/>
			);
		},
		[props.titleImage, props.highlightTitle, props.title]
	);

	return (
		<Box className={classes.container}>
			<Box className={classes.descriptionSection}>
				<Box className={classes.titleWrap}>{TitleContent}</Box>
				<Box className={classes.descriptionWrapper}>
					<Markdown className={classes.content} useCustomList={true}>
						{isMobile ? props.mobileDescription : props.description}
					</Markdown>
				</Box>
			</Box>
			{formBoxProps && (
				<Box className={classes.otherSection} id={props.formId}>
					<FormBoxWrap
						{...formBoxProps}
						fullWidthSubmitButton={true}
						analyticsProps={analyticsProps}
					/>
				</Box>
			)}
		</Box>
	);
}

LandingPageBannerSection.propTypes = {
	pageSlug: PropTypes.string.isRequired,
	titleImage: PropTypes.object,
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	description: PropTypes.string.isRequired,
	mobileDescription: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};
LandingPageBannerSection.defaultProps = {
	titleImage: null,
	useOtpFlowForForm: false,
	analyticsProps: {},
};
