import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { textValues } from './constants';

// components
import TitleSection from '@/components/shared/titleSection';
import Markdown from '@/components/widgets/markdown';

// materials
import { Box } from '@mui/material';

// styles

import useStyles from './style';

export default function OverviewSection(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const [showFullContentBody, setShowFullContentBody] = React.useState(false);

	const getShortContentText = () => {
		if (!showFullContentBody) {
			const charactedLimit = isMobile
				? props.shortDescriptionLetterCountMobile
				: props.shortDescriptionLetterCount;

			if (props.body.length > charactedLimit) {
				return props.body.slice(0, charactedLimit) + '...';
			}
		}

		return props.body;
	};

	const showReadMore = () => {
		const charactedLimit = isMobile
			? props.shortDescriptionLetterCountMobile
			: props.shortDescriptionLetterCount;

		return getShortContentText().length > charactedLimit;
	};

	const toggleShowFullContentBody = () => {
		setShowFullContentBody(!showFullContentBody);
	};

	return (
		<>
			<Box className={classes.titleSectionWrap}>
				<TitleSection
					title={props.title}
					highlightTitle={props.highlightTitle}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>
			<Box className={classes.contentWrap}>
				<Markdown>{getShortContentText()}</Markdown>
				{showReadMore() && (
					<Box
						onClick={toggleShowFullContentBody}
						component="span"
						className={classes.readMoreTextWrap}
					>
						{showFullContentBody ? textValues.readLess : textValues.readMore}
					</Box>
				)}
			</Box>
		</>
	);
}

OverviewSection.propTypes = {
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	body: PropTypes.string.isRequired,
	shortDescriptionLetterCount: PropTypes.number.isRequired,
	shortDescriptionLetterCountMobile: PropTypes.number.isRequired,
};
