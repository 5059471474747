// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme) => ({
	metaWrap: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1),
		marginBottom: theme.spacing(1),
		fontStyle: 'italic',
	},
	avatarWrap: {
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		fontStyle: 'normal',
		fontWeight: theme.typography.fontWeightMedium,
		height: theme.spacing(3.5),
		width: theme.spacing(3.5),
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	userName: {
		color: theme.palette.primary.main,
		fontWeight: theme.typography.fontWeightMedium,
		'&::after': {
			content: '", "',
			whiteSpace: 'pre',
		},
	},
	createdDate: {
		color: theme.palette.text.disabled,
		fontWeight: theme.typography.fontWeightMedium,
	},
	messageWrap: {
		backgroundColor: theme.palette.common.lightBlue,
		borderRadius: '15px',
		padding: theme.spacing(2),
	},
}));

export default useStyles;
