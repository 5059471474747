import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	videoCardWrap: {
		width: width || '100%',
		height: height || '100%',
		margin: '0 auto',
		overflow: 'hidden',
	},
	videoWrap: {
		overflow: 'hidden',
		borderRadius: '19px',
	},
	labelWrap: {
		padding: theme.spacing(1),
	},
	label: {
		textAlign: 'center',
		fontWeight: theme.typography.fontWeightSemiBold,
	},
}));

export default useStyles;
