import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	listGroupWrap: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
		},
	},
	sliderCardWrap: {
		cursor: 'pointer',
		width: '435px',
		padding: theme.spacing(0, 2),
	},
	cardWrap: {
		flexShrink: 0,
		flexGrow: 0,
		flexBasis: `calc(33% - 16px)`,

		[theme.breakpoints.down('md')]: {
			flexBasis: `calc(50% - 16px)`,
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%;',
		},
	},
	flexDirectionColumn: {
		flexDirection: 'column',
	},
	cursorPointer: {
		cursor: 'pointer',
	},
	linkWrap: {
		marginTop: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	link: {
		color: theme.palette.primary.main,
	},
}));

export default useStyles;
