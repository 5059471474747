// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import Timer from '@/components/shared/timer';
import TitleSection from '@/components/shared/titleSection';
import CTAButton from '@/components/widgets/ctaButton';
import ImageComponent from '@/components/widgets/image';
import Markdown from '@/components/widgets/markdown';

// material
import { Box, Rating } from '@mui/material';

// Defaults
import moment from 'moment-mini';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// styles
import useStyles from './style';

export default function EventPageBannerSection({
	ctaButton,
	titleImage,
	...props
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const hasEventEnded = () => {
		return moment().isSameOrAfter(moment(props.endTime));
	}
	const [eventEnded, setEventEnded] = React.useState(hasEventEnded);

	React.useEffect(() => {
		if (eventEnded) {
			return;
		}

		const intervalId = setInterval(() => {
			if (hasEventEnded()) {
				setEventEnded(true);
				clearInterval(intervalId);
			}
		}, 1000);

		return () => clearInterval(intervalId);
	}, [props.endTime]);

	const descriptionLength = 250;
	let descriptionText = isMobile ? props.mobileDescription : props.description;
	if (descriptionText && descriptionText.length > descriptionLength) {
		descriptionText = descriptionText.slice(0, descriptionLength) + '...';
	}

	const descriptionElement = (
		<>
			<Box className={classes.titleWrapper}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={true}
					heading={'h1'}
					fontSize={props.titleFontSize || titleSizes.large}
				/>
			</Box>
			<Box className={classes.contentWrapper}>
				<Markdown className={classes.content}>{descriptionText}</Markdown>
			</Box>
			{props.timer && (
				<Box className={classes.timerWrapper}>
					<Timer {...props.timer}></Timer>
				</Box>
			)}
			{props.rating && <Rating size={'large'} value={props.rating} readOnly />}
		</>
	);

	return (
		<Box className={classes.root}>
			{isMobile && (
				<Box className={classes.mobileDescription}>{descriptionElement}</Box>
			)}
			<Box className={classes.imageSection}>
				{titleImage && titleImage.url && (
					<ImageComponent
						src={titleImage.url}
						alt={titleImage.alternativeText}
						priority={true}
						layout="fill"
						objectFit="fill"
					/>
				)}
			</Box>
			<Box className={classes.descriptionSection}>
				{!isMobile && descriptionElement}

				<Box className={classes.ctaButtonWrap}>
					{!eventEnded && (
						<CTAButton
							pageSlug={props.pageSlug}
							fullWidth={true}
							label={ctaButton.label}
							link={ctaButton.link}
							form={props.form}
							authUserForm={props.authUserForm}
							formId={props.formId}
							openLoginModal={ctaButton.openLoginModal}
							openFormModal={ctaButton.openFormModal}
							scrollToForm={ctaButton.scrollToForm}
							formPageConstants={props.formPageConstants}
							formPageConfig={props.formPageConfig}
							useOtpFlowForForm={props.useOtpFlowForForm}
							paymentDetail={props.paymentDetail}
							analyticsProps={props.analyticsProps}
							shadow={ctaButton.shadow}
						/>)}
				</Box>

			</Box>
		</Box>
	);
}

EventPageBannerSection.propTypes = {
	pageSlug: PropTypes.string,
	titleImage: PropTypes.object.isRequired,
	titleFontSize: PropTypes.string,
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	description: PropTypes.string.isRequired,
	mobileDescription: PropTypes.string.isRequired,
	timer: PropTypes.object,
	endTime: PropTypes.string,
	rating: PropTypes.number,
	ctaButton: PropTypes.object.isRequired,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	authUserForm: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};
EventPageBannerSection.defaultProps = {
	titleImage: {},
	analyticsProps: {},
};
