import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import PlanCard from '@/components/shared/PlanComparisonSection/partials/planCard';
import PlanCardMobile from '@/components/shared/PlanComparisonSection/partials/planCardMobile';

// material
import { Box } from '@mui/material';

// Styles
import useStyles from './style';

const detailPageButtonText = 'View Details';
export default function PlanGroup({ plans, analyticsProps }) {
	const { classes } = useStyles();
	const isDesktop = useSelector((state) => state.common.isDesktop);

	const [expandedCardMap, setExpandedCardMap] = React.useState({});

	const handleSetExpandedCardMap = (index) => {
		const updatedExpandedCardMap = { ...expandedCardMap };
		updatedExpandedCardMap[index] = expandedCardMap[index] ? false : true;
		setExpandedCardMap(updatedExpandedCardMap);
	};

	return (
		<Box className={classes.planCardList}>
			{plans.map((plan, index) => (
				<Box key={index} className={classes.planCardWrap}>
					{isDesktop || expandedCardMap[index] ? (
						<PlanCard
							title={plan.title}
							subTitle={plan.subTitle}
							tag={plan.tag}
							buttonLink={plan.buttonLink}
							features={plan.features}
							highlight={plan.highlight}
							handleIconClick={() => handleSetExpandedCardMap(index)}
							detailPageButtonText={detailPageButtonText}
							showExpandIcon={!isDesktop}
							analyticsProps={analyticsProps}
						/>
					) : (
						<PlanCardMobile
							title={plan.title}
							subTitle={plan.subTitle}
							buttonLink={plan.buttonLink}
							highlight={plan.highlight}
							handleIconClick={() => handleSetExpandedCardMap(index)}
							detailPageButtonText={detailPageButtonText}
							analyticsProps={analyticsProps}
						/>
					)}
				</Box>
			))}
		</Box>
	);
}

PlanGroup.propTypes = {
	plans: PropTypes.array,
	analyticsProps: PropTypes.object,
};

PlanGroup.defaultProps = {
	plans: [],
};
