import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(15),
		textAlign: 'center',
		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(5),
		},
	},
	subTitle: {
		fontSize: theme.typography.h5.fontSize,
		color: theme.palette.text.disabled,
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.body1.fontSize,
		},
	},
	cardListWrap: {
		maxWidth: '1200px',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(5),
	}
}));

export default useStyles;
