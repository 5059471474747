import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		testimonialTitleWrap: {
			marginBottom: theme.spacing(1),
			textAlign: 'center',
		},
	};
});

export default useStyles;
