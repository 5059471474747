import PropTypes from 'prop-types';

//mui
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/material';

//styles
import useStyles from './style';

const InfoNote = (props) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.endNoteContainer}>
			<InfoIcon className={classes.endNoteIcon} /> <i>{props.infoNote}</i>
		</Box>
	);
};

export default InfoNote;

InfoNote.propTypes = {
	infoNote: PropTypes.string.isRequired,
};
