import { makeStyles } from 'tss-react/mui';

const transitionStyle = {
	'-webkit-transition': 'opacity 0.5s ease-in-out',
	'-moz-transition': 'opacity 0.5s ease-in-out',
	'-o-transition': 'opacity 0.5s ease-in-out',
	transition: 'opacity 0.5s ease-in-out',
};

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: '16px',
		textAlign: 'center',
	},
	title: {
		...theme.typography.h4,
		wordWrap: 'break-word',
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.h5.fontSize,
		},
	},
	mainSectionWrap: {
		display: 'flex',
		padding: '0 56px',
		'& > * + *': {
			marginLeft: '74px',
		},
		[theme.breakpoints.down('lg')]: {
			padding: '0 16px',
		},
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	infoCardList: {
		flexBasis: '48%',
		position: 'relative',
	},
	infoCard: {
		borderRadius: '25px',
		overflow: 'hidden',
		width: '100%',
		...transitionStyle,
	},
	infoCardImageWrap: {
		position: 'relative',
		paddingTop: '70%',
	},
	infoCardBody: {
		padding: '18px 18px 32px 18px',
		backgroundColor: theme.palette.primary.light,
		color: 'white',
		minHeight: '287px',
	},
	cardDescription: {
		fontSize: '22px',
		fontWeight: theme.typography.fontWeightMedium,
		marginTop: '8px',
	},
	listWrap: {
		flexBasis: '52%',
		overflow: 'hidden',
		'& > * + *': {
			'&::before': {
				content: '""',
				display: 'block',
				height: '2px',
				backgroundColor: '#D9D9D9',
				marginTop: '32px',
				marginBottom: '24px',
				[theme.breakpoints.down('sm')]: {
					marginTop: '24px',
					marginBottom: '16px',
				},
			},
		},
	},
	itemWrap: {
		'& > * + *': {
			marginTop: '17px',
		},
	},
	itemTitle: {
		fontSize: '22px',
		fontWeight: theme.typography.fontWeightMedium,
		...transitionStyle,
	},
	zeroOpacity: {
		opacity: 0,
		position: 'absolute',
		zIndex: -1,
		top: 0,
	},
}));

export default useStyles;
