import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { PlayArrow } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// components
import ImageComponent from '@/components/widgets/image';

// styles
import useStyles from './style';

export default function CounterCardV1({ image, title, subTitle, ...props }) {
	const { classes } = useStyles(props);
	const { isMobile, isDesktop } = useSelector((state) => state.common);

	return (
		<Box className={classes.counterCardV1Wrap}>
			<Box className={classes.counterCardV1SectionOne}>
				<Box className={classes.imageWrap}>
					{image && image.url ? (
						<ImageComponent
							src={image.url}
							alt={image.alternativeText}
							layout="fill"
							objectFit="cover"
						/>
					) : (
						<PlayArrow className={classes.logo} />
					)}
				</Box>
			</Box>
			<Box className={classes.counterCardV1SectionTwo}>
				<Typography
					variant={isMobile ? 'h6' : isDesktop ? 'h4' : 'h5'}
					className={`${classes.title} ${
						!isMobile && classes.fontWeightMedium
					}`}
				>
					{title}
				</Typography>
				<Typography
					variant={isDesktop ? 'h6' : 'body1'}
					className={classes.fontWeightMedium}
				>
					{subTitle}
				</Typography>
			</Box>
		</Box>
	);
}

CounterCardV1.propTypes = {
	image: PropTypes.object,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired,
};
CounterCardV1.defaultProps = {
	image: null,
};
