import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(1),
		textAlign: 'center',
	}
}));

export default useStyles;
