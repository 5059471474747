// material
import { Box, Skeleton, Typography } from '@mui/material';

//style
import useStyles from './style';

export default function Loader() {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.metaWrap}>
				<Box className={classes.avatarWrap} />
				<Skeleton width={100} />
				<Skeleton width={100} />
			</Box>
			<Box className={classes.messageWrap}>
				<Typography>
					<Skeleton />
				</Typography>
			</Box>
		</Box>
	);
}
