import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSection: {
		marginBottom: theme.spacing(2),
	},
	videoListSection: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(4),
	},
	cardWrap: {
		flexGrow: 0,
		flexShrink: 0,
		flexBasis: 'calc(33% - 24px)',
		[theme.breakpoints.down('md')]: {
			flexBasis: 'calc(50% - 16px)',
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
		},
	},
}));

export default useStyles;
