import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	title: {
		...theme.typography.h4,
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.h5.fontSize,
		},
	},
	sectionWrap: {
		boxShadow: '-1.55446px 2.07262px 15.5446px rgb(254 200 152 / 87%)',
		borderRadius: '15px',
		backgroundColor: theme.palette.common.white,
		padding: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 2),
		},
	},
	highlightV2Section: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(4),
		justifyContent: 'center',
	},
	cardV2Wrap: {
		flexGrow: 0,
		flexShrink: 0,
		display: 'inline-block',
	},
	cardWidth100: {
		flexBasis: '100%;',
	},
	cardWidth50: {
		flexBasis: 'calc(50% - 32px)',
	},
	cardWidth33: {
		flexBasis: 'calc(33.33% - 32px)',
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
}));

export default useStyles;
