import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { disablePadding }) => {
	return {
		dynamicZoneContainer: {
			maxWidth: theme.breakpoints.values.xl,
			margin: '0 auto',
		},
		dynamicZoneSection: {
			position: 'relative',
			padding: disablePadding ? 0 : theme.spacing(2),
			[theme.breakpoints.down('sm')]: {
				padding: disablePadding ? 0 : theme.spacing(2, 0),
			},
		},
		scrollMargin: {
			scrollMarginTop: '80px',
			scrollSnapMarginTop: '80px',
		},
	};
});

export default useStyles;
