import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	endNoteContainer: {
		background: 'rgba(255, 246, 214, 1)',
		borderRadius: '8px',
		display: 'flex',
		padding: '5px',
	},
	endNoteIcon: {
		color: 'rgba(109, 109, 109, 1)',
		fontStyle: 'italic',
		marginRight: '10px',
	},
}));

export default useStyles;
