// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// material
import { Box } from '@mui/material';

// Default
import PropTypes from 'prop-types';

// components
import TitleSection from '@/components/shared/titleSection';
import TestimonialSlider from './partials/testimonialSlider';

// styles
import useStyles from './style';

export default function TestimonialSection(props) {
	const { classes } = useStyles();

	const analyticsProps = {
		...props.analyticsProps,
		section: 'TESTIMONIALS_SECTION',
	};

	return (
		<Box>
			<Box className={classes.testimonialTitleWrap}>
				<TitleSection
					title={props.title}
					fontSize={props.titleFontSize || titleSizes.medium}
					subTitle={props.subTitle}
				/>
			</Box>

			<Box>
				<TestimonialSlider
					cardListTheme={props.cardListTheme}
					cardList={props.cardList}
					analyticsProps={analyticsProps}
					analyticsPropsSubSections={props.cardList.map((item) => item.name)}
				/>
			</Box>
		</Box>
	);
}

TestimonialSection.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	cardListTheme: PropTypes.string.isRequired,
	cardList: PropTypes.array,
	analyticsProps: PropTypes.object,
};
TestimonialSection.defaultProps = {
	subTitle: '',
	analyticsProps: {},
};
