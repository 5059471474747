import { makeStyles, withStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, props) => ({
	loginBoxWrap: {
		width: props.width || '100%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
	sectionOne: {
		marginBottom: theme.spacing(2),
		textAlign: props.titlePosition || 'center',
	},
	sectionTwo: {
		display: 'flex',
		gap: theme.spacing(2),
		flexDirection: props.verticalLayout ? 'column' : 'row',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			flexDirection: 'column',
		},
	},
	inputFieldContainer: {
		width: '100%',
	},
	countryCodeAdornment: {
		padding: theme.spacing(0, 1, 0, 1.5),
		color: theme.palette.common.black,
		borderRight: `1px solid ${theme.palette.primary.main}`,
	},
	buttonWrap: {
		minWidth: '150px',
	},
	submitButton: {
		margin: theme.spacing(1, 0),
		borderRadius: '37px',
		textTransform: 'capitalize',
		color: theme.palette.common.white,
		background:
			'linear-gradient(263.25deg, rgba(245, 131, 32, 0.87) 0%, rgba(241, 105, 33, 0.987054) 81.82%, #D75312 109%)',
		boxShadow: '6.84606px 11.4101px 17.1151px rgba(208, 102, 8, 0.3)',
	},
}));

export const textFieldStyleWrapper = (Component) =>
	withStyles(Component, (theme) => ({
		root: {
			'& .MuiOutlinedInput-input': {
				fontSize: 'inherit',
				padding: '4px 0',
				'&:-webkit-autofill': {
					WebkitBoxShadow: `0 0 0 100px ${theme.palette.common.white} inset`,
				},
			},

			'& .MuiInputAdornment-root': {
				fontSize: 'inherit',
			},

			'& .MuiFormHelperText-root': {
				marginTop: theme.spacing(1.5),
			},

			'& .MuiOutlinedInput-root': {
				height: '47px',
				fontSize: '16px',
				borderRadius: '37px',
				boxShadow: '6.84606px 11.4101px 17.1151px rgba(208, 102, 8, 0.3)',

				'& fieldset': {
					borderColor: theme.palette.primary.main,
				},
				'&:hover fieldset': {
					borderColor: theme.palette.primary.main,
				},
				'&.Mui-focused fieldset': {
					borderColor: theme.palette.primary.main,
				},
			},

			'& input[type=number]': {
				MozAppearance: 'textfield',
			},
			'& input[type=number]::-webkit-outer-spin-button': {
				WebkitAppearance: 'none',
				margin: 0,
			},
			'& input[type=number]::-webkit-inner-spin-button': {
				WebkitAppearance: 'none',
				margin: 0,
			},
		},
	}));

export default useStyles;
