import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			width: '100%',
			minHeight: '300px',
			boxShadow: '0px 0px 60px rgb(254 200 152 / 87%)',
			borderRadius: theme.spacing(3),
			display: 'flex',
			flexDirection: 'row',
			overflow: 'hidden',
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
			},
		},
		descriptionSection: {
			padding: theme.spacing(6, 8),
			width: '50%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-between',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				padding: theme.spacing(3),
			},
		},
		appLinkContainer: {
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(2),
			},
		},
		logoDescription: {
			marginBottom: theme.spacing(1),
			[theme.breakpoints.down('md')]: {
				fontSize: '14px',
			},
			[theme.breakpoints.down('sm')]: {
				fontSize: '18px',
				marginBottom: theme.spacing(0.5),
			},
		},
		appLinkWrap: {
			display: 'flex',
			'& > div': {
				marginRight: theme.spacing(5),
				[theme.breakpoints.down('md')]: {
					marginRight: theme.spacing(3),
				},
				[theme.breakpoints.down('sm')]: {
					marginRight: theme.spacing(1),
				},
			},
		},
		otherSection: {
			position: 'relative',
			width: '50%',
			height: '300px',
			overflow: 'hidden',
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				marginTop: theme.spacing(1.5),
			},
		},
	};
});

export default useStyles;
