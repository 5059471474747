// Material
import { makeStyles } from 'tss-react/mui';

// Styles
const useStyles = makeStyles()((theme, { width, height }) => ({
	titleWrap: {
		marginBottom: theme.spacing(2),
		textAlign: 'center',
	},
	iFrameWrap: {
		display: 'flex',
		justifyContent: 'center',
		width: `${width}px` || '100%',
		height: `${height}px` || '100%',
		maxWidth: '100%',
		margin: '0 auto',
		boxShadow: '-2.17932px 6.53796px 43.5864px rgb(167 152 152 / 25%)',
		borderRadius: '10px',
		overflow: 'hidden',
	},
	iFrame: {
		border: 'none',
	}
}));

export default useStyles;
