import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(5.5),
	},
	categoryRowWrap: {
		marginBottom: '46px',
	},
}));
export default useStyles;
