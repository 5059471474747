import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	counterCardV1Wrap: {
		width: width || '100%',
		height: height || '100%',
		display: 'inline-flex',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
	imageWrap: {
		position: 'relative',
		width: '75px',
		height: '75px',
		borderRadius: '50%',
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.common.lightOrange,
		[theme.breakpoints.down('md')]: {
			width: '65px',
			height: '65px',
		},
	},
	logo: {
		transform: 'rotate(-90deg)',
		width: '50px',
		height: '50px',
		color: theme.palette.common.white,
	},
	title: {
		color: theme.palette.common.textLightGrey,
	},
	fontWeightMedium: {
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default useStyles;
