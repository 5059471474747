import PropTypes from 'prop-types';

// components
import PlanFeature from '@/components/shared/PlanComparisonSection/partials/planFeature';
import GenericButton from '@/components/widgets/genericButton';

// material
import { KeyboardArrowUp } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function PlanCard({
	title,
	subTitle,
	tag,
	features,
	highlight,
	buttonLink,
	showExpandIcon,
	handleIconClick,
	detailPageButtonText,
	analyticsProps,
}) {
	const { classes, cx } = useStyles({ highlight });

	return (
		<Box
			className={cx(
				classes.planCard,
				highlight ? classes.highlightPlanCard : ''
			)}
		>
			<Box className={classes.titleWrap}>
				<Box className={classes.titleAndIcon}>
					<Typography component="h6" className={classes.title}>
						{title}
					</Typography>
					{showExpandIcon && (
						<KeyboardArrowUp
							classes={{ root: classes.arrowIcon }}
							onClick={handleIconClick}
						/>
					)}
				</Box>

				{subTitle && (
					<Typography className={classes.subTitle}>{subTitle}</Typography>
				)}
				<Box className={classes.tagWrap}>
					{tag && <Typography className={classes.tag}>{tag}</Typography>}
				</Box>
			</Box>
			<Box className={classes.featureWrap}>
				{features.map((feature, index) => (
					<Box key={index}>
						<PlanFeature
							feature={feature.feature}
							offered={feature.offered}
							iconHighlighted={highlight}
						/>
					</Box>
				))}
			</Box>
			{buttonLink && (
				<Box className={classes.planCardBottomWrap}>
					<GenericButton
						label={detailPageButtonText}
						link={buttonLink}
						fullWidth={true}
						variant={highlight ? 'contained' : 'outlined'}
						color={highlight ? 'success' : 'primary'}
						analyticsProps={analyticsProps}
					/>
				</Box>
			)}
		</Box>
	);
}

PlanCard.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	tag: PropTypes.string,
	buttonLink: PropTypes.string,
	features: PropTypes.array,
	highlight: PropTypes.bool,
	showExpandIcon: PropTypes.bool,
	handleIconClick: PropTypes.func.isRequired,
	detailPageButtonText: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};

PlanCard.defaultProps = {
	highlight: false,
	showExpandIcon: false,
	features: [],
	analyticsProps: {},
};
