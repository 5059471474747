// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// Material
import { Box, Typography } from '@mui/material';

// Defaults
import PropTypes from 'prop-types';

// Styles
import useStyles from './style';

// Component
import TitleSection from '@/components/shared/titleSection';

export default function Timeline({
	title,
	titleHighlighted,
	description,
	cardList,
	titleFontSize,
}) {
	const { classes } = useStyles();
	return (
		<Box className={classes.root}>
			{title && (
				<Box className={classes.titleWrap}>
					<TitleSection
						title={titleHighlighted ? null : title}
						highlightTitle={titleHighlighted ? title : null}
						fontSize={titleFontSize || titleSizes.medium}
					/>
					{description && (
						<Typography variant="body1">{description}</Typography>
					)}
				</Box>
			)}
			<Box className={classes.timeline}>
				{cardList.map((data, i) => (
					<Box
						key={i}
						className={`${classes.container} ${
							i % 2 !== 0 ? classes.containerLeft : classes.containerRight
						}`}
					>
						<Box className={classes.containerContent}>
							<Typography className={classes.title} variant="subtitle2">
								{data.title}
							</Typography>
							{data.description && (
								<Typography className={classes.description} variant="subtitle2">
									{data.description}
								</Typography>
							)}
						</Box>
						<Box
							className={`${classes.date} ${
								i % 2 === 0 ? classes.dateLeft : classes.dateRight
							}`}
						>
							{data.date}
						</Box>
					</Box>
				))}
			</Box>
		</Box>
	);
}

Timeline.propTypes = {
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	description: PropTypes.string,
	cardList: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
};

Timeline.defaultProps = {
	titleHighlighted: false,
	analyticsProps: {},
};
