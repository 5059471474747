export const shapeCategories = (categories = []) => {
	return categories.map((category) => {
		category.id = category.title.toLowerCase().replace(/ +/g, '-');

		category.offeredPlans.sort((a, b) => {
			if (a.priority || b.priority)
				return (a.priority || 0) - (b.priority || 0);

			return 0;
		});

		return category;
	});
};
