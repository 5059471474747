import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => {
	return {
		cardContainer: {
			borderRadius: theme.spacing(2),
			height: '100%',
			padding: theme.spacing(2.5),
			background: theme.palette.common.greyishBlue,
		},
		title: {
			fontSize: '20px',
			fontWeight: 600,
			[theme.breakpoints.down('md')]: {
				fontSize: '18px',
			},
		},
		details: {
			fontSize: '16px',
			fontWeight: 500,
		},
		ctaSection: {
			marginTop: theme.spacing(2),
			display: 'inline-flex',
			alignItems: 'center',
			gap: theme.spacing(1),
			cursor: 'pointer',
		},
		ctaLabel: {
			color: theme.palette.primary.main,
			fontWeight: theme.typography.fontWeightMedium,
		},
	};
});
