import PropTypes from 'prop-types';

// constants
import { textValues } from '@/components/widgets/couponCodeBox/constants';

// component
import DiscountAmount from '@/components/widgets/couponCodeBox/partials/discountAmount';
import CTAButton from '@/components/widgets/ctaButton';

// material
import { Close } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	IconButton,
	TextField,
	Typography,
} from '@mui/material';

// styles
import useStyles from './style';

export default function CouponCodeModalForm({
	paymentDetail,
	errorText,
	couponCode,
	...props
}) {
	const { classes } = useStyles(props);

	return (
		<Dialog
			open={props.open}
			classes={{ paper: classes.dialogPaper }}
			maxWidth="lg"
		>
			<Box className={classes.closeIconWrap}>
				<IconButton onClick={props.handleClose}>
					<Close fontSize="small" />
				</IconButton>
			</Box>

			{props.title && (
				<Typography component="h1" textAlign="center">
					<Typography
						component="span"
						variant="h4"
						fontWeight="fontWeightSemiBold"
					>
						{props.title}
					</Typography>
				</Typography>
			)}
			{props.subTitle && (
				<Typography
					fontWeight="fontWeightRegular"
					component="p"
					variant="h6"
					textAlign="center"
				>
					{props.subTitle}
				</Typography>
			)}

			<Box className={classes.couponSectionWrap}>
				<DiscountAmount
					discountAmount={(props.discountAmount || '').toString()}
					originalAmount={(paymentDetail.productAmount || '').toString()}
				/>
			</Box>

			<Box className={classes.couponSectionWrap}>
				<Typography component="span" fontWeight="fontWeightMedium">
					{textValues.couponCodeFieldText}
				</Typography>
				<Box className={classes.couponInputWrap}>
					<TextField
						type="text"
						value={couponCode}
						onChange={props.handleInputChange}
						onKeyDown={props.handleKeyDown}
						helperText={errorText || null}
						FormHelperTextProps={{
							error: Boolean(errorText),
							sx: { marginLeft: 0 },
						}}
						InputProps={{ sx: { backgroundColor: '#f1f5fd' } }}
						sx={{
							width: '100%',
							fieldSet: {
								borderStyle: 'dashed',
								borderRight: 'none',
								borderTopLeftRadius: '7px',
								borderBottomLeftRadius: '7px',
							},
						}}
					/>
					<Button
						variant="contained"
						className={classes.applyButton}
						onClick={props.handleApplyCouponCode}
						disabled={props.loading || (couponCode || '').trim().length === 0}
					>
						{props.loading ? (
							<CircularProgress size="28px" />
						) : (
							textValues.applyButtonText
						)}
					</Button>
				</Box>
			</Box>

			{props.canProceedToPayment && (
				<Box className={classes.proceedButtonWrap}>
					<CTAButton
						fullWidth={true}
						openFormModal={true}
						pageSlug={props.pageSlug}
						form={props.form}
						className={classes.proceedButton}
						label={textValues.proceedButtonText}
						formPageConstants={props.formPageConstants}
						formPageConfig={props.formPageConfig}
						paymentDetail={{
							...paymentDetail,
							couponCode,
							withCouponCode: false,
						}}
						analyticsProps={props.analyticsProps}
						shadow={false}
						styledButton={false}
						color="success"
					/>
				</Box>
			)}
		</Dialog>
	);
}

CouponCodeModalForm.propTypes = {
	// data
	title: PropTypes.string,
	subTitle: PropTypes.string,
	couponCode: PropTypes.string,
	discountAmount: PropTypes.string,
	analyticsProps: PropTypes.object,
	pageSlug: PropTypes.string.isRequired,
	paymentDetail: PropTypes.object.isRequired,
	// function
	open: PropTypes.bool,
	loading: PropTypes.bool,
	errorText: PropTypes.string,
	canProceedToPayment: PropTypes.bool,
	// form
	form: PropTypes.object.isRequired,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
	// handlers
	handleClose: PropTypes.func.isRequired,
	handleKeyDown: PropTypes.func.isRequired,
	handleInputChange: PropTypes.func.isRequired,
	handleApplyCouponCode: PropTypes.func.isRequired,
};

CouponCodeModalForm.defaultProps = {
	open: false,
	loading: false,
	canProceedToPayment: false,
	analyticsProps: {},
};
