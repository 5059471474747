import PropTypes from 'prop-types';

// components
import GenericButton from '@/components/widgets/genericButton';

// material
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function PlanCardMobile({
	title,
	subTitle,
	highlight,
	buttonLink,
	detailPageButtonText,
	handleIconClick,
	analyticsProps,
}) {
	const { classes, cx } = useStyles({ highlight });

	return (
		<Box
			className={cx(
				classes.planCard,
				highlight ? classes.highlightPlanCard : ''
			)}
		>
			<Box className={classes.titleSection}>
				<Typography component="h6" className={classes.title}>
					{title}
				</Typography>
				<KeyboardArrowDown
					classes={{ root: classes.arrowIcon }}
					onClick={handleIconClick}
				/>
			</Box>
			{subTitle && (
				<Typography className={classes.subTitle}>{subTitle}</Typography>
			)}

			{buttonLink && (
				<Box className={classes.planCardBottomWrap}>
					<GenericButton
						label={detailPageButtonText}
						link={buttonLink}
						fullWidth={true}
						variant={highlight ? 'contained' : 'outlined'}
						color={highlight ? 'success' : 'primary'}
						analyticsProps={analyticsProps}
					/>
				</Box>
			)}
		</Box>
	);
}

PlanCardMobile.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	buttonLink: PropTypes.string,
	highlight: PropTypes.bool,
	handleIconClick: PropTypes.func.isRequired,
	detailPageButtonText: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};

PlanCardMobile.defaultProps = {
	highlight: false,
	analyticsProps: {},
};
