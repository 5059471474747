import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { textValues } from './constants';

// components
import IconAction from '@/components/shared/iconAction';
import TitleSection from '@/components/shared/titleSection';

// Material
import { Box, Typography } from '@mui/material';

// Styles
import useStyles from './style';

export default function ContactAndLocationSection(props) {
	const { classes } = useStyles();

	return (
		<Box>
			<Box className={classes.titleSectionWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>

			<Box className={classes.mainSection}>
				<Box className={classes.locationSection}>
					<iframe
						width="100%"
						className={classes.iFrame}
						loading="lazy"
						src={props.mapEmbedUrl}
						allowFullScreen
					></iframe>
				</Box>
				<Box className={classes.contactInfoWrap}>
					{props.contactEmail && (
						<Box>
							<Typography variant="h6" className={classes.label}>
								{textValues.email}
							</Typography>
							<Typography className={classes.text}>
								{props.contactEmail}
							</Typography>
						</Box>
					)}
					{props.contactNumber && (
						<Box>
							<Typography variant="h6" className={classes.label}>
								{textValues.contact}
							</Typography>
							<Typography className={classes.text}>
								{props.contactNumber}
							</Typography>
						</Box>
					)}

					{props.socialIconActions.length > 0 && (
						<Box>
							<Typography variant="h6" className={classes.label}>
								{textValues.socialMedia}
							</Typography>
							<Box className={classes.socialIconWrap}>
								{props.socialIconActions.map((iconAction, index) => (
									<IconAction
										key={index}
										materialIcon={iconAction.platform}
										goto={iconAction.goto}
										iconWidth="50px"
										iconHeight="50px"
										circularIcon
									/>
								))}
							</Box>
						</Box>
					)}
				</Box>
			</Box>
		</Box>
	);
}

ContactAndLocationSection.propTypes = {
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	contactEmail: PropTypes.string,
	contactNumber: PropTypes.string,
	mapEmbedUrl: PropTypes.string.isRequired,
	socialIconActions: PropTypes.array,
};

ContactAndLocationSection.defaultProps = {
	socialIconActions: [],
};
