import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			padding: theme.spacing(5, 12),
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(2, 2, 0),
			},
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(3, 2),
			},
		},
		title: {
			fontSize: theme.spacing(5),
			fontWeight: 600,
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
			},
		},
		description: {
			fontSize: theme.spacing(2.5),
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
			},
			[theme.breakpoints.down('xs')]: {
				fontSize: theme.spacing(2),
			},
		},
	};
});

export default useStyles;
