import moment from 'moment-mini';
import PropTypes from 'prop-types';

// component
import Comment from '@/components/shared/commentSection/partials/comment';
import Loader from '@/components/shared/commentSection/partials/comment/loader';

// material
import { Box } from '@mui/material';

//style
import useStyles from './style';

export default function ListSection({ loading, data }) {
	const { classes } = useStyles();

	if (loading) {
		return Array.from({ length: 3 }, (_, index) => (
			<Box key={index} className={classes.commentWrap}>
				<Loader />
			</Box>
		));
	}

	return data.map((item) => (
		<Box key={item.id} className={classes.commentWrap}>
			<Comment
				message={item.message}
				userName={item.userName}
				email={item.email}
				mobile={item.mobile}
				createdDate={moment(item.createdAt).format('Do MMM, hh:mm A')}
			/>
		</Box>
	));
}

ListSection.propTypes = {
	data: PropTypes.array,
	loading: PropTypes.bool,
};

ListSection.defaultProps = {
	data: [],
	loading: false,
};
