export const desktopUiSchema = {
	type: 'VerticalLayout',
	elements: [
		{
			type: 'HorizontalLayout',
			elements: [
				{
					type: 'Control',
					scope: '#/properties/message',
					options: {
						multi: true,
					},
				},
			],
		},
		{
			type: 'HorizontalLayout',
			elements: [
				{
					type: 'Control',
					scope: '#/properties/userName',
					label: 'Name',
				},
				{
					type: 'Control',
					scope: '#/properties/email',
				},
				{
					type: 'Control',
					scope: '#/properties/mobile',
					options: {
						type: 'number',
					},
				},
			],
		},
	],
};

export const tabletUiSchema = {
	type: 'VerticalLayout',
	elements: [
		{
			type: 'HorizontalLayout',
			elements: [
				{
					type: 'Control',
					scope: '#/properties/message',
					options: {
						multi: true,
					},
				},
			],
		},
		{
			type: 'HorizontalLayout',
			elements: [
				{
					type: 'Control',
					scope: '#/properties/userName',
					label: 'Name',
				},
				{
					type: 'Control',
					scope: '#/properties/email',
				},
				{
					type: 'Control',
					scope: '#/properties/mobile',
					options: {
						type: 'number',
					},
				},
			],
		},
	],
};

export const mobileUiSchema = {
	type: 'VerticalLayout',
	elements: [
		{
			type: 'Control',
			scope: '#/properties/message',
			options: {
				multi: true,
			},
		},
		{
			type: 'Control',
			scope: '#/properties/userName',
			label: 'Name',
		},
		{
			type: 'Control',
			scope: '#/properties/email',
		},
		{
			type: 'Control',
			scope: '#/properties/mobile',
			options: {
				type: 'number',
			},
		},
	],
};
