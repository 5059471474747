// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// mui
import { Box, Typography } from '@mui/material';
// Defaults
import { get } from 'lodash';
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { scroller } from 'react-scroll';

// Components
import TitleSection from '@/components/shared/titleSection';
import LocationCard from './partials/locationCard';
import Map from './partials/map';

// styles
import { useStyles } from './style';

const mapBoxId = `MAP_BOX`;
export default function LocationSection(props) {
	const { classes } = useStyles();
	const [currentGooglePlacesId, setCurrentGooglePlacesId] = useState(
		get(props, 'locations.0.locationDetails.googlePlacesId') || null
	);

	const onClickViewMap = (googlePlacesId) => {
		if (googlePlacesId) {
			setCurrentGooglePlacesId(googlePlacesId);
			scroller.scrollTo(mapBoxId, {
				offset: -150,
			});
		}
	};

	if (!props.locations && !props.locations.length) return null;

	return (
		<Box>
			<Box className={classes.titleWrap}>
				{props.title && (
					<TitleSection
						title={props.title}
						fontSize={props.titleFontSize || titleSizes.medium}
					/>
				)}
				{props.description && (
					<Typography variant="body1">{props.description}</Typography>
				)}
			</Box>
			<Box>
				<Box className={classes.mapSection} id={mapBoxId}>
					<Map currentGooglePlacesId={currentGooglePlacesId} />
				</Box>
				<Box className={classes.locationListSection}>
					{props.locations.map(({ locationDetails }, index) => (
						<Box key={index} className={classes.locationItemWrap}>
							<LocationCard
								title={locationDetails.title}
								addressLine1={locationDetails.addressLine1}
								addressLine2={locationDetails.addressLine2}
								ctaLabel={locationDetails.ctaLabel}
								googlePlacesId={locationDetails.googlePlacesId}
								pincode={locationDetails.pincode}
								receptionNumber={locationDetails.receptionNumber}
								onClickViewMap={onClickViewMap}
							/>
						</Box>
					))}
				</Box>
			</Box>
		</Box>
	);
}

LocationSection.propTypes = {
	titleFontSize: PropTypes.string,
	title: PropTypes.string,
	description: PropTypes.string,
	locations: PropTypes.array,
};
