import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// Components
import IconAction from '@/components/shared/iconAction';
import ImageComponent from '@/components/widgets/image';

// material
import { Box, Typography } from '@mui/material';

// styles
import TitleSection from '@/components/shared/titleSection';
import useStyles from './style';

export default function DownloadAppHighlight(props) {
	const { classes } = useStyles();
	const { isMobile, isDesktop } = useSelector((state) => state.common);

	const iconActionStyles = {
		iconWidth: isMobile ? '115px' : isDesktop ? '150px' : '100px',
		iconHeight: isMobile ? '48px' : isDesktop ? '60px' : '42px',
	};

	return (
		<Box className={classes.root}>
			<Box className={classes.descriptionSection}>
				<TitleSection
					title={props.title}
					highlightTitle={props.highlightTitle}
					textAlign={'left'}
					highlightTitleAfter={true}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
				<Box className={classes.appLinkContainer}>
					<Typography variant="h5" className={classes.logoDescription}>
						{props.appIconLabel}
					</Typography>

					<Box className={classes.appLinkWrap}>
						<IconAction {...iconActionStyles} {...props.androidAppIcon} />
						<IconAction {...iconActionStyles} {...props.iosAppIcon} />
					</Box>
				</Box>
			</Box>
			<Box className={classes.otherSection}>
				<ImageComponent
					src={props.titleImage.url}
					alt={props.titleImage.alternativeText}
					layout="fill"
					objectFit="contain"
					objectPosition="top"
				/>
			</Box>
		</Box>
	);
}

DownloadAppHighlight.propTypes = {
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleImage: PropTypes.object.isRequired,
	appIconLabel: PropTypes.string.isRequired,
	androidAppIcon: PropTypes.object,
	iosAppIcon: PropTypes.object,
	analyticsProps: PropTypes.object,
};
DownloadAppHighlight.defaultProps = {
	titleImages: [],
	analyticsProps: {},
};
