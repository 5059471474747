import dynamic from 'next/dynamic';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
// constants
import { listTypes } from '../../constants';
import { alignmentTypes, textValues } from './constants';

// material
import { Box, Typography } from '@mui/material';

// components
const ItemCard = dynamic(() =>
	import('@/components/shared/recentlyAddedSection/partials/itemCard')
);
const BlogCard = dynamic(() =>
	import('@/components/shared/recentlyAddedSection/partials/blogCard')
);
const ComponentSlider = dynamic(() =>
	import('@/components/shared/componentSlider')
);

// utils
import * as eventActions from '@/utils/analytics';

// actions
import { goToRoute, isExternalUrl } from '@/store/actions';

// styles
import useStyles from './style';

export default function ListGroup({ loading, cardList, alignment, ...props }) {
	const { classes } = useStyles(props);
	const dispatch = useDispatch();
	const { isMobile, isDesktop } = useSelector((state) => state.common);

	const location = useSelector((state) => state.router.location);
	let searchParams = new URLSearchParams(location.search);

	const ListItemCard =
		(props.listType === listTypes.v2 || props.listType === listTypes.v3) &&
		alignment === alignmentTypes.vertical
			? BlogCard
			: ItemCard;
	let SectionComponent, cardWrapClass, sectionProps;

	if (props.slider) {
		SectionComponent = ComponentSlider;
		cardWrapClass = classes.sliderCardWrap;
		sectionProps = {
			vertical: alignment === alignmentTypes.vertical,
			verticalSwiping: alignment === alignmentTypes.vertical,
			focusOnSelect: alignment === alignmentTypes.horizontal,
			slidesToShow: isMobile ? 1 : isDesktop ? 3 : 2,
			centerFocusedSlider: false,
			analyticsProps: props.analyticsProps,
			analyticsPropsSubSections: cardList.map((item) => item.title),
		};
	} else {
		SectionComponent = Box;
		cardWrapClass = `${classes.cardWrap} ${classes.cursorPointer}`;

		const className = `${classes.listGroupWrap} ${
			alignment === alignmentTypes.vertical ? classes.flexDirectionColumn : ''
		}`;
		sectionProps = { className };
	}

	const renderLink = () => {
		if (!props.link) return null;

		let LinkElement,
			link = props.link;

		if (isExternalUrl(link)) {
			LinkElement = (
				<a
					href={link}
					target="_blank"
					rel="noreferrer"
					className={classes.link}
				>
					{textValues.linkText}
				</a>
			);
		} else {
			LinkElement = (
				<Link href={link}>
					<a className={classes.link}>{textValues.linkText}</a>
				</Link>
			);
		}

		return <Box className={classes.linkWrap}>{LinkElement}</Box>;
	};

	const handleCardClick = (e, { path, title }) => {
		e.stopPropagation();
		if (!path) return;

		eventActions.trackEvent('LINK_CLICKED', {
			...props.analyticsProps,
			eventCategory: 'NAVIGATION',
			eventAction: 'CLICKED',
			subSection: title,
		});

		dispatch(goToRoute(path, {}, searchParams));
	};

	if (!loading && cardList.length === 0) {
		return (
			<Box className={cardWrapClass}>
				<Typography variant="h6" textAlign="center">
					{textValues.noData}
				</Typography>
			</Box>
		);
	}

	if (loading) {
		return (
			<SectionComponent {...sectionProps}>
				{Array.from({ length: props.itemCount }, (_, index) => (
					<Box key={index} className={cardWrapClass}>
						<ListItemCard loading={true} />
					</Box>
				))}
			</SectionComponent>
		);
	}

	return (
		<Box>
			<SectionComponent {...sectionProps}>
				{cardList.map((card, index) => (
					<Box
						key={index}
						className={cardWrapClass}
						onClick={(e) => handleCardClick(e, card)}
					>
						<ListItemCard
							slug={card.slug}
							title={card.title}
							location={card.location}
							timing={card.timing}
							description={card.description}
							image={card.image}
						/>
					</Box>
				))}
			</SectionComponent>
			{renderLink()}
		</Box>
	);
}

ListGroup.propTypes = {
	alignment: PropTypes.string,
	slider: PropTypes.bool,
	itemCount: PropTypes.number,
	listType: PropTypes.string,
	link: PropTypes.string,
	cardList: PropTypes.array,
	analyticsProps: PropTypes.object,
};
ListGroup.defaultProps = {
	slider: false,
	itemCount: 6,
	alignment: alignmentTypes.horizontal,
	analyticsProps: {},
};
