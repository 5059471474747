import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import HightlightSectionV1 from '@/components/shared/highlightSection/partials/highlightSectionV1';
import HightlightSectionV2 from '@/components/shared/highlightSection/partials/highlightSectionV2';

// constants
import { highlightCardTypes } from './constants';

export default function HighlightSection({ cardList, ...props }) {
	const isDesktop = useSelector((state) => state.common.isDesktop);

	let SectionComponent,
		sectionProps = {
			cardList,
			title: props.title,
			highlightTitle: props.highlightTitle,
			subTitle: props.subTitle,
			titleFontSize: props.titleFontSize,
		};

	switch (props.cardListTheme) {
		case highlightCardTypes.v1: {
			SectionComponent = HightlightSectionV1;
			sectionProps.centerAlignContent = cardList.length < 3 && isDesktop;
			break;
		}

		case highlightCardTypes.v2: {
			SectionComponent = HightlightSectionV2;
			let columnCount = 1;
			if (isDesktop) {
				columnCount = cardList.length <= 5 || cardList.length % 2 !== 0 ? 2 : 3;
			}
			sectionProps.columnCount = columnCount;
			break;
		}
	}

	return <SectionComponent {...sectionProps} />;
}

HighlightSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardListTheme: PropTypes.string.isRequired,
	cardList: PropTypes.array,
};
HighlightSection.defaultProps = {
	cardList: [],
};
