import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import CouponCodeBox from '@/components/widgets/couponCodeBox';
import ImageComponent from '@/components/widgets/image';

// material
import { Box } from '@mui/material';

// styles

import useStyles from './style';

export default function ProductPageBannerSection({ titleImages, ...props }) {
	const { classes } = useStyles();

	const couponCodeBoxAnalyticsProps = {
		...props.analyticsProps,
		section: 'BANNER_SECTION',
		subSection: 'COUPON_CODE_BOX',
	};

	const renderCouponCodeBox = !!(
		props.form &&
		props.paymentDetail &&
		Object.keys(props.paymentDetail).length
	);

	const [activeImageIndex, setActiveImageIndex] = React.useState(
		titleImages.length > 0 ? 0 : null
	);

	React.useEffect(() => {
		let intervalID;
		if (titleImages.length > 0) {
			intervalID = setInterval(() => {
				setActiveImageIndex((currentActiveIndex) => {
					return currentActiveIndex + 1 >= titleImages.length
						? 0
						: currentActiveIndex + 1;
				});
			}, 5000);
		}

		return () => intervalID && clearInterval(intervalID);
	}, []);

	return (
		<Box className={classes.container}>
			<Box className={classes.descriptionSection}>
				<Box className={classes.contentLimitedWidth}>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						highlightTitleAfter={true}
						heading={'h1'}
						fontSize={props.titleFontSize || titleSizes.large}
						subTitle={props.subTitle}
					/>

					{renderCouponCodeBox && (
						<Box className={classes.couponCodeBoxWrap}>
							<CouponCodeBox
								title={
									props.title && props.highlightTitle
										? `${props.title} ${props.highlightTitle}`
										: props.title || props.highlightTitle || ''
								}
								subTitle={props.subTitle}
								buttonLabel={props.buttonLabel}
								pageSlug={props.pageSlug}
								form={props.form}
								formId={props.formId}
								formPageConstants={props.formPageConstants}
								formPageConfig={props.formPageConfig}
								useOtpFlowForForm={props.useOtpFlowForForm}
								paymentDetail={props.paymentDetail}
								analyticsProps={couponCodeBoxAnalyticsProps}
							/>
						</Box>
					)}
				</Box>
			</Box>
			<Box className={classes.otherSection}>
				<Box className={classes.titleImageWrap}>
					{titleImages.map((image, index) => (
						<ImageComponent
							key={index}
							src={image.url}
							alt={image.alternativeText}
							className={`${classes.image} ${
								index !== activeImageIndex && classes.zeroOpacity
							}`}
							priority={true}
							layout="fill"
							objectFit="contain"
							objectPosition="top"
						/>
					))}
				</Box>
			</Box>
		</Box>
	);
}

ProductPageBannerSection.propTypes = {
	pageSlug: PropTypes.string,
	titleImages: PropTypes.array,
	highlightTitle: PropTypes.string,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	buttonLabel: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
	// form
	form: PropTypes.object,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	paymentDetail: PropTypes.object,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
};
ProductPageBannerSection.defaultProps = {
	titleImages: [],
	analyticsProps: {},
};
