import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	container: {
		width: width || '100%',
		height: height || '100%',
		display: 'inline-block',
		overflow: 'hidden',
		borderRadius: '18px',
		padding: theme.spacing(2),
		border: '0.5px solid #6D6D6D',
	},
	title: {
		fontWeight: theme.typography.fontWeightSemiBold,
	},
}));

export default useStyles;
