import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const commonSectionStyles = {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	};

	const commonImageStyles = {
		position: 'relative',
		width: '100%',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			height: '280px',
		},
	};

	const commonLimitedWidthStyles = {
		margin: '0 auto',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '100%',
		},
	};

	return {
		container: {
			display: 'flex',
			gap: theme.spacing(2),
			margin: theme.spacing(0, 'auto'),
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				margin: theme.spacing(0, 'auto'),
			},
		},
		descriptionSection: {
			...commonSectionStyles,
			width: '55%',
		},
		ctaButtonWrap: {
			margin: theme.spacing(6, 'auto', 0),
			width: '320px',
			[theme.breakpoints.down('md')]: {
				marginTop: theme.spacing(3),
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: theme.spacing(0),
				width: '100%',
			},
		},
		otherSection: {
			...commonSectionStyles,
			width: '45%',
		},
		titleImageWrap: {
			...commonImageStyles,
			height: '500px',
		},
		titleImageSmallWrap: {
			...commonImageStyles,
			height: '400px',
		},
		image: {
			'-webkit-transition': 'opacity 1s ease-in-out',
			'-moz-transition': 'opacity 1s ease-in-out',
			'-o-transition': 'opacity 1s ease-in-out',
			transition: 'opacity 1s ease-in-out',
		},
		contentLimitedWidth: {
			...commonLimitedWidthStyles,
			maxWidth: '550px',
		},
		contentLimitedWidthSmall: {
			...commonLimitedWidthStyles,
			maxWidth: '650px',
		},
		content: {
			overflowWrap: 'break-word',
			'& a': {
				color: theme.palette.common.linkBlue,
				'&:hover': {
					textDecoration: 'underline',
				},
			},
		},
		textCenter: {
			textAlign: 'center',
		},
		zeroOpacity: {
			opacity: 0,
		},
		row: {
			flexDirection: 'row',
		},
		rowReverse: {
			flexDirection: 'row-reverse',
		},
	};
});

export default useStyles;
