import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	const commonSectionStyles = {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		gap: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
			gap: theme.spacing(1),
		},
	};

	return {
		root: {
			display: 'flex',
			gap: theme.spacing(2),
			[theme.breakpoints.down('md')]: {
				flexDirection: 'column',
			},
		},
		contentSection: {
			...commonSectionStyles,
			flexBasis: 'calc(60% - 16px)',
		},
		imageSection: {
			...commonSectionStyles,
			alignItems: 'center',
			flexBasis: 'calc(40% - 16px)',
		},
		description: {
			fontSize: '18px',
			[theme.breakpoints.down('sm')]: {
				fontSize: '16px',
			},
		},
		imageWrap: {
			width: 'fit-content',
			borderRadius: '50%',
			overflow: 'hidden',
			boxShadow:
				'0px 10px 50px rgba(245, 106, 32, 0.3) inset,0px -30px 50px rgba(245, 106, 32, 0.3), 30px -10px 50px rgba(245, 131, 32, 0.03), -30px -10px 50px rgba(245, 211, 32, 0.1)',
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			padding: theme.spacing(0, 3),
			height: '100%',
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(0, 1),
			},
		},
		textCenter: {
			textAlign: 'center',
		},
	};
});

export default useStyles;
