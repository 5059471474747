import Link from 'next/link';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { paymentIssueData } from './constants';

// components
import TitleSection from '@/components/shared/titleSection';

// material
import { Box, Button, Typography } from '@mui/material';

// utils
import * as eventActions from '@/utils/analytics';

// style
import useStyles from './style';

export default function ThankYouPageMainSection({
	searchQueryValues,
	...props
}) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	const status = searchQueryValues.status;
	const orderID = searchQueryValues.razorpay_order_id;

	let data = { ...props };

	if (status || orderID) {
		if (status !== '1') {
			data = { ...paymentIssueData };
			if (orderID) data.subDescription = `(ORDER ID: ${orderID})`;
		}
	}

	const handleClick = (button) => {
		if (!button.link && !button.goTo) return;

		eventActions.trackEvent('BUTTON_CLICKED', {
			eventCategory: 'NAVIGATION',
			eventLabel: button.label,
			eventAction: 'CLICKED',
			section: 'THANK_YOU_BUTTON_SECTION',
		});
	};

	const renderButtonLink = (button) => {
		const ButtonElement = (
			<Button
				fullWidth
				color={button.color || 'primary'}
				variant="contained"
				onClick={() => handleClick(button)}
				size="large"
			>
				{button.label}
			</Button>
		);

		return button.goTo ? (
			<a href={button.goTo}>{ButtonElement}</a>
		) : button.link ? (
			<Link href={button.link}>
				<a>{ButtonElement}</a>
			</Link>
		) : (
			ButtonElement
		);
	};

	return (
		<Box className={classes.sectionWrap}>
			<Box>
				<TitleSection
					title={data.title}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>

			<Box>
				<Typography component="p" variant={isMobile ? 'body1' : 'h6'}>
					{data.description}
				</Typography>
				{data.subDescription && (
					<Typography component="p" variant={isMobile ? 'body1' : 'h6'}>
						{data.subDescription}
					</Typography>
				)}
			</Box>

			{data.buttonActions && data.buttonActions.length > 0 && (
				<Box className={classes.buttonGroupSection}>
					{data.buttonActions.map((button, index) => (
						<Box key={index} py={2}>
							{renderButtonLink(button)}
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
}

ThankYouPageMainSection.propTypes = {
	title: PropTypes.string,
	titleFontSize: PropTypes.string,
	description: PropTypes.string,
	subDescription: PropTypes.string,
	buttonActions: PropTypes.array,
	zohoFormRequired: PropTypes.bool,
	searchQueryValues: PropTypes.object,
};

ThankYouPageMainSection.defaultProps = {
	buttonActions: [],
	zohoFormRequired: false,
};
