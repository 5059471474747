import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			padding: theme.spacing(4, 4),
			backgroundColor: '#FF8041',
			borderRadius: '29px',
			margin: theme.spacing(5, 5),
			[theme.breakpoints.down('sm')]: {
				margin: theme.spacing(3, 0),
				padding: theme.spacing(2),
			},
		},
		textSection: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			height: '100%',
		},
		description: {
			fontWeight: 400,
			fontSize: theme.spacing(2.5),
			[theme.breakpoints.down('sm')]: {
				fontSize: theme.spacing(2),
			},
		},
		contentContainer: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			color: theme.palette.common.white,
			[theme.breakpoints.down('md')]: {
				marginBottom: theme.spacing(3),
			},
		},
		formCard: {
			margin: theme.spacing(0, 2, 0, 4),
			borderRadius: theme.spacing(2),
			boxShadow: '3px 4px 10px rgba(0, 0, 0, 0.25)',
			[theme.breakpoints.down('md')]: {
				margin: theme.spacing(0, 0, 0, 0),
			},
		},
		iconContainer: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: '#D9520E',
			borderRadius: '50%',
			marginBottom: theme.spacing(2),
			width: theme.spacing(12.5),
			height: theme.spacing(12.5),
			[theme.breakpoints.down('md')]: {
				margin: theme.spacing(0, 'auto', 2),
			},
		},
		iconWrapper: {
			position: 'relative',
			width: theme.spacing(5.5),
			height: theme.spacing(5.5),
		},
	};
});

export default useStyles;
