// components
import ComponentSlider from '@/components/shared/componentSlider';
import TestimonialCardV1 from '@/components/shared/testimonialSection/partials/testimonialCardV1';
// Default
import PropTypes from 'prop-types';

export default function TestimonialSectionV1({
	width,
	maxWidth,
	cardList,
	...props
}) {
	return (
		<ComponentSlider {...props}>
			{cardList.map((item, index) => (
				<TestimonialCardV1
					key={item.id || index}
					{...item}
					width={width}
					maxWidth={maxWidth}
				/>
			))}
		</ComponentSlider>
	);
}

TestimonialSectionV1.propTypes = {
	cardList: PropTypes.array,
};
TestimonialSectionV1.defaultProps = {
	cardList: [],
};
