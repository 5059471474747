import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	videoTitleWrap: {
		textAlign: 'center',
		marginBottom: theme.spacing(1),
	},
	videoWrap: {
		textAlign: 'center',
		borderRadius: '19px',
		overflow: 'hidden',
		margin: theme.spacing(2),
	},
}));

export default useStyles;
