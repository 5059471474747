//actions
import { recordVC } from '@/api';

// utils
import get from 'lodash/get';

export const getCenterLink = async (data) => {
	const result = await recordVC(data);
	return get(result, 'data', null);
};
