import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { textValues } from './constants';

// component
import CTAButton from '@/components/widgets/ctaButton';
import DiscountAmount from './partials/discountAmount';
import CouponCodeMobileForm from './partials/mobileForm';
import CouponCodeModalForm from './partials/modalForm';
import WithProps from './withProps';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

const CouponCodeBox = ({ paymentDetail, discountAmount, ...props }) => {
	const { classes } = useStyles(props);
	const isMobile = useSelector((state) => state.common.isMobile);

	if (isMobile && props.formOpen) {
		return (
			<>
				<DiscountAmount
					discountAmount={(discountAmount || '').toString()}
					originalAmount={(paymentDetail.productAmount || '').toString()}
				/>
				<CouponCodeMobileForm
					// data
					pageSlug={props.pageSlug}
					paymentDetail={paymentDetail}
					analyticsProps={props.analyticsProps}
					couponCode={props.couponCode}
					// function
					loading={props.loading}
					errorText={props.errorText}
					canProceedToPayment={props.canProceedToPayment}
					// form
					form={props.form}
					formId={props.formId}
					formPageConfig={props.formPageConfig}
					formPageConstants={props.formPageConstants}
					// handlers
					handleKeyDown={props.handleKeyDown}
					handleClose={props.handleFormClose}
					handleInputChange={props.handleInputChange}
					handleApplyCouponCode={props.handleApplyCouponCode}
					handleRenterCouponCode={props.handleRenterCouponCode}
				/>
			</>
		);
	}

	return (
		<>
			<Box className={classes.couponCodeWrap}>
				<Box className={classes.textWrap}>
					<Typography
						component="p"
						variant="h3"
						fontWeight="fontWeightBold"
					>{`₹${paymentDetail.productAmount}/-`}</Typography>
					{paymentDetail.withCouponCode && (
						<Typography
							fontWeight="fontWeightMedium"
							className={classes.couponFormTriggerText}
							onClick={props.handleFormOpen}
						>
							{textValues.couponFormTriggerText}
						</Typography>
					)}
				</Box>
				<CTAButton
					fullWidth={true}
					label={props.buttonLabel}
					pageSlug={props.pageSlug}
					paymentDetail={paymentDetail}
					analyticsProps={props.analyticsProps}
					openFormModal={true}
					form={props.form}
					formId={props.formId}
					formPageConfig={props.formPageConfig}
					formPageConstants={props.formPageConstants}
				/>
			</Box>
			<CouponCodeModalForm
				// data
				title={props.title}
				subTitle={props.subTitle}
				pageSlug={props.pageSlug}
				paymentDetail={paymentDetail}
				analyticsProps={props.analyticsProps}
				couponCode={props.couponCode}
				discountAmount={(discountAmount || '').toString()}
				// function
				open={props.formOpen}
				loading={props.loading}
				errorText={props.errorText}
				canProceedToPayment={props.canProceedToPayment}
				// form
				form={props.form}
				formPageConfig={props.formPageConfig}
				formPageConstants={props.formPageConstants}
				// handlers
				handleKeyDown={props.handleKeyDown}
				handleClose={props.handleFormClose}
				handleInputChange={props.handleInputChange}
				handleApplyCouponCode={props.handleApplyCouponCode}
			/>
		</>
	);
};

export default WithProps(CouponCodeBox);

CouponCodeBox.propTypes = {
	paymentDetail: PropTypes.object.isRequired,
	pageSlug: PropTypes.string.isRequired,
	buttonLabel: PropTypes.string.isRequired,
	form: PropTypes.object.isRequired,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	formId: PropTypes.string,
	useOtpFlowForForm: PropTypes.bool,
	formPageConstants: PropTypes.object,
	formPageConfig: PropTypes.object,
	handleChange: PropTypes.func,
	analyticsProps: PropTypes.object,
};

CouponCodeBox.defaultProps = {
	analyticsProps: {},
	useOtpFlowForForm: false,
};
