import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	spinnerWrap: {
		height: '50vh',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		gap: theme.spacing(2),
	},
}));

export default useStyles;
