import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	wrapper: {
		display: 'flex',
		gap: theme.spacing(2),
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
		},
	},
	titleImageWrap: {
		position: 'relative',
		overflow: 'hidden',
		borderRadius: '15px',
		width: '55%',
		height: '513px',
		backgroundColor: theme.palette.common.lightGrey,
		[theme.breakpoints.down('xl')]: {
			height: '478px',
		},
		[theme.breakpoints.down('md')]: {
			height: '426px',
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	imageCaption: {
		position: 'absolute',
		cursor: 'pointer',
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		gap: theme.spacing(1),
		color: theme.palette.common.white,
		background: 'rgba(0, 0, 0, 0.6)',
		height: '74px',
		width: '100%',
	},
	infoWrap: {
		width: '45%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		[theme.breakpoints.down('md')]: {
			width: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			gap: theme.spacing(2),
		},
	},
	titleLogoWrap: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		gap: theme.spacing(2),
		marginBottom: theme.spacing(1),
		[theme.breakpoints.down('md')]: {
			marginBottom: theme.spacing(0),
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '300px',
		},
	},
	logoWrap: {
		width: '80px',
		height: '80px',
		position: 'relative',
		borderRadius: '50%',
		border: `1px solid ${theme.palette.text.disabled}`,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			width: '60px',
			height: '60px',
			flexShrink: 0,
		},
	},
	logo: {
		padding: '12px !important',
	},
	title: {
		...theme.typography.h2,
		fontWeight: theme.typography.fontWeightBold,
		wordBreak: 'break-word',
		[theme.breakpoints.down('md')]: {
			fontSize: theme.typography.h4.fontSize,
		},
	},
	subTitleWrap: {
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(1),
		},
	},
	statsWrap: {
		display: 'inline-flex',
		gap: theme.spacing(4),
		padding: theme.spacing(4),
		marginBottom: theme.spacing(3),
		boxShadow: '-1.7754px 2.3672px 8.87701px #FBC4AC',
		borderRadius: '14px',
		flexWrap: 'wrap',
		justifyContent: 'center',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3),
			padding: theme.spacing(2),
		},
	},
	stat: {
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			flexBasis: 'calc(50% - 18px)',
		},
		[theme.breakpoints.down(280)]: {
			flexBasis: '100%',
		},
	},
	statTitle: {
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(1),
		textAlign: 'center',
	},
	button: {
		padding: theme.spacing(1, 6),
		background:
			'linear-gradient(263.25deg, #F58320 0%, rgba(241, 105, 33, 0.987054) 81.82%, #D75312 109%)',
		boxShadow: '5.7651px 9.6085px 14.4128px rgba(208, 102, 8, 0.3)',
		borderRadius: '33px',
	},
	subTitle: {
		fontWeight: theme.typography.fontWeightMedium,
		textAlign: 'center',
	},
	source: {
		...theme.typography.caption,
		verticalAlign: 'top',
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
	textAlignCenter: {
		textAlign: 'center',
	},
}));

export default useStyles;
