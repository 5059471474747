import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	counterCardV3Wrap: {
		width: width || '100%',
		height: height || '100%',
	},
	title: {
		textAlign: 'center',
		marginBottom: theme.spacing(1),
	},
	subTitle: {
		textAlign: 'center',
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.common.textLightGrey,
	},
}));

export default useStyles;
