import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		marginBottom: theme.spacing(2),
	},
	contentWrap: {
		backgroundColor: 'transparent',
		textAlign: 'left',
		'& a': {
			color: theme.palette.primary.main,
		},
	},
}));

export default useStyles;
