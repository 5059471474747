import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import Markdown from '@/components/widgets/markdown';

// material
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// utils
import { addIdToSections } from './util';

// Styles
import useStyles from './style';

export default function CategorizedModuleSection(props) {
	const { classes, cx } = useStyles();
	const location = useSelector((state) => state.router.location);

	const cardList = React.useMemo(
		() => addIdToSections(props.cardList),
		[props.cardList]
	);

	const [selectedModuleIndex, setSelectedModuleIndex] = React.useState(0);
	const [showArrows, setShowArrows] = React.useState(false);
	const sectionListRef = React.useRef(null);

	const handleScrollButtonClick = (scroll) => {
		if (scroll == 'left') sectionListRef.current.scrollLeft += 150;
		else sectionListRef.current.scrollLeft -= 150;
	};

	const handleSectionClick = (index) => {
		setSelectedModuleIndex(index);
	};

	React.useEffect(() => {
		if (!sectionListRef.current) return;
		if (
			sectionListRef.current.scrollWidth >
			sectionListRef.current.parentElement.scrollWidth
		) {
			setShowArrows(true);
		}
	}, [sectionListRef.current]);

	React.useEffect(() => {
		const hash = location.hash && location.hash.slice(1);
		const moduleIndex = cardList.findIndex((section) => section.id === hash);
		setSelectedModuleIndex(moduleIndex === -1 ? 0 : moduleIndex);
	}, [location.hash]);

	return (
		<Box>
			<Box className={classes.titleWrap}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>

			<Box
				className={cx(
					classes.sectionRowWrap,
					showArrows ? classes.sectionRowWrapPadding : ''
				)}
			>
				<ChevronLeft
					className={cx(classes.arrowLeft, showArrows ? '' : classes.hidden)}
					onClick={() => handleScrollButtonClick('right')}
				/>
				<Box className={classes.sectionList} ref={sectionListRef}>
					{cardList.map((section, index) => (
						<Box
							key={index}
							className={cx(
								classes.sectionWrap,
								showArrows ? classes.textCenter : ''
							)}
						>
							<a href={`#${section.id}`}>
								<Typography
									component="span"
									onClick={() => handleSectionClick(index)}
									className={cx(
										classes.section,
										index === selectedModuleIndex ? classes.activeSection : ''
									)}
								>
									{section.title}
								</Typography>
							</a>
						</Box>
					))}
				</Box>
				<ChevronRight
					className={cx(classes.arrowRight, showArrows ? '' : classes.hidden)}
					onClick={() => handleScrollButtonClick('left')}
				/>
			</Box>
			<Box className={classes.contentWrap}>
				<Markdown>{props.cardList[selectedModuleIndex].body}</Markdown>
			</Box>
		</Box>
	);
}

CategorizedModuleSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	cardList: PropTypes.array.isRequired,
};
