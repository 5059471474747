import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	root: {
		background: theme.palette.common.white,
		boxShadow: '-1.55446px 2.07262px 15.5446px rgba(254, 200, 152, 0.87)',
		borderRadius: theme.spacing(1),
		padding: theme.spacing(4, 2, 6),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(3, 2, 4),
		},
	},
	titleWrap: {
		textAlign: 'center',
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(3),
		},
	},
	timeline: {
		position: 'relative',
		maxWidth: '500px',
		margin: '0 auto',
		minHeight: '100%',
		'&::after': {
			content: "''",
			position: 'absolute',
			width: '1px',
			backgroundColor: '#6D6D6D',
			top: '0',
			bottom: '0',
			left: '50%',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
			'&::after': {
				left: '70px',
			},
		},
	},
	container: {
		position: 'relative',
		width: '50%',
		padding: '0 32px 30px 20px',
		'&::after': {
			content: "''",
			position: 'absolute',
			width: '11px',
			height: '11px',
			right: '-6px',
			backgroundColor: '#F16621',
			border: ' 1px solid #F16621',
			top: '8px',
			borderRadius: '50%',
			zIndex: '1',
		},
		[theme.breakpoints.down('sm')]: {
			width: '50%',
			padding: '0px 0px 20px 15px',
			'&::after': {
				width: '9px',
				height: '9px',
				right: '-6px',
				top: '5px',
			},
		},
	},
	containerLeft: {
		left: '0',
		[theme.breakpoints.down('sm')]: {
			left: '70px',
			'&::after': {
				left: '-4px',
			},
		},
	},
	containerRight: {
		left: '50%',
		'&::after': {
			left: '-5px',
		},
		[theme.breakpoints.down('sm')]: {
			left: '70px',
			'&::after': {
				left: '-4px',
			},
		},
	},
	containerContent: {
		width: '100%',
		minheight: '50px',
	},
	date: {
		background: '#F16621',
		padding: '5px 8px',
		color: theme.palette.common.white,
		borderRadius: '10px',
		fontSize: '12px',
		lineHeight: '14px',
		fontWeight: theme.typography.fontWeightMedium,
		position: 'absolute',
		[theme.breakpoints.down('sm')]: {
			fontSize: '8px',
			lineHeight: '10px',
			padding: '3px 6px',
		},
	},
	dateLeft: {
		top: '0',
		left: '-90px',
		[theme.breakpoints.down('sm')]: {
			top: '2px',
			left: '-60px',
		},
	},
	dateRight: {
		top: '0',
		right: '-90px',
		[theme.breakpoints.down('sm')]: {
			top: '2px',
			left: '-60px',
			right: 'auto',
		},
	},
	title: {
		fontWeight: theme.typography.fontWeightMedium,
		color: theme.palette.common.black,
		marginBottom: theme.spacing(0.5),
		[theme.breakpoints.down('sm')]: {
			fontSize: '12px',
			lineHeight: '15px',
		},
	},
	description: {
		color: theme.palette.common.black,
		fontSize: '10px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '8px',
			lineHeight: '10px',
		},
	},
}));

export default useStyles;
