import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

//components
import TitleSection from '@/components/shared/titleSection';
const FormBoxWrap = dynamic(() => import('@/components/shared/formBoxWrap'));
const SlotBookFormWrap = dynamic(() =>
	import('@/components/shared/formBoxWrap/slotBookFormWrap')
);

// Material
import { Box } from '@mui/material';

// utils
import useFormBoxProps from '@/utils/hooks/use-form-box-props';

// Styles
import useStyles from './style';

export default function FormSection({ slotBookingForm, ...props }) {
	const { classes } = useStyles(props);
	const formBoxProps = useFormBoxProps(props);

	return (
		<Box className={classes.wrap}>
			<Box className={classes.sectionOne}>
				<TitleSection
					title={props.title}
					highlightTitle={props.highlightTitle}
					subTitle={props.subTitle}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>
			{(slotBookingForm || formBoxProps) && (
				<Box className={classes.sectionTwo}>
					{slotBookingForm ? (
						<SlotBookFormWrap
							submitButtonText={slotBookingForm.submitButtonText}
							submitLink={slotBookingForm.submitLink}
							successMessage={slotBookingForm.successMessage}
							failureMessage={slotBookingForm.failureMessage}
							analyticsProps={props.analyticsProps}
						/>
					) : (
						<FormBoxWrap
							{...formBoxProps}
							title=""
							description=""
							analyticsProps={props.analyticsProps}
						/>
					)}
				</Box>
			)}
		</Box>
	);
}

FormSection.propTypes = {
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	slotBookingForm: PropTypes.shape({
		successMessage: PropTypes.string.isRequired,
		failureMessage: PropTypes.string.isRequired,
		submitLink: PropTypes.string.isRequired,
		submitButtonText: PropTypes.string.isRequired,
	}),
	analyticsProps: PropTypes.object,
	form: PropTypes.object,
};

FormSection.defaultProps = {
	analyticsProps: {},
	form: null,
	slotBookingForm: null,
};
