import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	amountWrap: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	originalAmount: {
		textDecoration: 'line-through',
		marginRight: '16px',
		color: '#ababab',
		fontSize: '26px',
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default useStyles;
