export const actionTypes = {
	redirectToCompass: 'redirectToCompass',
	redirectToCompassEvent: 'redirectToCompassEvent',
	redirectToCenter: 'redirectToCenter',
	redirectToLink: 'redirectToLink',
	default: 'default',
};

export const defaultConfig = {
	actionType: actionTypes.default,
	message: 'Something Went Wrong, Redirecting back',
};
