import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	universityBannerSection: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: theme.spacing(2),
		margin: theme.spacing(0, 2),
		[theme.breakpoints.down('sm')]: {
			margin: theme.spacing(0),
		},
	},
	descriptionWrap: {
		maxWidth: '735px',
		margin: '0 auto',
	},
	titleWrap: {
		textAlign: 'center',
	},
	title: {
		...theme.typography.h1,
		[theme.breakpoints.down('lg')]: {
			fontSize: theme.typography.h3.fontSize,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.h4.fontSize,
		},
	},
	description: {
		marginTop: '8px',
	},
	loginBoxWrap: {
		flexBasis: '25%',
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	addSpaceAfter: {
		'&::after': {
			content: '" "',
			whiteSpace: 'pre',
		},
	},
}));

export default useStyles;
