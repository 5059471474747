import Link from 'next/link';
import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import GenericTable from '@/components/shared/GenericTable';
import TitleSection from '@/components/shared/titleSection';

// material
import { Box } from '@mui/material';

// actions
import { isExternalUrl } from '@/store/actions';

// styles
import useStyles from './style';

export default function GenericTableSection(props) {
	const { classes } = useStyles();
	const renderLinkElement = () => {
		if (!props.link) return null;
		const link = props.link;
		if (isExternalUrl(link)) {
			return (
				<a
					href={link}
					target="_blank"
					rel="noreferrer"
					className={classes.link}
				>
					{props.linkText}
				</a>
			);
		}
		return (
			<Link href={link}>
				<a className={classes.link}>{props.linkText}</a>
			</Link>
		);
	};

	return (
		<>
			{props.title && (
				<Box className={classes.titleSectionWrap}>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						highlightTitleAfter={false}
						fontSize={props.titleFontSize || titleSizes.medium}
						subTitle={props.subTitle}
					/>
				</Box>
			)}

			<Box className={classes.contentWrap}>
				<GenericTable
					styled={props.styled}
					tableBorder={props.tableBorder}
					rowBorder={props.rowBorder}
					columnBorder={props.columnBorder}
					rows={props.rows}
					headings={props.headings}
					columnsAlignment={props.columnsAlignment}
				/>
				{props.link && (
					<Box className={classes.linkWrap}>{renderLinkElement()}</Box>
				)}
			</Box>
		</>
	);
}

GenericTableSection.propTypes = {
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	subTitle: PropTypes.string,
	styled: PropTypes.bool,
	tableBorder: PropTypes.bool,
	rowBorder: PropTypes.bool,
	columnBorder: PropTypes.bool,
	rows: PropTypes.array,
	headings: PropTypes.array,
	columnsAlignment: PropTypes.array,
	link: PropTypes.string,
	linkText: PropTypes.string,
	titleFontSize: PropTypes.string,
};
