import PropTypes from 'prop-types';

// components
import CounterCardV3 from '@/components/shared/counterSection/partials/counterCardV3';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function CounterSectionV3({ cardList }) {
	const { classes } = useStyles();

	return (
		<Box className={classes.counterSectionV3Wrap}>
			{cardList.map((item, index) => (
				<Box key={index} className={classes.cardV3Wrap}>
					<CounterCardV3 {...item} />
				</Box>
			))}
		</Box>
	);
}

CounterSectionV3.propTypes = {
	cardList: PropTypes.array,
};
CounterSectionV3.defaultProps = {
	cardList: [],
};
