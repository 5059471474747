import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: '16px',
		textAlign: 'center',
	},
	slider: {
		display: 'flex',
		justifyContent: 'center',
	},
	sliderCardWrap: {
		width: '400px',
		padding: theme.spacing(0, 2),
	},
	linkCard: {
		width: '100%',
		height: '100%',
		display: 'flex',
		overflow: 'hidden',
		borderRadius: '15px',
		border: '1px solid #A9A9AA',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',

		'& > * + *': {
			marginTop: '17px',
		},
	},
	iconWrap: {
		position: 'relative',
		height: '84px!important',
		width: '84px!important',
		borderRadius: '50%',
		backgroundColor: theme.palette.common.grey,
		color: '#6D6D6D',
	},
	icon: {
		position: 'absolute',
		width: '34px!important',
		height: '38px!important',
		top: 'calc(50% - 19px)',
		left: 'calc(50% - 17px)',
		color: '#6D6D6D',
	},
	subIcon: {
		position: 'absolute',
		top: '48%',
		left: ' 48%',
		padding: '1px',
		width: '20px',
		height: '20px',
		backgroundColor: theme.palette.common.grey,
		borderRadius: '50%',
	},
	button: {
		minWidth: '162px',
		borderRadius: '28px',
		padding: '11px 25px',
		display: 'inline-block',
		maxWidth: '90%',
		wordWrap: 'break-word',
	},
}));

export default useStyles;
