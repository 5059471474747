// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import VideoPlayer from '@/components/widgets/videoPlayer';

// material
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// styles
import useStyles from './style';

export default function VideoSection(props) {
	const { classes } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);
	const isDesktop = useSelector((state) => state.common.isDesktop);

	const height = isMobile ? '215px' : isDesktop ? '550px' : '300px';
	return (
		<Box>
			{props.title && (
				<Box className={classes.videoTitleWrap}>
					<TitleSection
						title={props.title}
						fontSize={props.titleFontSize || titleSizes.medium}
						subTitle={props.subTitle}
					/>
				</Box>
			)}
			<Box className={classes.videoWrap}>
				<VideoPlayer
					url={props.videoLink}
					autoPlayOnView={props.autoPlay}
					height={height}
					analyticsProps={props.analyticsProps}
				/>
			</Box>
		</Box>
	);
}

VideoSection.propTypes = {
	videoLink: PropTypes.string.isRequired,
	title: PropTypes.string,
	subTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	autoPlay: PropTypes.bool,
	analyticsProps: PropTypes.object,
};
VideoSection.defaultProps = {
	autoPlay: false,
	subTitle: '',
	analyticsProps: {},
};
