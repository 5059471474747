import { config, imageSizeTypes } from './constants';

export const getConfig = (size, shape, deviceSize) => {
	const configuration = config[size];

	return {
		...configuration[shape],
		perItemWidth: configuration['perItemWidth'][deviceSize],
		itemsGap: size === imageSizeTypes.large ? '48px' : '32px',
	};
};
