import { getCompassCourseFinderURL } from '@/utils/redirect-to-platform';
import get from 'lodash/get';

export const getRedirectionLink = (profileData, university_ids) => {
	const queryFields = {},
		keywords = get(profileData, 'desiredProfile.keywords'),
		newDegreeTypes = get(profileData, 'desiredProfile.degree_types_new');

	if (keywords && keywords.length) queryFields.keywords = keywords.join(',');
	if (newDegreeTypes && newDegreeTypes.length)
		queryFields.level_and_type = newDegreeTypes.join(',');
	if (university_ids) queryFields.university_ids = university_ids;

	return getCompassCourseFinderURL(queryFields);
};
