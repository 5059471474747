import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	planCardList: {
		display: 'flex',
		justifyContent: 'center',
		gap: '14px 0px',
		flexWrap: 'wrap',
		wordBreak: 'break-word',
		[theme.breakpoints.down('md')]: {
			gap: '14px',
			flexWrap: 'wrap',
		},
	},
	planCardWrap: {
		flexBasis: '25%',
		minWidth: 0,
		[theme.breakpoints.down('lg')]: {
			flexBasis: '33.33%',
		},
		[theme.breakpoints.down('md')]: {
			flexBasis: '100%',
			maxWidth: '326px',
		},
	},
}));
export default useStyles;
