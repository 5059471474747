// mui
import { Button } from '@mui/material';
// styles
import { green } from '@mui/material/colors';
import { withStyles } from 'tss-react/mui';

export const CustomButton = withStyles(Button, (theme) => ({
	root: {
		color: 'white',
		padding: theme.spacing(1.25, 4),
		backgroundColor: green[400],
		'&:hover': {
			backgroundColor: green[600],
		},
	},
}));
