import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	sectionWrap: {
		maxWidth: theme.breakpoints.values.md,
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing(3),
		margin: '0 auto',
	},
	buttonGroupSection: {
		width: '50%',
		margin: '0 auto',
	},
}));

export default useStyles;
