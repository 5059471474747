import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import Markdown from '@/components/widgets/markdown';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function TableSection(props) {
	const { classes } = useStyles();
	return (
		<>
			{props.title && (
				<Box className={classes.titleSectionWrap}>
					<TitleSection
						title={props.title}
						highlightTitle={props.highlightTitle}
						subTitle={props.subTitle}
						fontSize={props.titleFontSize || titleSizes.medium}
					/>
				</Box>
			)}
			<Box className={classes.contentWrap}>
				<Markdown
					tableTextAlignment={props.tableTextAlignment}
					tableStyled={props.styled}
					rowBorder={props.rowBorder}
					columnBorder={props.columnBorder}
				>
					{props.body}
				</Markdown>
			</Box>
		</>
	);
}

TableSection.propTypes = {
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	body: PropTypes.string.isRequired,
	styled: PropTypes.bool,
	tableTextAlignment: PropTypes.string,
	rowBorder: PropTypes.bool,
	columnBorder: PropTypes.bool,
};
