export const textValues = {
	countryCodeText: '+ 91',
	placeholder: 'Enter your Mobile Number',
	buttonText: "Let's Begin",
	requiredText: 'Please Enter Your Mobile Number',
	invalidText: 'Please Enter Valid Mobile Number',
};

export const textValuesPartner = {
	titleText: 'Become a partner today',
	buttonText: 'Register Now',
};
