import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// mui
import { Box, Typography } from '@mui/material';
// styles
import useStyles from './style';
// comps
import TitleSection from '@/components/shared/titleSection';
import ImageComponent from '@/components/widgets/image';

export default function LeftAlignedSection(props) {
	const { isDesktop, isMobile } = useSelector((state) => state.common);
	const { classes } = useStyles();

	return (
		<Box className={classes.root}>
			{props.imageUrl && (
				<Box className={classes.imageSection}>
					<Box className={classes.imageWrap}>
						<ImageComponent
							src={props.imageUrl}
							alt={props.imageAltText}
							width={isMobile ? '300px' : '400px'}
							height={isMobile ? '300px' : '400px'}
						/>
					</Box>
				</Box>
			)}
			<Box className={classes.contentSection}>
				<Typography
					component="h1"
					className={isDesktop ? '' : classes.textCenter}
				>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						highlightTitleAfter={true}
						fontSize={props.titleFontSize}
						textAlign={isDesktop ? 'left' : 'center'}
					/>
				</Typography>
				<Typography className={classes.description}>
					{props.description}
				</Typography>
			</Box>
		</Box>
	);
}

LeftAlignedSection.propTypes = {
	imageUrl: PropTypes.string.isRequired,
	imageAltText: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	highlightTitle: PropTypes.string,
	description: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};
LeftAlignedSection.defaultProps = {
	analyticsProps: {},
};
