import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// components
import Spinner from '@/components/widgets/spinner';

// material
import { Box, Typography } from '@mui/material';

// utils
import { goToPreviousRoute } from '@/store/actions/navigation';
import { getConfig } from './util';

// style
import useStyles from './style';

export default function RedirectionSection(props) {
	const { classes } = useStyles();
	const dispatch = useDispatch();

	const config = getConfig(props);

	React.useEffect(() => {
		if (!config.action) {
			setTimeout(() => dispatch(goToPreviousRoute()), 2000);
			return;
		}

		(async () => await config.action())();
	}, []);

	return (
		<Box className={classes.spinnerWrap}>
			{config.message && <Typography>{config.message}</Typography>}
			<Spinner />
		</Box>
	);
}

RedirectionSection.propTypes = {
	config: PropTypes.object.isRequired,
	pageSlug: PropTypes.string.isRequired,
	searchQueryValues: PropTypes.object.isRequired,
};
