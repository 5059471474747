import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// Mui
import { Box, Grid } from '@mui/material';

// styles
import useStyles from './style';

// components
import TitleSection from '@/components/shared/titleSection';
import ImageCard from './partials/imageCard';

export default function PerksAndBenefitsSection(props) {
	const isMobile = useSelector((state) => state.common.isMobile);
	const { classes } = useStyles({});

	return (
		<Box className={classes.root}>
			<Box
				mb={isMobile ? 7.5 : 10}
				display="flex"
				flexDirection={'column'}
				alignItems={'center'}
			>
				<TitleSection
					title={props.title}
					highlightTitle={props.highlightTitle}
					subTitle={props.description}
					highlightTitleAfter={true}
					fontSize={props.titleFontSize || titleSizes.medium}
				/>
			</Box>
			<Grid container spacing={2}>
				{props.perk &&
					props.perk.length > 0 &&
					props.perk.map((item, index) => (
						<Grid item xs={12} sm={6} md={4} key={index}>
							<ImageCard
								img={get(item, 'perkImage.url', '')}
								altText={get(item, 'perkImage.alternativeText', '')}
								title={get(item, 'perkTitle', '')}
								description={get(item, 'perkDescription', '')}
								lastCard={props.perk.length === index + 1}
							/>
						</Grid>
					))}
			</Grid>
		</Box>
	);
}

PerksAndBenefitsSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	description: PropTypes.string,
	perk: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
};
PerksAndBenefitsSection.defaultProps = {
	analyticsProps: {},
};
