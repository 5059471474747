import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material
import { Box, Typography } from '@mui/material';

// styles
import useStyles from './style';

export default function CounterCardV2({ title, subTitle, ...props }) {
	const { classes } = useStyles(props);
	const { isMobile, isDesktop } = useSelector((state) => state.common);

	return (
		<Box className={classes.counterCardV2Wrap}>
			<Typography
				variant={isMobile ? 'h6' : isDesktop ? 'h4' : 'h5'}
				className={classes.title}
			>
				{title}
			</Typography>
			<Typography
				variant={isDesktop ? 'h6' : 'body1'}
				className={classes.subtitle}
			>
				{subTitle}
			</Typography>
		</Box>
	);
}

CounterCardV2.propTypes = {
	title: PropTypes.string.isRequired,
	subTitle: PropTypes.string.isRequired,
	isActive: PropTypes.bool,
};
CounterCardV2.defaultProps = {
	isActive: false,
};
