import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleSectionWrap: {
		textAlign: 'center',
		marginBottom: '16px',
	},
	cardListWrap: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',

		margin: '-63px 0 0 -67px',
		'& > *': { margin: '63px 0 0 67px' },
	},
	cardWrap: {
		flexBasis: 'calc(20% - 67px)',
		minWidth: 0,

		[theme.breakpoints.down('md')]: {
			flexBasis: 'calc(50% - 67px)',
		},
		[theme.breakpoints.down('sm')]: {
			flexBasis: '100%',
			maxWidth: '200px',
		},
	},
}));

export default useStyles;
