const controlSchema = {
	type: 'object',
	required: ['userName', 'email', 'message'],
	properties: {
		userName: {
			type: 'string',
			minLength: 3,
		},
		email: {
			type: 'string',
			format: 'email',
		},
		message: {
			type: 'string',
		},
		mobile: {
			type: 'string',
			maxLength: 10,
			minLength: 10,
		},
	},
};

export default controlSchema;
