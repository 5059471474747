import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	titleWrap: {
		marginBottom: theme.spacing(4),
	},
	sectionRowWrap: {
		position: 'relative',
		marginBottom: theme.spacing(3),
	},
	sectionRowWrapPadding: {
		padding: theme.spacing(0, 2),
	},
	sectionList: {
		display: 'flex',
		gap: theme.spacing(2),
		width: '100%',
		overflowX: 'scroll',
		scrollBehavior: 'smooth',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	sectionWrap: {
		flexBasis: 'calc(20% - 16px)',
		flexShrink: 0,
	},
	section: {
		fontWeight: theme.typography.fontWeightMedium,
		display: 'inline-block',
		width: 'max-content',
	},
	activeSection: {
		color: theme.palette.primary.main,
	},
	arrowLeft: {
		position: 'absolute',
		top: 0,
		left: '-10px',
	},
	arrowRight: {
		position: 'absolute',
		top: 0,
		right: '-10px',
	},
	contentWrap: {
		border: `1px solid ${theme.palette.common.black}`,
		padding: theme.spacing(2, 2.5),
		borderRadius: '10px',
	},
	hidden: {
		display: 'none !important',
	},
	textCenter: {
		textAlign: 'center',
	},
}));
export default useStyles;
