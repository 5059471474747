import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// components
import ComponentSlider from '@/components/shared/componentSlider';
import EventPageBannerSection from '@/modules/event-page/banner-section';

// material
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function EventGroupBannerSection({
	event,
	pageSlug,
	interval,
	analyticsProps,
	titleFontSize,
}) {
	const { classes, cx } = useStyles();
	const isMobile = useSelector((state) => state.common.isMobile);

	return (
		<ComponentSlider
			focusOnSelect={false}
			slidesToShow={1}
			dots={true}
			arrows={!isMobile}
			useTransform={false}
			analyticsProps={analyticsProps}
			infinite={true}
			autoplay={Boolean(interval)}
			autoplaySpeed={interval ? interval * 1000 : 3000}
			speed={interval ? (isMobile ? 400 : 700) : 500}
			cssEase="linear"
			accessibility={false}
			className={classes.bannerSection}
			analyticsPropsSubSections={event.map((item) =>
				`${item.highlightTitle || ''} ${item.title}`.trim()
			)}
			appendDots={(dots) => (
				<ul>
					{dots.map((item, index) => (
						<li
							key={index}
							className={cx(
								classes.dot,
								item.props.className ? classes.activeDot : ''
							)}
						>
							{item.props.children}
						</li>
					))}
				</ul>
			)}
			nextArrow={({ onClick, trackEvent }) => (
				<Box
					className={classes.nextArrow}
					onClick={(clickArgs) => {
						trackEvent('RIGHT');
						onClick(clickArgs);
					}}
				>
					<ChevronRight fontSize="large" />
				</Box>
			)}
			prevArrow={({ onClick, trackEvent }) => (
				<Box
					className={classes.prevArrow}
					onClick={(clickArgs) => {
						trackEvent('LEFT');
						onClick(clickArgs);
					}}
				>
					<ChevronLeft fontSize="large" />
				</Box>
			)}
		>
			{event.map((event, index) => (
				<Box key={index} className={classes.sliderCardWrap}>
					<EventPageBannerSection
						pageSlug={pageSlug}
						title={event.title}
						highlightTitle={event.highlightTitle}
						titleImage={event.titleImage}
						description={event.description}
						mobileDescription={event.mobileDescription}
						rating={event.rating}
						timer={event.timer}
						endTime={event.endTime}
						ctaButton={event.ctaButton}
						form={event.form}
						authUserForm={event.authUserForm}
						titleFontSize={titleFontSize}
						formPageConstants={event.formPageConstants || {}}
						formPageConfig={event.formPageConfig || {}}
						useOtpFlowForForm={event.useOtpFlowForForm}
						paymentDetail={event.paymentDetail}
						analyticsProps={analyticsProps}
					/>
				</Box>
			))}
		</ComponentSlider>
	);
}

EventGroupBannerSection.propTypes = {
	pageSlug: PropTypes.string,
	event: PropTypes.array.isRequired,
	analyticsProps: PropTypes.object,
};
EventGroupBannerSection.defaultProps = {
	analyticsProps: {},
};
