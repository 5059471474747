import PropTypes from 'prop-types';
import React from 'react';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

//Material
import { Box } from '@mui/material';

//component
import TitleSection from '@/components/shared/titleSection';

//style
import useStyles from './style';
import { getQueryString } from './util';

export default function IFrameSection(props) {
	const { classes } = useStyles(props);
	const [iFrameUrl, setIFrameUrl] = React.useState(null);

	React.useEffect(() => {
		const url = `${props.link}?${getQueryString(
			props.searchQueryValues,
			props.queryKeyConfig
		)}`;
		setIFrameUrl(url);
	}, [props.searchQueryValues, props.searchQueryValues]);

	return (
		<Box>
			{props.title && (
				<Box className={classes.titleWrap}>
					<TitleSection
						highlightTitle={props.highlightTitle}
						title={props.title}
						fontSize={props.titleFontSize || titleSizes.medium}
						subTitle={props.subTitle}
					/>
				</Box>
			)}
			<Box className={classes.iFrameWrap}>
				{iFrameUrl && (
					<iframe
						src={iFrameUrl}
						width={'100%'}
						height={'100%'}
						allow="fullscreen"
						loading="lazy"
						className={classes.iFrame}
					></iframe>
				)}
			</Box>
		</Box>
	);
}

IFrameSection.propTypes = {
	title: PropTypes.string,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	width: PropTypes.string.isRequired,
	height: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	queryKeyConfig: PropTypes.object,
	searchQueryValues: PropTypes.object,
};
