import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	bannerSection: {
		marginBottom: '27.5px',
	},
	prevArrow: {
		position: 'absolute',
		top: '50%',
		left: -20,
		transform: 'translateY(-50%)',
		zIndex: 100,
		cursor: 'pointer',
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		background: theme.palette.primary.main,
	},
	nextArrow: {
		position: 'absolute',
		top: '50%',
		right: -20,
		transform: 'translateY(-50%)',
		cursor: 'pointer',
		zIndex: 100,
		width: '40px',
		height: '40px',
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'white',
		background: theme.palette.primary.main,
	},
	dot: {
		'& button::before': {
			fontSize: '15px!important',
			opacity: '1!important',
			color: `${theme.palette.text.lightGrey}!important`,
		},
	},
	activeDot: {
		'& button::before': {
			color: `${theme.palette.primary.main}!important`,
		},
	},
	sliderCardWrap: {
		marginBottom: theme.spacing(4),
		[theme.breakpoints.down('sm')]: {
			marginBottom: theme.spacing(2),
		},
	},
}));

export default useStyles;
