import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import HightlightCardV1 from '@/components/shared/highlightSection/partials/highlightCardV1';
import TitleSection from '@/components/shared/titleSection';

// material
import { Box } from '@mui/material';

// styles
import useStyles from './style';

export default function HightlightSectionV1({
	cardList,
	centerAlignContent,
	...props
}) {
	const { classes, cx } = useStyles();
	return (
		<>
			<TitleSection
				highlightTitle={props.highlightTitle}
				fontSize={props.titleFontSize || titleSizes.medium}
				title={props.title}
				subTitle={props.subTitle}
			/>
			<Box
				className={cx(
					classes.highlightV1Section,
					centerAlignContent ? classes.justifyContentCenter : ''
				)}
			>
				{cardList.map((item, index) => (
					<Box key={index} className={classes.cardV1Wrap}>
						<HightlightCardV1 {...item} />
					</Box>
				))}
			</Box>
		</>
	);
}

HightlightSectionV1.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	centerAlignContent: PropTypes.bool,
};
HightlightSectionV1.defaultProps = {
	cardList: [],
	centerAlignContent: false,
};
