import PropTypes from 'prop-types';

// material
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// Styles
import useStyles from './style';

const activeCategoryId = 'active-category';
let observer = null;
export default function CategoryRow({
	categories,
	selectedCategoryIndex,
	handleCategoryClick,
}) {
	const { classes, cx } = useStyles();

	const [showArrows, setShowArrows] = React.useState(false);
	const categoryListRef = React.useRef(null);

	const handleScrollButtonClick = (scroll) => {
		if (scroll == 'left') categoryListRef.current.scrollLeft += 150;
		else categoryListRef.current.scrollLeft -= 150;
	};

	const handleScrollToSelectedCategory = (target) => {
		const rect = target.getBoundingClientRect();
		if (rect.left > categoryListRef.current.clientWidth) {
			categoryListRef.current.scrollLeft =
				rect.left + rect.width - categoryListRef.current.clientWidth;
		}
	};

	React.useEffect(() => {
		if (!categoryListRef.current) return;
		if (
			categoryListRef.current.scrollWidth >
			categoryListRef.current.parentElement.scrollWidth
		) {
			setShowArrows(true);
		}

		observer && observer.disconnect();

		observer = new IntersectionObserver(
			([entity]) => {
				if (entity.isIntersecting) observer && observer.disconnect();
				else handleScrollToSelectedCategory(entity.target);
			},
			{ root: null, threshold: 1 }
		);

		let item = document.getElementById(activeCategoryId);
		if (item) observer.observe(item);

		return () => {
			observer && observer.disconnect();
		};
	}, [categoryListRef.current]);

	return (
		<Box
			className={cx(
				classes.categoryRow,
				showArrows ? classes.categoryRowPadding : ''
			)}
		>
			<ChevronLeft
				className={cx(classes.arrowLeft, showArrows ? '' : classes.hidden)}
				onClick={() => handleScrollButtonClick('right')}
			/>
			<Box
				className={cx(
					classes.categoryList,
					showArrows ? classes.justifyStart : ''
				)}
				ref={categoryListRef}
			>
				{categories.map((category, index) => (
					<Box
						key={index}
						className={cx(
							classes.categoryWrap,
							showArrows ? classes.textCenter : ''
						)}
					>
						<a href={`#${category.id}`}>
							<Typography
								component="span"
								onClick={() => handleCategoryClick(index)}
								className={cx(
									classes.category,
									index === selectedCategoryIndex ? classes.activeCategory : ''
								)}
								id={index === selectedCategoryIndex ? activeCategoryId : ''}
							>
								{category.title}
							</Typography>
						</a>
					</Box>
				))}
			</Box>
			<ChevronRight
				className={cx(classes.arrowRight, showArrows ? '' : classes.hidden)}
				onClick={() => handleScrollButtonClick('left')}
			/>
		</Box>
	);
}

CategoryRow.propTypes = {
	categories: PropTypes.array.isRequired,
};
