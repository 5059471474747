import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => {
	return {
		root: {
			position: 'relative',
		},
		bannerBackground: {
			zIndex: -1,
		},
		textContainer: {
			maxWidth: theme.spacing(94),
		},
		heroImage: {
			zIndex: -1,
			objectFit: 'cover',
			objectPosition: 'left top',
		},
		mainTextSection: {
			padding: theme.spacing(13, 8),
			[theme.breakpoints.down('md')]: {
				padding: theme.spacing(10, 2),
			},
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(1, 0, 0),
			},
		},
		tagLineSection: {
			padding: theme.spacing(3, 0, 1, 0),
			[theme.breakpoints.down('sm')]: {
				padding: theme.spacing(3, 2, 1),
			},
		},
		headingDescription: {
			fontSize: theme.spacing(2.5),
			[theme.breakpoints.down('sm')]: {
				textAlign: 'center',
			},
		},
		logoContainer: {
			justifyContent: 'center',
			[theme.breakpoints.down('sm')]: {
				justifyContent: 'center',
			},
		},
		logoWrapper: {
			marginTop: theme.spacing(5),
			width: '502px',
			height: '502px',
			[theme.breakpoints.down('md')]: {
				width: '400px',
				height: '400px',
				marginTop: theme.spacing(3),
			},
			[theme.breakpoints.down('sm')]: {
				width: '316px',
				height: '316px',
				marginBottom: '16px',
			},
		},
		button: {
			borderRadius: '24px',
			textTransform: 'capitalize',
		},
		buttonContainer: {
			[theme.breakpoints.down('xs')]: {
				margin: theme.spacing(2.5, 'auto', 1),
				width: '80%',
			},
		},
		tagLineFirstLetter: {
			color: theme.palette.primary.main,
		},
	};
});

export default useStyles;
