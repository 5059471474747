import PropTypes from 'prop-types';

// components
import PartnerLoginBox from './partner';
import WebsiteLoginBox from './website';

// utils
import getPlatform from '@/utils/getPlatform';

export default function LoginBox(props) {
	return getPlatform() === 'partner' ? (
		<PartnerLoginBox {...props} />
	) : (
		<WebsiteLoginBox {...props} />
	);
}

LoginBox.propTypes = {
	title: PropTypes.string,
	subTitle: PropTypes.string,
	width: PropTypes.string,
	titlePosition: PropTypes.string,
	buttonText: PropTypes.string,
	analyticsProps: PropTypes.object,
};
LoginBox.defaultProps = {
	width: '100%',
	titlePosition: 'center',
	analyticsProps: {},
	layout: 'horizontal',
};
