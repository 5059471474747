import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
	dialogPaper: {
		overflowY: 'unset',
		width: '579px',
		padding: '73px 64px 53px',
		borderRadius: '12px',
	},
	closeIconWrap: {
		margin: '10px',
		position: 'absolute',
		right: 0,
		top: 0,
	},
	couponSectionWrap: {
		marginTop: '26px',
	},
	couponInputWrap: {
		display: 'flex',
		height: '48px',
		marginTop: '4px',
	},
	applyButton: {
		borderRadius: 'unset',
		borderTopRightRadius: '7px',
		borderBottomRightRadius: '7px',
		padding: '6px 24px',
		height: '46px',
		minWidth: '96px',
	},
	proceedButtonWrap: {
		marginTop: '54px',
	},
	proceedButton: {
		borderRadius: '30px',
	},
}));

export default useStyles;
