import PropTypes from 'prop-types';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';

// components
import TitleSection from '@/components/shared/titleSection';
import VideoCard from '@/components/shared/videoGallerySection/partials/videoCard';

// Material
import { Box } from '@mui/material';

// Styles
import useStyles from './style';

export default function VideoGallerySection(props) {
	const { classes } = useStyles();

	return (
		<Box className={classes.VideoGalleryWrap}>
			<Box className={classes.titleSection}>
				<TitleSection
					highlightTitle={props.highlightTitle}
					title={props.title}
					highlightTitleAfter={false}
					fontSize={props.titleFontSize || titleSizes.medium}
					subTitle={props.subTitle}
				/>
			</Box>
			<Box className={classes.videoListSection}>
				{props.cardList.map((card, index) => (
					<Box key={index} className={classes.cardWrap}>
						<VideoCard
							url={card.videoLink}
							thumbnailURL={card.image && card.image.url}
							label={card.label}
							analyticsProps={props.analyticsProps}
						/>
					</Box>
				))}
			</Box>
		</Box>
	);
}

VideoGallerySection.propTypes = {
	highlightTitle: PropTypes.string,
	title: PropTypes.string.isRequired,
	titleFontSize: PropTypes.string,
	subTitle: PropTypes.string,
	cardList: PropTypes.array,
	analyticsProps: PropTypes.object,
};

VideoGallerySection.defaultProps = {
	cardList: [],
	analyticsProps: {},
};
