import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
	categoryRow: {
		position: 'relative',
	},
	categoryRowPadding: {
		padding: theme.spacing(0, 2),
	},
	categoryList: {
		display: 'flex',
		gap: '30px',
		width: '100%',
		overflowX: 'scroll',
		scrollBehavior: 'smooth',
		justifyContent: 'center',
		'&::-webkit-scrollbar': {
			display: 'none',
		},
	},
	categoryWrap: {
		flexShrink: 0,
	},
	category: {
		fontWeight: theme.typography.fontWeightSemiBold,
		fontSize: '19.7977px',
		display: 'inline-block',
		width: 'max-content',
		color: '#A9A9A9',
	},
	activeCategory: {
		color: theme.palette.common.black,
		borderBottom: `3px solid ${theme.palette.primary.main}`,
	},
	arrowLeft: {
		position: 'absolute',
		top: '3px',
		left: '-10px',
	},
	arrowRight: {
		position: 'absolute',
		top: '3px',
		right: '-10px',
	},
	hidden: {
		display: 'none !important',
	},
	textCenter: {
		textAlign: 'center',
	},
	justifyStart: {
		justifyContent: 'flex-start',
	},
}));
export default useStyles;
