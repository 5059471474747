import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// constants
import { titleSizes } from '@/utils/constants/titleSizes';
import { textValues } from './constants';

// components
import TitleSection from '@/components/shared/titleSection';
import LoginBox from '@/components/widgets/loginBox';

// material
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function UniversityBannerSection({
	title,
	highlightTitle,
	titleFontSize,
	description,
	analyticsProps,
}) {
	const { classes } = useStyles();
	const isDesktop = useSelector((state) => state.common.isDesktop);
	return (
		<Box className={classes.universityBannerSection}>
			<Box className={classes.descriptionWrap}>
				<Box className={classes.titleWrap}>
					<TitleSection
						highlightTitle={highlightTitle}
						title={title}
						highlightTitleAfter={true}
						heading={'h1'}
						fontSize={titleFontSize || titleSizes.large}
					/>
					{description && (
						<Typography className={classes.description}>
							{description}
						</Typography>
					)}
				</Box>
			</Box>
			{isDesktop && (
				<Box className={classes.loginBoxWrap}>
					<LoginBox
						layout="vertical"
						title={textValues.loginBoxTitle}
						buttonText={textValues.loginBoxButtonText}
						analyticsProps={analyticsProps}
					/>
				</Box>
			)}
		</Box>
	);
}

UniversityBannerSection.propTypes = {
	title: PropTypes.string.isRequired,
	highlightTitle: PropTypes.string,
	titleFontSize: PropTypes.string,
	description: PropTypes.string,
	analyticsProps: PropTypes.object,
};

UniversityBannerSection.defaultProps = {
	analyticsProps: {},
};
