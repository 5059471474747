import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height, isActive }) => ({
	counterCardV2Wrap: {
		width: width || '100%',
		height: height || '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderRadius: '20px',
		padding: theme.spacing(3),
		backgroundColor: isActive
			? theme.palette.common.white
			: theme.palette.common.lightGrey,
		boxShadow: isActive
			? '0px -5px 15px rgb(245 106 32 / 20%), 0px 5px 10px rgb(245 131 32 / 3%), 0px 5px 15px rgb(245 211 32 / 20%)'
			: 'none',
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(2),
		},
	},
	title: {
		textAlign: 'center',
		overflowWrap: 'anywhere',
		color: theme.palette.common.textLightGrey,
		fontWeight: theme.typography.fontWeightMedium,
		[theme.breakpoints.down('sm')]: {
			fontWeight: theme.typography.fontWeightSemiBold,
		},
	},
	subtitle: {
		textAlign: 'center',
		overflowWrap: 'anywhere',
		fontWeight: theme.typography.fontWeightMedium,
	},
}));

export default useStyles;
