import PropTypes from 'prop-types';

// material
import { CancelOutlined, CheckCircleOutline } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';

// style
import useStyles from './style';

const PlanFeature = ({ feature, offered, iconHighlighted }) => {
	const { classes, cx } = useStyles({ iconHighlighted });

	const renderIcon = () => {
		let Icon, iconColorClass;

		if (offered === true) {
			Icon = CheckCircleOutline;
			iconColorClass = classes.offeredIconColor;
		} else if (offered === false) {
			Icon = CancelOutlined;
			iconColorClass = classes.iconColor;
		}

		if (iconHighlighted) iconColorClass = classes.highlighIconColor;

		return Icon ? (
			<Icon classes={{ root: cx(classes.icon, iconColorClass) }} />
		) : null;
	};

	const featureClassName = cx(
		classes.feature,
		offered === false && !iconHighlighted ? classes.textDisabled : '',
		offered === true || offered === false ? '' : classes.noIconMargin
	);

	return (
		<Box className={classes.planFeature}>
			{renderIcon()}
			<Typography className={featureClassName}>{feature}</Typography>
		</Box>
	);
};

export default React.memo(PlanFeature);

PlanFeature.propTypes = {
	feature: PropTypes.string.isRequired,
	offered: PropTypes.bool,
	iconHighlighted: PropTypes.bool,
};

PlanFeature.defaultProps = {
	iconHighlighted: false,
};
