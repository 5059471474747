import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme, { width, height }) => ({
	container: {
		width: width || '100%',
		height: height || '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
		borderRadius: '15px',
		border: '1px solid #A9A9AA',
		wordWrap: 'break-word',
	},
	body: {
		padding: theme.spacing(2),
		textAlign: 'center',
		backgroundColor: theme.palette.common.white,
		display: 'flex',
		height: '100%',
		flexDirection: 'column',
	},
	imageSection: {
		backgroundColor: theme.palette.common.backgroundGrey,
		borderBottom: '1px solid #A9A9AA',
	},
	coverImageWrap: {
		position: 'relative',
		overflow: 'hidden',
		width: '100%',
		margin: '0 auto',
		paddingTop: '69%',
	},
	iconImageWrap: {
		position: 'relative',
		overflow: 'hidden',
		width: '18px',
		height: '18px',
		display: 'inline-block',
		marginRight: '10px',
		marginLeft: '-28px',
	},
	title: {
		fontWeight: theme.typography.fontWeightSemiBold,
		[theme.breakpoints.down('sm')]: {
			fontWeight: theme.typography.fontWeightSemiBold,
		},
	},
	content: {
		color: '#6D6D6D',
		fontWeight: theme.typography.fontWeightMedium,
		fontSize: '18px',
		'& *': {
			marginTop: theme.spacing(0.5),
			marginBottom: theme.spacing(0.5),
		},
	},
	buttonWrap: {
		marginTop: '24px',
		display: 'flex',
		alignItems: 'flex-end',
		height: '100%',
	},
}));

export default useStyles;
